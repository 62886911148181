import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useCurrentLanguage() {
  const { i18n } = useTranslation();

  const currentLanguage = useMemo(() => {
    return i18n.language?.split('-')[0].toLocaleLowerCase() || 'en';
  }, [i18n.language]);

  return currentLanguage;
}
