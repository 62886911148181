import useRefresh from '../Auth/useRefresh';
import useCurrentUser from '../CurrentUser/useCurrentUser';
import Menu from '../System/Menu';
import MenuItem from '../System/MenuItem';
import useSetCurrentWorkspace from './useSetCurrentWorkspace';
import useWorkspaces from './useWorkspaces';
import Button from '../System/Button';
import useOpen from '../System/useOpen';
import ArrowDropDown from '../Icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import WorkspacesIcon from '../Icons/Workspaces';
import clsx from 'clsx';
import AddIcon from '../Icons/Add';
import { useHistory } from 'react-router-dom';

export default function WorkspacesSelect({ classes = {} }) {
  const { t } = useTranslation();

  const { loading, workspaces } = useWorkspaces();

  const { currentUser } = useCurrentUser();

  const { setCurrentWorkspace } = useSetCurrentWorkspace();
  const { onRefresh } = useRefresh();

  const { open, onOpen, onClose } = useOpen();

  const currentWorkspace = useMemo(() => {
    return (
      currentUser.currentWorkspaceId &&
      workspaces?.find((ws) => ws.id === currentUser.currentWorkspaceId)
    );
  }, [currentUser, workspaces]);

  const { replace } = useHistory();
  const handleChangeWorkspace = async (workspaceId) => {
    onClose();

    await setCurrentWorkspace({
      variables: { workspaceId },
    });
    await onRefresh();
    replace('/');
  };

  const [menuReferenceElement, setMenuReferenceElement] = useState(null);

  return loading ? null : (
    <>
      <Button
        ref={setMenuReferenceElement}
        className={clsx(classes.actionButton)}
        variant="outlined"
        startIcon={<WorkspacesIcon className="opacity-75 h-5" />}
        endIcon={<ArrowDropDown className="h-5" />}
        data-cy="workspace-select"
        onClick={onOpen}
      >
        {currentWorkspace?.name || t('WorkspacesSelect.currentEmpty')}
      </Button>

      <Menu
        open={open}
        placement="bottom-start"
        onClose={onClose}
        referenceElement={menuReferenceElement}
      >
        <p className="font-bold px-4 pt-3 pb-1 caption">
          {t('WorkspaceSelect.yourWorkspaces')}
        </p>

        {workspaces?.length ? (
          workspaces.map((workspace) => (
            <MenuItem
              key={workspace.id}
              disabled={workspace.id === currentUser.currentWorkspaceId}
              onClick={() => handleChangeWorkspace(workspace.id)}
            >
              {workspace.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem className="empty-state text-sm">
            {t('WorkspacesSelect.empty-workspaces')}
          </MenuItem>
        )}

        <hr className="mx-4" />

        <MenuItem to="/create-workspace">
          <AddIcon className="w-5 h-5 mr-2" />
          <p className="opacity-75">{t('WorkspaceSelect.create')}</p>
        </MenuItem>
      </Menu>
    </>
  );
}
