import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import useOpen from '../System/useOpen';
import CreateIssueDialog from './CreateIssueDialog';

export default function CreateIssueButton({
  className,
  test,
  stepId,
  onClick = () => {},
  ...rest
}) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();

  return (
    <>
      <Button
        noBg
        className={clsx(
          'duration-150 bg-secondary-main text-secondary-contrast-text',
          className,
        )}
        onClick={async (event) => {
          await onClick(event);
          onOpen();
        }}
        {...rest}
      >
        {t('CreateIssueButton.createIssue')}
      </Button>

      <CreateIssueDialog
        test={test}
        stepId={stepId}
        open={open}
        onClose={onClose}
      />
    </>
  );
}
