import { gql, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import TestReminder from './TestReminder';
import Timeline from '../Timeline/Timeline';
import CreateIssueComment from './CreateIssueComment';
import IssueStatusChip from './IssueStatusChip';
import { useMemo } from 'react';
import IssueCommentFragment from '../../fragments/IssueCommentFragment';
import IssueActionFragment from '../../fragments/IssueActionFragment';
import Button from '../System/Button';
import DoubleArrow from '../Icons/DoubleArrow';

const GET_ISSUE = gql`
  query GetIssue($issueId: String!) {
    getIssue(issueId: $issueId) {
      id
      createdAt
      number
      status
      step {
        id
        title
      }
      user {
        id
        username
      }
      actions {
        ...IssueActionFragment
      }
      comments {
        ...IssueCommentFragment
      }
      test {
        id
        title
        description
        status
        folderId
        steps {
          id
          title
        }
      }
    }
  }

  ${IssueActionFragment}
  ${IssueCommentFragment}
`;

// i18next parser
//
// t('Issue.actions.OPEN');
// t('Issue.actions.CLOSE');

export default function Issue({ className }) {
  const { issueId } = useParams();

  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_ISSUE, {
    variables: { issueId },
  });

  const issue = data?.getIssue;

  const timelineItems = useMemo(() => {
    return issue
      ? [
          ...issue.comments.map((c) => ({
            ...c,
            html: c.comment,
            type: 'comment',
          })),
          ...issue.actions.map((a) => ({
            ...a,
            text: ` ${a.user.username} ${t(`Issue.actions.${a.action}`)}`,
            type: 'action',
          })),
        ].sort((a, b) => (Number(a.createdAt) < Number(b.createdAt) ? -1 : 1))
      : [];
  }, [issue, t]);

  if (error) {
    return <p>{t('Global.serverError')}</p>;
  }

  return loading ? null : (
    <div
      className={clsx(
        'bg-background-paper flex flex-col overflow-auto',
        className,
      )}
    >
      <section className="flex items-center px-6 pt-4">
        <p className="caption flex-1">
          <span className="mr-1">#{issue.number}</span>
          {t('Global.createdAt', {
            datetime: DateTime.fromMillis(
              Number(issue.createdAt),
            ).toLocaleString(DateTime.DATETIME_MED),
            author: issue.user.username,
          })}
        </p>

        <Button
          endIcon={<DoubleArrow />}
          size="small"
          variant="outlined"
          className="mr-4 text-sm no-underline"
          component={Link}
          to={`/tests/${issue.test.folderId}/${issue.test.id}`}
        >
          {t('Issue.goToTest')}
        </Button>

        <IssueStatusChip status={issue.status} />
      </section>

      <hr className="mt-4" />

      <div className="overflow-auto">
        {issue.test ? (
          <TestReminder
            className="px-6 pt-4 pb-4 border-b border-divider"
            test={issue.test}
            selectedStepId={issue.step?.id}
          />
        ) : null}

        <div className="bg-background-default px-6 py-8">
          <Timeline items={timelineItems} />
        </div>

        <hr />

        <section className="px-6 py-8">
          <CreateIssueComment issue={issue} />
        </section>
      </div>
    </div>
  );
}
