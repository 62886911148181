import React, { useEffect, useRef, useState } from 'react';
import RadioButtonCheck from '../Icons/RadioButtonCheck';
import Stop from '../Icons/Stop';
import Button from '../System/Button';
import Dialog from '../System/Dialog';
import { useTranslation } from 'react-i18next';
import DialogContent from '../System/DialogContent';
import DialogTitle from '../System/DialogTitle';
import DialogActions from '../System/DialogActions';
import clsx from 'clsx';
import Save from '../Icons/Save';
import { DateTime } from 'luxon';
import ArrowDropDown from '../Icons/ArrowDropDown';
import { motion } from 'framer-motion';

const GET_DEFAULT_DELAY = () => Number(localStorage.getItem('kt-delay') ?? 4);

export default function Capture({ open, onClose, onSave }) {
  const { t } = useTranslation();

  const recorderRef = useRef();
  const mediaStreamRef = useRef();
  const recordingStartTimeRef = useRef();

  const [recording, setRecording] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [data, setData] = useState(null);
  const delayTimeoutRef = useRef(null);
  const refreshTimeoutRef = useRef(null);

  const playbackVideoRef = useRef();
  const liveVideoRef = useRef();

  const [delay, setDelay] = useState(GET_DEFAULT_DELAY());

  // Clear refresh timeout on component unmount
  useEffect(() => {
    return () => {
      if (!recording && refreshTimeoutRef.current) {
        clearInterval(refreshTimeoutRef.current);
        refreshTimeoutRef.current = null;
        setRecordingDuration(GET_DEFAULT_DELAY());
      }

      if (!recording && delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
        delayTimeoutRef.current = null;
      }
    };
  });

  const handleDurationRefresh = () => {
    const now = new Date();
    const past = now.valueOf() - recordingStartTimeRef.current.valueOf();

    setRecordingDuration(Math.round(past / 1000));
  };

  const handleStart = async () => {
    mediaStreamRef.current = await navigator.mediaDevices.getDisplayMedia();

    recordingStartTimeRef.current = new Date(
      new Date().valueOf() + delay * 1000,
    );

    recorderRef.current = new MediaRecorder(mediaStreamRef.current, {
      mimeType: 'video/webm;codecs=H264', // compare quality without H264 ?
    });

    recorderRef.current.ondataavailable = ({ data }) => {
      setData(data);
    };

    mediaStreamRef.current.getVideoTracks()[0].onended = () => {
      if (refreshTimeoutRef.current) {
        clearInterval(refreshTimeoutRef.current);
      }

      if (delayTimeoutRef.current) {
        clearTimeout(delayTimeoutRef.current);
      }

      setRecording(false);
    };

    setRecording(true);
    setRecordingDuration(-delay);
    refreshTimeoutRef.current = setInterval(handleDurationRefresh, 1000);

    delayTimeoutRef.current = setTimeout(() => {
      recorderRef.current.start();
    }, delay * 1000);
  };

  useEffect(() => {
    if (recording && mediaStreamRef.current) {
      liveVideoRef.current.srcObject = mediaStreamRef.current;
    }
  }, [recording]);

  useEffect(() => {
    if (data) {
      playbackVideoRef.current.src = URL.createObjectURL(data);
    }
  }, [data]);

  const handleStop = () => {
    try {
      recorderRef.current.stop();
    } catch (err) {}

    if (refreshTimeoutRef.current) {
      clearInterval(refreshTimeoutRef.current);
    }

    if (delayTimeoutRef.current) {
      clearTimeout(delayTimeoutRef.current);
    }

    // Removes the recording banner
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(function (track) {
        try {
          track.stop();
        } catch (err) {}
      });
    }
    setRecording(false);
  };

  const handleSave = () => {
    if (onSave) {
      const file = new File(
        [data],
        `Capture_Keep_Testing_${DateTime.now().toFormat(
          'yyyy_MM_dd_HH_mm_ss',
        )}.webm`,
        {
          type: data.type,
        },
      );
      onSave(file);
    }
  };

  return (
    <Dialog maxWidth="4xl" open={open} onClose={recording ? null : onClose}>
      <DialogTitle>{t('Capture.dialogTitle')}</DialogTitle>
      <DialogContent>
        <p className="mb-8">{t('Capture.helper')}</p>

        <div className="flex items-center justify-center">
          {recording ? (
            <div>
              <div className="px-12 py-8 border border-divider rounded bg-background-paper flex flex-col items-center">
                <motion.span
                  key={recordingDuration < -1 ? recordingDuration : null}
                  className="text-3xl font-bold mb-2"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.25 }}
                >
                  {recordingDuration} s
                </motion.span>
                <span className="mb-4">
                  {recordingDuration >= 0 ? (
                    <span className="flex items-center">
                      <motion.span
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 0.25 }}
                        transition={{
                          repeat: Infinity,
                          duration: 1,
                          repeatType: 'reverse',
                        }}
                      >
                        <RadioButtonCheck className="w-6 mr-2 text-red-500 fill-current" />
                      </motion.span>
                      {t('Capture.recording')}
                    </span>
                  ) : (
                    <span>{t('Capture.recordingSoon')}</span>
                  )}
                </span>

                <div className="rounded overflow-hidden mb-4 shadow-lg bg-background-default">
                  <video
                    autoPlay
                    muted
                    ref={liveVideoRef}
                    className="bg-background-paper"
                  />
                </div>

                <Button
                  className="bg-background-paper"
                  variant="outlined"
                  startIcon={<Stop />}
                  onClick={handleStop}
                >
                  {t('Capture.stopRecording')}
                </Button>
              </div>
            </div>
          ) : data ? null : (
            <div>
              <div className="flex justify-center">
                <Button
                  classes={{ startIcon: 'w-8 mb-4 text-red-400' }}
                  className="px-12 py-8 border border-red-200 bg-red-50 flex-col"
                  noPadding
                  startIcon={<RadioButtonCheck />}
                  onClick={handleStart}
                >
                  <span className="text-red-600">
                    {t('Capture.startRecording')}
                  </span>
                </Button>
              </div>

              <div className="flex flex-col items-center mt-6">
                <p className="mr-2 mb-2">
                  <strong>{t('Capture.delay')}</strong>
                </p>

                <div className="flex">
                  <Button
                    noPadding
                    noShadow
                    squared
                    variant="outlined"
                    className="w-10 h-10"
                    disabled={delay === 0}
                    onClick={() => {
                      setDelay((prev) => {
                        const newDelay = Math.max(0, prev - 1);
                        localStorage.setItem('kt-delay', newDelay);
                        return newDelay;
                      });
                    }}
                  >
                    <ArrowDropDown
                      className={clsx(
                        'transform rotate-90',
                        delay === 0 && 'opacity-10',
                      )}
                    />
                  </Button>

                  <div className="flex items-center border-t border-b border-divider py-1 px-4 w-32">
                    <p>{t('Capture.delayTime', { count: delay })}</p>
                  </div>

                  <Button
                    noPadding
                    noShadow
                    squared
                    variant="outlined"
                    className={clsx('w-10 h-10')}
                    disabled={delay === 9}
                    onClick={() => {
                      setDelay((prev) => {
                        const newDelay = Math.min(9, prev + 1);
                        localStorage.setItem('kt-delay', newDelay);
                        return newDelay;
                      });
                    }}
                  >
                    <ArrowDropDown
                      className={clsx(
                        'transform -rotate-90',
                        delay === 9 && 'opacity-10',
                      )}
                    />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Always keep it mounted so playbackVideoRef exists */}
        <div className={clsx(data && !recording ? 'block' : 'hidden')}>
          <div className="rounded overflow-hidden mb-4 shadow-lg bg-background-default">
            <video
              autoPlay
              muted
              loop
              controls
              ref={playbackVideoRef}
              style={{ backgroundColor: '#ccc' }}
            />
          </div>

          <div className="flex justify-center mt-8 mb-4">
            <div className="mr-4">
              <div>
                <Button
                  variant="outlined"
                  startIcon={<RadioButtonCheck />}
                  onClick={handleStart}
                >
                  {t('Capture.startNewRecord')}
                </Button>
              </div>
              <p className="caption text-center mt-1">
                {t('Capture.restartHelper')}
              </p>
            </div>

            <div>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Save />}
                onClick={handleSave}
              >
                {t('Capture.saveRecord')}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions onClose={recording ? null : onClose} />
    </Dialog>
  );
}
