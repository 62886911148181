import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export default function MenuItem({
  className,
  disabled,
  to,
  onClick,
  children,
  ...rest
}) {
  const Element = useMemo(() => {
    return to
      ? ({ children }) => (
          <Link className="no-underline" to={to}>
            {children}
          </Link>
        )
      : ({ children }) => <>{children}</>;
  }, [to]);

  return (
    <Element to={to}>
      <li
        className={clsx(
          'px-4 py-3 whitespace-nowrap flex items-center',
          disabled && 'opacity-30',
          disabled
            ? 'cursor-default'
            : onClick || to
            ? 'cursor-pointer hover:bg-background-default'
            : 'cursor-default',
          className,
        )}
        onClick={disabled ? null : onClick}
        {...rest}
      >
        {children}
      </li>
    </Element>
  );
}
