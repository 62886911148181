import { gql, useMutation } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import useCurrentUser from '../CurrentUser/useCurrentUser';

const GOOGLE_SIGN_IN = gql`
  mutation GoogleSignIn($idToken: String!, $joinWorkspaceId: String) {
    googleSignIn(idToken: $idToken, joinWorkspaceId: $joinWorkspaceId) {
      idToken
    }
  }
`;

const loadGoogleScript = (cb) => {
  const elementId = 'google-login-script';
  const existingElement = document.getElementById(elementId);
  if (!existingElement) {
    const js = document.createElement('script');
    js.id = elementId;
    js.src = 'https://apis.google.com/js/platform.js';
    js.defer = true;
    js.onload = cb;
    document.body.appendChild(js);
  } else {
    cb();
  }
};

export default function useGoogleSignInButton({ joinWorkspaceId }) {
  const [error, setError] = useState(false);

  const buttonRef = useRef();

  const [googleSignIn, { loading }] = useMutation(GOOGLE_SIGN_IN);

  const { setCurrentUserFromToken } = useCurrentUser();

  const handleSignedIn = useCallback(
    async (googleUser) => {
      const idToken = googleUser.getAuthResponse().id_token;
      const { data } = await googleSignIn({
        variables: { idToken, joinWorkspaceId },
      });

      setCurrentUserFromToken({ idToken: data.googleSignIn.idToken });
    },
    [setCurrentUserFromToken, googleSignIn, joinWorkspaceId],
  );

  useEffect(() => {
    const initGoogleScript = async () => {
      setError(false);

      await new Promise((resolve) => {
        loadGoogleScript(resolve);
      });

      const gapi = window.gapi;

      if (!gapi) {
        setError(true);
      } else {
        gapi.load('auth2', function () {
          // Retrieve the singleton for the GoogleAuth library and set up the client.
          const auth2 = gapi.auth2.init({
            client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
            cookiepolicy: 'single_host_origin',
            // Request scopes in addition to 'profile' and 'email'
            //scope: 'additional_scope'
          });
          if (!auth2) {
            setError(true);
          } else {
            auth2.attachClickHandler(
              buttonRef.current,
              {},
              handleSignedIn,
              function () {
                setError(true);
              },
            );
          }
        });
      }
    };

    initGoogleScript();
  }, [handleSignedIn]);

  return { error, loading, buttonRef };
}
