import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AuthLayout from '../components/Auth/AuthLayout';
import Success from '../components/Icons/Success';

export default function ForgotPasswordSuccessPage() {
  const { t } = useTranslation();

  return (
    <AuthLayout title={t('ForgotPasswordSuccessPage.title')}>
      <div className="flex flex-col items-center bg-green-50 px-6 py-4 my-8 rounded">
        <p>
          <Success className="text-green-500 fill-current w-16 h-16" />
        </p>

        <p className="mt-4 text-center text-green-800">
          {t('ForgotPasswordSuccessPage.success')}
        </p>
      </div>

      <p className=" mt-8 text-sm no-underline hover:underline">
        <Link to="/sign-in">{t('ForgotPasswordPage.backToSignIn')}</Link>
      </p>
    </AuthLayout>
  );
}
