export default function Language({ fill, ...rest }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 36"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M9.49991 25C9.32812 24.9995 9.15937 24.9547 9.00991 24.87C8.77948 24.7397 8.61008 24.5234 8.53884 24.2685C8.46761 24.0136 8.50036 23.7408 8.62991 23.51L13.6299 14.51C13.7625 14.2792 13.9814 14.1106 14.2383 14.0412C14.4952 13.9719 14.7692 14.0074 14.9999 14.14C15.2307 14.2726 15.3993 14.4914 15.4686 14.7483C15.538 15.0053 15.5025 15.2792 15.3699 15.51L10.3699 24.51C10.2805 24.6607 10.1531 24.7853 10.0004 24.8713C9.84776 24.9573 9.67515 25.0017 9.49991 25Z" />
      <path d="M19.4995 25C19.3223 24.9997 19.1484 24.9523 18.9955 24.8627C18.8427 24.7731 18.7164 24.6445 18.6295 24.49L13.6295 15.49C13.5639 15.3758 13.5214 15.2497 13.5044 15.119C13.4875 14.9883 13.4964 14.8556 13.5308 14.7284C13.5652 14.6012 13.6242 14.482 13.7047 14.3776C13.7851 14.2732 13.8853 14.1857 13.9995 14.12C14.2303 13.9874 14.5042 13.9519 14.7612 14.0213C14.8884 14.0556 15.0076 14.1147 15.112 14.1951C15.2164 14.2756 15.3039 14.3758 15.3695 14.49L20.3695 23.49C20.4372 23.6046 20.4814 23.7315 20.4994 23.8633C20.5174 23.9951 20.509 24.1293 20.4745 24.2578C20.4401 24.3863 20.3803 24.5066 20.2987 24.6118C20.2172 24.7169 20.1155 24.8047 19.9995 24.87C19.8471 24.9564 19.6747 25.0012 19.4995 25V25Z" />
      <path d="M17.8996 22.11H11.0996C10.8344 22.11 10.58 22.0046 10.3925 21.8171C10.205 21.6296 10.0996 21.3752 10.0996 21.11C10.0996 20.8448 10.205 20.5904 10.3925 20.4029C10.58 20.2153 10.8344 20.11 11.0996 20.11H17.8996C18.1648 20.11 18.4192 20.2153 18.6067 20.4029C18.7943 20.5904 18.8996 20.8448 18.8996 21.11C18.8996 21.3752 18.7943 21.6296 18.6067 21.8171C18.4192 22.0046 18.1648 22.11 17.8996 22.11V22.11Z" />
      <path d="M7.5 36C7.32482 36.0012 7.1524 35.9564 7 35.87C6.8474 35.7819 6.72078 35.655 6.63298 35.5023C6.54518 35.3495 6.4993 35.1762 6.5 35V30H5.88C4.45487 30 3.08796 29.4346 2.0793 28.4278C1.07064 27.421 0.502649 26.0551 0.5 24.63V14.38C0.5 12.9531 1.06682 11.5847 2.07577 10.5758C3.08471 9.56682 4.45314 9 5.88 9H24.13C25.5551 9.00265 26.921 9.57064 27.9278 10.5793C28.9346 11.588 29.5 12.9549 29.5 14.38V24.63C29.4974 26.0534 28.9307 27.4177 27.9242 28.4242C26.9177 29.4307 25.5534 29.9974 24.13 30H18.13L8 35.87C7.8476 35.9564 7.67518 36.0012 7.5 36V36ZM5.88 11C4.98357 11 4.12385 11.3561 3.48998 11.99C2.85611 12.6239 2.5 13.4836 2.5 14.38V24.63C2.5 25.0734 2.5875 25.5124 2.75748 25.922C2.92746 26.3315 3.17659 26.7034 3.49058 27.0165C3.80458 27.3296 4.17726 27.5776 4.58729 27.7463C4.99731 27.9151 5.4366 28.0013 5.88 28H7.5C7.76522 28 8.01957 28.1054 8.20711 28.2929C8.39464 28.4804 8.5 28.7348 8.5 29V33.27L17.4 28.13C17.5534 28.0461 17.7252 28.0014 17.9 28H24.13C25.0238 28 25.881 27.6449 26.513 27.013C27.1449 26.381 27.5 25.5238 27.5 24.63V14.38C27.5013 13.9366 27.4151 13.4973 27.2463 13.0873C27.0776 12.6773 26.8296 12.3046 26.5165 11.9906C26.2034 11.6766 25.8315 11.4275 25.422 11.2575C25.0124 11.0875 24.5734 11 24.13 11H5.88Z" />
      <path d="M39.5 8H31.5C31.2348 8 30.9804 7.89464 30.7929 7.70711C30.6054 7.51957 30.5 7.26522 30.5 7C30.5 6.73478 30.6054 6.48043 30.7929 6.29289C30.9804 6.10536 31.2348 6 31.5 6H39.5C39.7652 6 40.0196 6.10536 40.2071 6.29289C40.3946 6.48043 40.5 6.73478 40.5 7C40.5 7.26522 40.3946 7.51957 40.2071 7.70711C40.0196 7.89464 39.7652 8 39.5 8Z" />
      <path d="M35.5 8C35.2348 8 34.9804 7.89464 34.7929 7.70711C34.6054 7.51957 34.5 7.26522 34.5 7V5C34.5 4.73478 34.6054 4.48043 34.7929 4.29289C34.9804 4.10536 35.2348 4 35.5 4C35.7652 4 36.0196 4.10536 36.2071 4.29289C36.3946 4.48043 36.5 4.73478 36.5 5V7C36.5 7.26522 36.3946 7.51957 36.2071 7.70711C36.0196 7.89464 35.7652 8 35.5 8Z" />
      <path d="M33.5003 15C33.3156 14.9995 33.1347 14.9478 32.9776 14.8507C32.8205 14.7536 32.6934 14.6149 32.6103 14.45C32.4917 14.2139 32.4714 13.9403 32.5538 13.6892C32.6363 13.4382 32.8148 13.2299 33.0503 13.11C34.2003 12.53 36.1003 8.96003 37.0503 6.62003C37.0909 6.48667 37.1589 6.36327 37.2501 6.25776C37.3412 6.15225 37.4534 6.06696 37.5794 6.00736C37.7054 5.94776 37.8425 5.91518 37.9819 5.91169C38.1213 5.9082 38.2598 5.93389 38.3887 5.98712C38.5175 6.04034 38.6338 6.11991 38.7301 6.22073C38.8264 6.32155 38.9005 6.44139 38.9477 6.57255C38.995 6.70372 39.0143 6.84331 39.0044 6.98237C38.9945 7.12142 38.9556 7.25688 38.8903 7.38003C38.4503 8.44003 36.1403 13.78 33.8903 14.89C33.7699 14.9549 33.6368 14.9925 33.5003 15Z" />
      <path d="M39.4998 15C39.3032 15.0022 39.1109 14.9428 38.9498 14.83L32.9498 10.83C32.7558 10.6737 32.6271 10.4506 32.5889 10.2044C32.5508 9.95813 32.6059 9.70653 32.7436 9.49883C32.8812 9.29113 33.0914 9.14229 33.333 9.08147C33.5747 9.02064 33.8303 9.05221 34.0498 9.16998L40.0498 13.17C40.2272 13.2891 40.3617 13.462 40.4337 13.6631C40.5057 13.8643 40.5113 14.0832 40.4498 14.2878C40.3883 14.4925 40.2629 14.672 40.0919 14.8001C39.921 14.9283 39.7135 14.9983 39.4998 15Z" />
      <path d="M41.5 27C41.3531 27.0002 41.2079 26.9681 41.0748 26.9059C40.9418 26.8437 40.824 26.7529 40.73 26.64L36 21H28.5C28.2348 21 27.9804 20.8946 27.7929 20.7071C27.6054 20.5196 27.5 20.2652 27.5 20C27.5 19.7348 27.6054 19.4804 27.7929 19.2929C27.9804 19.1054 28.2348 19 28.5 19H36.5C36.6469 18.9998 36.7921 19.0319 36.9252 19.0941C37.0582 19.1563 37.176 19.2471 37.27 19.36L40.5 23.24V20C40.5 19.7348 40.6054 19.4804 40.7929 19.2929C40.9804 19.1054 41.2348 19 41.5 19H44.12C45.0156 18.9974 45.8738 18.6404 46.5071 18.0071C47.1404 17.3738 47.4974 16.5156 47.5 15.62V5.38C47.4974 4.48438 47.1404 3.6262 46.5071 2.99289C45.8738 2.35959 45.0156 2.00264 44.12 2H25.88C24.9844 2.00264 24.1262 2.35959 23.4929 2.99289C22.8596 3.6262 22.5026 4.48438 22.5 5.38V10C22.5 10.2652 22.3946 10.5196 22.2071 10.7071C22.0196 10.8946 21.7652 11 21.5 11C21.2348 11 20.9804 10.8946 20.7929 10.7071C20.6054 10.5196 20.5 10.2652 20.5 10V5.38C20.5 3.95313 21.0668 2.58471 22.0758 1.57577C23.0847 0.56682 24.4531 0 25.88 0H44.12C45.5469 0 46.9153 0.56682 47.9242 1.57577C48.9332 2.58471 49.5 3.95313 49.5 5.38V15.62C49.5 17.0469 48.9332 18.4153 47.9242 19.4242C46.9153 20.4332 45.5469 21 44.12 21H42.5V26C42.4999 26.2055 42.4365 26.406 42.3184 26.5742C42.2003 26.7424 42.0333 26.8701 41.84 26.94C41.731 26.9796 41.616 26.9999 41.5 27Z" />
    </svg>
  );
}
