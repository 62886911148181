export default function ScheduledDate({ checked, label, onChange }) {
  return (
    <div className="mt-4 flex">
      <label className="text-sm italic flex items-center">
        <input
          className="mr-2 rounded-sm"
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
    </div>
  );
}
