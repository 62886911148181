const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

export default function getLinksFromText(text) {
  const matches = text.match(URL_REGEX) || [];
  const indexes = matches.reduce((acc, match, index) => {
    const previousIndex = index === 0 ? 0 : acc[acc.length - 1].index;
    return [
      ...acc,
      {
        url: match,
        index:
          text
            .slice(
              previousIndex + (index === 0 ? 0 : matches[index - 1].length),
            )
            .indexOf(match) +
          previousIndex +
          (index === 0 ? 0 : matches[index - 1].length),
      },
    ];
  }, []);

  return indexes;
}
