import { Form, Formik } from 'formik';
import TextField from '../System/TextField';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import { gql, useMutation } from '@apollo/client';
import useCurrentLanguage from '../Language/useCurrentLanguage';
import { useHistory } from 'react-router';

const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!, $lang: LANG) {
    requestPasswordReset(email: $email, lang: $lang)
  }
`;

export default function ForgotPasswordForm() {
  const { t } = useTranslation();

  const currentLanguage = useCurrentLanguage();

  const [submitForgotPassword] = useMutation(REQUEST_PASSWORD_RESET);

  const { replace } = useHistory();

  const handleSubmit = async ({ email }) => {
    await submitForgotPassword({
      variables: { email, lang: currentLanguage.toUpperCase() },
    });

    replace('/forgot-password-success');
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t('Global.invalidEmail'))
          .required(t('Global.requiredField')),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <p className="mb-6">{t('ForgotPasswordForm.subtitle')}</p>
          <div>
            <TextField
              label={t('ForgotPasswordForm.emailLabel')}
              name="email"
              type="email"
            />
          </div>
          <div className="w-full flex">
            <Button
              loading={isSubmitting}
              className="flex-1"
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('ForgotPasswordForm.submit')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
