import CircularProgress from '../System/CircularProgress';
import ScheduledTest from './ScheduledTest';
import useScheduledTests from './useScheduledTests';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Warning from '../Icons/Warning';
import Error from '../Icons/Error';

export default function ScheduledTests() {
  const { t } = useTranslation();
  const { loading, grouppedSchedules } = useScheduledTests();

  return loading ? (
    <div>
      <CircularProgress />
    </div>
  ) : grouppedSchedules.length ? (
    <div>
      {grouppedSchedules.map(({ limitDate, scheduledTests }) => {
        const date = limitDate?.toLocaleString({
          ...DateTime.DATE_HUGE,
          timeZoneName: undefined,
        });

        const diffDays =
          limitDate && Math.ceil(limitDate.diffNow('day').toObject().days);
        const isLate = diffDays != null && diffDays <= 0;
        const isToday = diffDays === 1;

        return (
          <div
            className="mb-8"
            key={limitDate ? limitDate.toFormat('yyyy-MM-dd') : 'no-limit-date'}
          >
            <h3
              className={clsx(
                'flex items-center mb-4',
                isLate ? 'text-red-500' : isToday ? 'text-yellow-600' : null,
              )}
            >
              {isToday ? (
                <Warning className="w-6 mr-2 fill-current" />
              ) : isLate ? (
                <Error className="w-6 mr-2 fill-current" />
              ) : null}
              {date
                ? `${date.charAt(0).toUpperCase()}${date.slice(1)}`
                : t('ScheduledTests.noLimitDate')}
            </h3>
            {scheduledTests.map((scheduledTest) => {
              return (
                <ScheduledTest
                  key={scheduledTest.id}
                  scheduledTest={scheduledTest}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  ) : (
    <p>{t('ScheduledTests.emptyState')}</p>
  );
}
