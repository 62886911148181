import { useState } from 'react';
import { getPreviousMediaId, getNextMediaId } from './utils';

export default function useCarousel({ mediaList }) {
  const [currentMediaId, setCurrentMediaId] = useState(
    mediaList.length && mediaList[0].id,
  );

  const handlePrevious = () => {
    const index = getPreviousMediaId({ currentMediaId, mediaList });
    if (index) {
      setCurrentMediaId(mediaList[index].id);
    }
  };

  const handleNext = () => {
    const index = getNextMediaId({ currentMediaId, mediaList });
    if (index) {
      setCurrentMediaId(mediaList[index].id);
    }
  };

  return {
    currentMediaId,
    onPrevious: handlePrevious,
    onNext: handleNext,
    onMediaChange: (id) => setCurrentMediaId(id),
  };
}
