import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import CreateWorkspacePage from '../../pages/create-workspace';
import TestsPage from '../../pages/tests';
import IssuesPage from '../../pages/issues';
import useWorkspaces from '../Workspace/useWorkspaces';
import AgendaPage from '../../pages/agenda';
import SettingsPage from '../../pages/settings';
import JoinWorkspacePage from '../../pages/join-workspace';
import useCurrentUser from '../CurrentUser/useCurrentUser';
import ProfilePage from '../../pages/profile';
import BillingPage from '../../pages/billing';

export default function AppRouter() {
  const { loading, workspaces } = useWorkspaces();
  const { pathname } = useLocation();
  const { currentUser } = useCurrentUser();

  if (loading) {
    return null;
  }

  if (
    !pathname.startsWith('/join-workspace/') &&
    pathname !== '/create-workspace' &&
    pathname !== '/me' &&
    pathname !== '/billing'
  ) {
    if (currentUser.invites.length) {
      return (
        <Redirect
          to={`/join-workspace/${currentUser.invites[0].workspace_id}`}
        />
      );
    } else {
      if (!workspaces.length) {
        return <Redirect to="/create-workspace" />;
      } else if (!currentUser.currentWorkspaceId) {
        return null;
      }
    }
  }

  return (
    <Switch>
      <Route path="/join-workspace/:workspaceId">
        <JoinWorkspacePage />
      </Route>

      <Route path="/create-workspace">
        <CreateWorkspacePage />
      </Route>

      <Route path="/tests/:folderId?/:testId?">
        <TestsPage />
      </Route>
      <Route path="/issues/:issueId?">
        <IssuesPage />
      </Route>
      <Route path="/agenda">
        <AgendaPage />
      </Route>

      {currentUser.workspaceRoles.includes('OWNER') ? (
        <Route path="/settings">
          <SettingsPage />
        </Route>
      ) : null}

      <Route path="/me">
        <ProfilePage />
      </Route>

      <Route path="/billing">
        <BillingPage />
      </Route>

      <Redirect to="/tests" />
    </Switch>
  );
}
