import { gql, useMutation } from '@apollo/client';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import ConfirmDialog from '../System/ConfirmDialog';
import useOpen from '../System/useOpen';
import useSnackbar from '../System/useSnackbar';
import UpdateScheduledTests from '../TestScheduler/UpdateScheduledTests';
import useUpdateScheduledTest from '../TestScheduler/useUpdateScheduledTests';

export const CREATE_TEST_PASSED = gql`
  mutation CreatePassedTest($testId: String!, $scheduledTestIds: [String!]) {
    createPassedTest(testId: $testId, scheduledTestIds: $scheduledTestIds) {
      id
      createdAt
      user {
        id
        username
      }
    }
  }
`;

export default function CreatePassedTestButton({
  className,
  test,
  onClick = () => {},
  ...rest
}) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();

  const { showSnackbar } = useSnackbar();

  // Scheduled tests
  const {
    assignedScheduledTests,
    checkedScheduledTests,
    setCheckedScheduledTests,
  } = useUpdateScheduledTest({ testScheduledTests: test?.scheduledTests });

  const [createPassedTest, { loading }] = useMutation(CREATE_TEST_PASSED, {
    refetchQueries: ['GetTest'],
    variables: {
      testId: test.id,
      scheduledTestIds: checkedScheduledTests.map((t) => t.id),
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  // Handle passed test and mark scheduled tests on submit
  const handleSubmit = async () => {
    await createPassedTest();

    showSnackbar(t('CreateTestPassedButton.success'), 'success');
    onClose();
  };

  return (
    <>
      <Button
        noBg
        className={clsx('duration-150 bg-green-500 text-black', className)}
        onClick={async (event) => {
          await onClick(event);
          onOpen();
        }}
        {...rest}
      >
        {t('CreatePassedTestButton.create')}
      </Button>

      <ConfirmDialog
        data-cy="success-dialog"
        loading={loading}
        open={open}
        maxWidth="2xl"
        onConfirm={handleSubmit}
        onClose={onClose}
      >
        <p>{t('CreatePassedTestButton.confirm')}</p>

        <UpdateScheduledTests
          assignedScheduledTests={assignedScheduledTests}
          checkedScheduledTests={checkedScheduledTests}
          setCheckedScheduledTests={setCheckedScheduledTests}
        />
      </ConfirmDialog>
    </>
  );
}
