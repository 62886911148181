import clsx from 'clsx';
import InfoIcon from '../Icons/Info';
import StepList from '../Tests/Step/StepList';
import { useTranslation } from 'react-i18next';
import useOpen from '../System/useOpen';
import Button from '../System/Button';
import KeyboardArrowDownIcon from '../Icons/KeyboardArrowDown';
import { Link } from 'react-router-dom';

const MAX_TITLE = 50;

export default function TestReminder({ className, test, selectedStepId }) {
  const { t } = useTranslation();

  const { open, onToggle } = useOpen();

  return (
    <section className={clsx(className)}>
      <p className="overflow-ellipsis overflow-hidden whitespace-nowrap mb-4">
        <span className="font-bold">{t('TestReminder.reminder')}</span>{' '}
        <Link
          to={`/tests/${test.folderId}/${test.id}`}
          className="no-underline hover:underline"
        >
          {!open && test.title.length > MAX_TITLE
            ? `${test.title.slice(0, MAX_TITLE)}...`
            : test.title}
        </Link>
      </p>

      {!open ? null : (
        <p
          className="mb-4"
          dangerouslySetInnerHTML={
            test.description
              ? {
                  __html: test.description,
                }
              : null
          }
        />
      )}

      {selectedStepId ? (
        <div className="flex items-center bg-background-default px-2 py-0.5 rounded-sm mb-4 caption italic">
          <InfoIcon className="mr-2 h-5 w-5" />
          {t('TestReminder.hasSelectedStep')}
        </div>
      ) : null}

      {open ? (
        <StepList
          className="mb-4"
          test={test}
          selected={selectedStepId}
          readOnly
          dense
        />
      ) : null}

      {selectedStepId || test.title.length > MAX_TITLE || test.description ? (
        <div>
          <Button
            size="small"
            variant="outlined"
            endIcon={
              <KeyboardArrowDownIcon
                className={clsx(
                  'transition-transform transform',
                  open ? ' -rotate-180' : 'rotate-0',
                )}
              />
            }
            onClick={onToggle}
          >
            {open ? t('Global.seeLess') : t('Global.seeMore')}
          </Button>
        </div>
      ) : null}
    </section>
  );
}
