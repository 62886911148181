import { useTranslation } from 'react-i18next';
import Language from '../Icons/Language';
import Button from '../System/Button';
import { Settings as luxonSettings } from 'luxon';
import useCurrentLanguage from './useCurrentLanguage';

export default function ChangeLanguage() {
  const { i18n } = useTranslation();

  const currentLanguage = useCurrentLanguage();

  const handlePointerDown = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    const newLanguage = currentLanguage === 'en' ? 'fr' : 'en';
    luxonSettings.defaultLocale = newLanguage;
    i18n.changeLanguage(newLanguage);
  };

  return (
    <Button
      variant="outlined"
      className="bg-background-paper"
      startIcon={<Language />}
      onPointerDown={handlePointerDown}
      onClick={handleClick}
    >
      {currentLanguage === 'en' ? 'Français' : 'English'}
    </Button>
  );
}
