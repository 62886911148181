import Button from '../../System/Button';
import { useTranslation } from 'react-i18next';
import EditIcon from '../../Icons/Edit';
import TestDialog from './TestDialog';
import useOpen from '../../System/useOpen';
import useTest from './useTest';
import { gql, useMutation } from '@apollo/client';
import useSnackbar from '../../System/useSnackbar';

const UPDATE_TEST = gql`
  mutation UpdateTest($test: UpdateTestInput!) {
    updateTest(test: $test) {
      id
      title
      description
      steps {
        id
        title
        position
      }
      actions {
        id
        createdAt
        action
        valueJSON
        user {
          id
          username
        }
      }
    }
  }
`;

export default function UpdateTest(props) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();

  const { test } = useTest();

  const { showSnackbar } = useSnackbar();

  const [updateTest] = useMutation(UPDATE_TEST, {
    onCompleted() {
      showSnackbar(t('UpdateTest.success'), 'success');
      onClose();
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  const handleSubmit = async ({ title, description, steps }) => {
    const filteredSteps = steps.filter((s) => !!s.title.length);
    if (
      JSON.stringify({
        title: test.title,
        description: test.description,
        steps: test.steps,
      }) === JSON.stringify({ title, description, steps: filteredSteps })
    ) {
      // We dont update if test values are the same
      onClose();
    } else {
      await updateTest({
        variables: {
          test: {
            testId: test.id,
            title,
            description,
            steps: filteredSteps.map((step) => ({
              id: step.id,
              title: step.title,
              position: step.position,
            })),
          },
        },
      });
    }
  };

  return (
    <>
      <div {...props}>
        <Button
          className="rounded-md"
          variant="outlined"
          size="small"
          startIcon={<EditIcon />}
          onClick={onOpen}
        >
          {t('UpdateTest.editButton')}
        </Button>
      </div>

      <TestDialog
        open={open}
        test={test}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </>
  );
}
