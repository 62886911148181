import DeleteForever from '../Icons/DeleteForever';
import Button from '../System/Button';
import Dialog from '../System/Dialog';
import DialogTitle from '../System/DialogTitle';
import { useTranslation } from 'react-i18next';
import useOpen from '../System/useOpen';
import DialogContent from '../System/DialogContent';
import DialogActions from '../System/DialogActions';
import { gql, useMutation } from '@apollo/client';
import useSnackbar from '../System/useSnackbar';
import { GET_TEST } from '../Tests/Test/useTest';
import clsx from 'clsx';

const DELETE_SCHEDULED_TEST = gql`
  mutation DeleteScheduledTest($id: String!) {
    deleteScheduledTest(id: $id)
  }
`;

export default function DeleteScheduledTest({
  className,
  scheduledTestId,
  testId,
  onDeleted,
}) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();

  const { showSnackbar } = useSnackbar();
  const [deleteScheduledTest, { loading }] = useMutation(
    DELETE_SCHEDULED_TEST,
    {
      variables: {
        id: scheduledTestId,
      },

      update(cache) {
        // Remove from agenda vue
        cache.modify({
          fields: {
            getScheduledTests(existingScheduledTests = []) {
              return existingScheduledTests.filter(
                ({ __ref }) =>
                  __ref !==
                  cache.identify({
                    id: scheduledTestId,
                    __typename: 'ScheduledTest',
                  }),
              );
            },
          },
        });

        // Remove from test view
        const { getTest } = cache.readQuery({
          query: GET_TEST,
          variables: { testId },
        });
        cache.writeQuery({
          query: GET_TEST,
          variables: { testId },
          data: {
            getTest: {
              ...getTest,
              scheduledTests: getTest.scheduledTests.filter(
                (s) => s.id !== scheduledTestId,
              ),
            },
          },
        });
      },
      onCompleted() {
        showSnackbar(t('DeleteScheduledTest.success'), 'success');

        if (onDeleted) {
          onDeleted();
        }

        onClose();
      },
    },
  );

  return (
    <>
      <Button
        data-cy="delete-scheduled-test"
        noPadding
        noShadow
        className={clsx('opacity-60 hover:opacity-100', className)}
        onClick={onOpen}
      >
        <DeleteForever className="w-6 h-6" />
      </Button>

      <Dialog
        data-cy="delete-scheduled-test-dialog"
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{t('DeleteScheduledTest.dialogTitle')}</DialogTitle>

        <DialogContent>
          <p>{t('DeleteScheduledTest.confirmText')}</p>
        </DialogContent>

        <DialogActions
          loading={loading}
          onCancel={onClose}
          confirmText={t('DeleteScheduledTest.submitText')}
          onConfirm={deleteScheduledTest}
        />
      </Dialog>
    </>
  );
}
