import { gql, useQuery } from '@apollo/client';

const GET_WORKSPACES = gql`
  query GetWorkspaces {
    getWorkspaces {
      id
      name
    }
  }
`;

export default function useWorkspaces({ skip } = {}) {
  const { loading, data } = useQuery(GET_WORKSPACES, {
    skip,
  });

  return { loading, workspaces: data?.getWorkspaces };
}
