import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function IssueStatusChip({ status }) {
  const { t } = useTranslation();

  return (
    <p
      className={clsx(
        'text-sm border border-opacity-50 px-2 py-1 rounded',
        status === 'OPEN'
          ? 'border-secondary-main bg-secondary-main bg-opacity-10 text-yellow-700'
          : 'border-green-300 bg-green-50 text-green-700',
      )}
    >
      {status === 'OPEN'
        ? t('IssueStatusChip.openStatus')
        : t('IssueStatusChip.closeStatus')}
    </p>
  );
}
