import { useApolloClient } from '@apollo/client';
import useCurrentUser from '../CurrentUser/useCurrentUser';

export default function useLogout() {
  const { setCurrentUserFromToken } = useCurrentUser();

  const apolloClient = useApolloClient();

  const handleLogout = () => {
    setCurrentUserFromToken({ idToken: null });
    localStorage.clear('kt_r');
    apolloClient.clearStore();
  };

  return { onLogout: handleLogout };
}
