import clsx from 'clsx';
import Document from '../Icons/Document';
import VideoIcon from '../Icons/Video';
import useOpen from '../System/useOpen';
import Carousel from './Carousel/Carousel';
import useCarousel from './Carousel/useCarousel';

export default function Gallery({ className, mediaList }) {
  const { open, onOpen, onClose } = useOpen();

  const { currentMediaId, onPrevious, onNext, onMediaChange } = useCarousel({
    mediaList,
  });

  return (
    <>
      <div className={clsx(className, 'grid grid-cols-3 gap-4')}>
        {mediaList.map((media) => {
          return (
            <div
              key={media.id}
              data-cy="gallery-item"
              className="flex flex-col bg-background-default rounded overflow-hidden cursor-pointer"
              onClick={() => {
                onMediaChange(media.id);
                onOpen();
              }}
            >
              <div
                className={clsx(
                  'w-full h-32 bg-cover bg-center mr-4 flex items-center justify-center',
                  'overflow-auto',
                )}
                style={{
                  backgroundImage: `url("${
                    media.thumbnail
                      ? media.thumbnail.src
                      : media.src || media.preview
                  }&w=400")`,
                }}
              >
                {media.contentType.startsWith(
                  'image/',
                ) ? null : media.contentType.startsWith('video/') ? (
                  <VideoIcon className="w-16 fill-current text-gray-700" />
                ) : (
                  <div className="flex flex-col items-center justify-center overflow-auto">
                    <Document className="w-12 fill-current text-gray-700" />
                    <p className="px-4 text-sm mt-2 max-w-full overflow-ellipsis overflow-hidden whitespace-nowrap">
                      {media.name}
                    </p>
                  </div>
                )}
              </div>

              {media.description ? (
                <p className="mt-2 px-2 pb-1 caption">{media.description}</p>
              ) : null}
            </div>
          );
        })}
      </div>
      <Carousel
        open={open}
        onClose={onClose}
        mediaList={mediaList}
        currentMediaId={currentMediaId}
        onPrevious={onPrevious}
        onNext={onNext}
      />
    </>
  );
}
