import { gql, useMutation } from '@apollo/client';
import useSignIn from '../SignIn/useSignIn';

const SIGN_UP = gql`
  mutation SignUp(
    $email: String!
    $password: String!
    $joinWorkspaceId: String
  ) {
    signUp(
      email: $email
      password: $password
      joinWorkspaceId: $joinWorkspaceId
    )
  }
`;

export default function useSignUp({ joinWorkspaceId } = {}) {
  const [signUp, { error }] = useMutation(SIGN_UP);

  const { onSubmit: signIn } = useSignIn();

  const handleSubmit = async (values) => {
    await signUp({
      variables: {
        email: values.email,
        password: values.password,
        joinWorkspaceId,
      },
    });

    await signIn(values);
  };

  return { error, onSubmit: handleSubmit };
}
