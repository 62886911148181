import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '../System/CircularProgress';
import { useTranslation } from 'react-i18next';
import Card from '../System/Card';
import Button from '../System/Button';
import DeclineIcon from '../Icons/HighlightOff';
import AcceptIcon from '../Icons/CheckCircle';
import useRefresh from '../Auth/useRefresh';
import useSetCurrentWorkspace from './useSetCurrentWorkspace';
import useCurrentUser from '../CurrentUser/useCurrentUser';
import { useEffect, useRef } from 'react';
import useWorkspaces from './useWorkspaces';
import useSnackbar from '../System/useSnackbar';

const GET_INVITE = gql`
  query GetWorkspaceInvite($workspaceId: String!) {
    getWorkspaceInvite(workspaceId: $workspaceId) {
      id
      workspace {
        id
        name
      }
    }
  }
`;

const ACCEPT_INVITE = gql`
  mutation AcceptWorkspaceInvite($inviteId: String!) {
    acceptWorkspaceInvite(inviteId: $inviteId)
  }
`;

const DECLINE_INVITE = gql`
  mutation DeclineWorkspaceInvite($inviteId: String!) {
    declineWorkspaceInvite(inviteId: $inviteId)
  }
`;

export default function JoinWorkspace() {
  const { t } = useTranslation();

  const { workspaceId } = useParams();
  const { replace } = useHistory();
  const { onRefresh } = useRefresh();
  const { workspaces } = useWorkspaces();

  const { data, loading, error } = useQuery(GET_INVITE, {
    variables: { workspaceId },
  });

  const { currentUser } = useCurrentUser();

  const { setCurrentWorkspace } = useSetCurrentWorkspace();
  const { showSnackbar } = useSnackbar();

  const waitForAcceptReloadRef = useRef(false);
  const waitForDeclineReloadRef = useRef(false);

  const [accept, { loading: loadingAccept }] = useMutation(ACCEPT_INVITE, {
    variables: {
      inviteId: data?.getWorkspaceInvite.id,
    },
    onCompleted() {
      const handleCompleted = async () => {
        await setCurrentWorkspace({ variables: { workspaceId } });
        waitForAcceptReloadRef.current = true;
        onRefresh();
      };
      handleCompleted();
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  const [decline] = useMutation(DECLINE_INVITE, {
    variables: {
      inviteId: data?.getWorkspaceInvite.id,
    },
    onCompleted() {
      waitForDeclineReloadRef.current = true;
      onRefresh();
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  useEffect(() => {
    if (waitForAcceptReloadRef.current) {
      if (
        (workspaces || []).find((w) => w.id === workspaceId) &&
        currentUser.currentWorkspaceId === workspaceId
      ) {
        replace('/');
      }
    } else if (waitForDeclineReloadRef.current) {
      if (!currentUser.invites.length) {
        replace('/');
      }
    }
  }, [currentUser, workspaces, replace, workspaceId]);

  return loading ||
    waitForAcceptReloadRef.current ||
    waitForDeclineReloadRef.current ? (
    <div className="flex-1 flex items-center justify-center">
      <CircularProgress />
    </div>
  ) : error ? (
    <p className="flex-1 flex items-center justify-center">
      {t('JoinWorkspacePage.error')}
    </p>
  ) : (
    <div className="flex-1 flex items-center justify-center">
      <Card className="w-full max-w-2xl">
        <h1>{t('JoinWorkspacePage.title')}</h1>

        <p className="mb-4">
          {t('JoinWorkspacePage.info', {
            workspaceName: data.getWorkspaceInvite.workspace.name,
          })}
        </p>
        <p>{t('JoinWorkspacePage.confirmText')}</p>

        <div className="flex items-center mt-6">
          <div className="mr-6">
            <Button
              loading={loadingAccept}
              variant="outlined"
              className="bg-green-50 text-green-900 border-green-200"
              startIcon={<AcceptIcon />}
              onClick={accept}
            >
              {t('JoinWorkspacePage.confirmButton')}
            </Button>
          </div>

          <div>
            <Button
              variant="outlined"
              className="text-red-800 border-red-200"
              startIcon={<DeclineIcon />}
              onClick={decline}
            >
              {t('JoinWorkspacePage.cancelButton')}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
