import TopBar from '../TopBar/TopBar';

export default function MainLayout({ children }) {
  return (
    <>
      <TopBar />
      <div className="flex-1 flex flex-col overflow-auto">{children}</div>
    </>
  );
}
