import Button from '../System/Button';
import useURLOpen from '../System/useURLOpen';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import Dialog from '../System/Dialog';
import DialogTitle from '../System/DialogTitle';
import DialogContent from '../System/DialogContent';
import { DateTime } from 'luxon';
import DialogActions from '../System/DialogActions';
import DeleteScheduledTest from './DeleteScheduledTest';
import { useParams } from 'react-router';
import UpdateScheduledTest from './UpdateScheduledTest';
import ScheduledTestResult from './ScheduledTestResult';

// i18next parser
//
// t('TestScheduledTests.test');
// t('TestScheduledTests.noLimitDate');

export default function TestScheduledTests({ className, scheduledTests }) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useURLOpen({
    name: 'scheduled-test',
  });

  const { testId } = useParams();

  return (
    <>
      <Button
        noBg
        noShadow
        noPadding
        disabled={!scheduledTests.length}
        className={clsx('text-sm hover:underline', className)}
        onClick={() => onOpen()}
      >
        {scheduledTests.length
          ? t('TestScheduledTests.schedulesCount', {
              doneCount: scheduledTests.filter(
                (s) =>
                  s.scheduledTestIssueResult || s.scheduledTestPassedResult,
              ).length,
              count: scheduledTests.length,
            })
          : null}
      </Button>

      <Dialog
        data-cy="scheduled-dialog"
        maxWidth="4xl"
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{t('TestScheduledTests.dialogTitle')}</DialogTitle>

        <DialogContent>
          {scheduledTests.map((scheduledTest) => (
            <div
              data-cy="scheduled-item"
              key={scheduledTest.id}
              className="bg-background-default mb-2 px-6 py-4 rounded flex items-center"
            >
              <div className="flex-1">
                <p className="flex-1">
                  {scheduledTest.limitDate ? (
                    <Trans
                      i18nKey="TestScheduledTests.test"
                      components={{
                        bold: <strong />,
                      }}
                      values={{
                        date: DateTime.fromMillis(
                          Number(scheduledTest.limitDate),
                        ).toLocaleString(DateTime.DATE_HUGE),
                        user: scheduledTest.user?.username,
                      }}
                    />
                  ) : (
                    <Trans
                      i18nKey="TestScheduledTests.noLimitDate"
                      components={{
                        bold: <strong />,
                      }}
                      values={{
                        user: scheduledTest.user?.username,
                      }}
                    />
                  )}
                </p>
                <p className="caption italic">
                  {t('Global.byAuthorAt', {
                    author: scheduledTest.createdBy?.username,
                    datetime: DateTime.fromMillis(
                      Number(scheduledTest.createdAt),
                    ).toLocaleString({
                      ...DateTime.DATETIME_FULL,
                      timeZoneName: undefined,
                    }),
                  })}
                </p>
              </div>

              {scheduledTest.scheduledTestIssueResult ||
              scheduledTest.scheduledTestPassedResult ? (
                <ScheduledTestResult scheduledTest={scheduledTest} />
              ) : (
                <>
                  <div className="mr-4">
                    <UpdateScheduledTest
                      scheduledTestId={scheduledTest.id}
                      initialLimitDate={scheduledTest.limitDate}
                    />
                  </div>

                  <div>
                    <DeleteScheduledTest
                      scheduledTestId={scheduledTest.id}
                      testId={testId}
                      onDeleted={onClose}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
        </DialogContent>

        <DialogActions onClose={onClose} />
      </Dialog>
    </>
  );
}
