import clsx from 'clsx';

export default function DialogContent({ className = null, children, ...rest }) {
  return (
    <div
      {...rest}
      className={clsx('flex-1 overflow-auto pt-6 pb-6 px-12', className)}
    >
      {children}
    </div>
  );
}
