import MainLayout from '../components/Layout/MainLayout';
import SettingsForm from '../components/Settings/SettingsForm';

export default function SettingsPage() {
  return (
    <MainLayout>
      <div className="my-8 w-full max-w-7xl mx-auto">
        <SettingsForm className="mx-8" />
      </div>
    </MainLayout>
  );
}
