import MainLayout from '../components/Layout/MainLayout';
import ProfileForm from '../components/Profile/ProfileForm';

export default function ProfilePage() {
  return (
    <MainLayout>
      <div className="my-8 w-full max-w-7xl mx-auto">
        <ProfileForm />
      </div>
    </MainLayout>
  );
}
