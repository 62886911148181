import Dialog from '../System/Dialog';
import DialogTitle from '../System/DialogTitle';
import DialogContent from '../System/DialogContent';
import DialogActions from '../System/DialogActions';
import { useTranslation } from 'react-i18next';

export default function ConfirmDialog({
  open,
  children,
  loading,
  onDelete,
  onConfirm,
  onClose,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle>{t('ConfirmDialog.title')}</DialogTitle>
      <DialogContent>
        {children || t('ConfirmDialog.defaultText')}
      </DialogContent>
      <DialogActions
        loading={loading}
        onConfirm={onConfirm}
        onDelete={onDelete}
        onCancel={onClose}
      />
    </Dialog>
  );
}
