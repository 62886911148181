import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import GoogleIcon from './GoogleIcon';
import Error from '../System/Error';
import useGoogleSignInButton from './useGoogleSignInButton';

export default function GoogleSignInButton({ join }) {
  const { t } = useTranslation();

  const { loading, error, buttonRef } = useGoogleSignInButton({
    joinWorkspaceId: join,
  });

  return (
    <div>
      <div>
        <Button
          loading={loading}
          ref={buttonRef}
          variant="outlined"
          startIcon={<GoogleIcon />}
          fullWidth
        >
          {t('SignInPage.googleSignIn')}
        </Button>
      </div>

      {error ? (
        <Error className="mt-2 text-center">
          Une erreur est survenue avec l'authentification Google
        </Error>
      ) : null}
    </div>
  );
}
