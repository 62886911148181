import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from './Button';

export default function DialogActions({
  className = null,
  classes = {},
  children = null,
  loading = false,
  cancelText = null,
  confirmText = null,
  confirmDisabled = false,
  disabled = false,
  onCancel = null,
  onConfirm = null,
  onClose = null,
  onDelete = null,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <div
      className={clsx('py-4 px-12 flex items-center justify-end', className)}
      {...rest}
    >
      {onCancel ? (
        <div>
          <Button variant="outlined" onClick={onCancel}>
            {t('DialogActions.cancel')}
          </Button>
        </div>
      ) : null}

      {onClose ? (
        <div className={clsx(onCancel && 'ml-2')}>
          <Button loading={loading} variant="outlined" onClick={onClose}>
            {t('DialogActions.close')}
          </Button>
        </div>
      ) : null}

      {onConfirm ? (
        <div className={clsx(onClose || (onCancel && 'ml-2'))}>
          <Button
            loading={loading}
            variant="contained"
            color="primary"
            disabled={disabled || confirmDisabled}
            onClick={onConfirm}
          >
            {confirmText || t('DialogActions.confirm')}
          </Button>
        </div>
      ) : null}

      {onDelete ? (
        <div
          className={clsx(
            (onConfirm ||
              (onClose && !onConfirm) ||
              (onCancel && !onClose && !onConfirm)) &&
              'ml-2',
          )}
        >
          <Button
            loading={loading}
            variant="contained"
            disabled={disabled}
            color={classes.delete ? null : 'primary'}
            className={classes.delete}
            onClick={onDelete}
          >
            {t('DialogActions.delete')}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
