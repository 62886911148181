import CircularProgress from '../System/CircularProgress';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';
import useAuth from './useAuth';

export default function AuthProvider() {
  // Authentication checks
  const { loading, currentUser } = useAuth();

  if (loading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <CircularProgress size={22} />
      </div>
    );
  }

  if (currentUser?.idToken) {
    return <AppRouter />;
  }

  return <AuthRouter />;
}
