import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function Or({ className, ...rest }) {
  const { t } = useTranslation();

  return (
    <div className={clsx('flex items-center', className)} {...rest}>
      <hr className="flex-1" />
      <p className="mx-4 font-bold">{t('Or.text')}</p>
      <hr className="flex-1" />
    </div>
  );
}
