import { gql, useMutation } from '@apollo/client';
import clsx from 'clsx';
import { Form, Formik, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import React, { useEffect, useRef } from 'react';
import useSnackbar from '../System/useSnackbar';

const UPDATE_FOLDER = gql`
  mutation UpdateFolder($folderId: String!, $name: String!, $parentId: String) {
    updateFolder(folderId: $folderId, name: $name, parentId: $parentId) {
      id
      name
      parentId
    }
  }
`;

const Input = React.forwardRef(
  ({ initialValue, editMode, onBlur, disabled, submitForm, ...rest }, ref) => {
    const [
      { onBlur: fieldOnBlur, ...field },
      meta,
      { setValue, setError },
    ] = useField({
      name: 'name',
    });

    useEffect(() => {
      if (editMode) {
        ref.current.focus();
      }
    }, [editMode, ref]);

    return (
      <div className="flex flex-col flex-1 relative flex-shrink-0">
        <input
          ref={ref}
          type="text"
          className={clsx(
            'flex-1 ml-0 py-1 bg-transparent border-0 focus:ring-1 rounded-sm w-full',
            editMode ? 'cursor-auto' : 'cursor-pointer',
          )}
          style={{ fontWeight: 'inherit' }}
          disabled={disabled || !editMode}
          onBlur={(e) => {
            if (field.value === '') {
              setValue(initialValue);
            } else if (initialValue !== field.value) {
              submitForm();
            }
            setError();
            onBlur && onBlur(e);
            fieldOnBlur(e);
          }}
          {...field}
          {...rest}
        />
        {meta.touched && meta.error ? (
          <p className="absolute top-8 left-0 right-0 rounded-br rounded-bl border border-red-900 bg-red-100 text-red-900 px-4 py-1 z-20">
            {meta.error}
          </p>
        ) : null}
      </div>
    );
  },
);

const RenameFolder = ({ editMode, folder, onBlur, ...rest }) => {
  const { t } = useTranslation();

  const inputRef = useRef();

  const { showSnackbar } = useSnackbar();

  const [updateFolder] = useMutation(UPDATE_FOLDER, {
    onCompleted() {
      showSnackbar(t('RenameFolder.success'), 'success');
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  return (
    <Formik
      initialValues={{
        name: folder.name,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(() => {
          return t('RenameFolder.requiredField');
        }),
      })}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values) => {
        inputRef.current.blur();
        await updateFolder({
          variables: {
            folderId: folder.id,
            name: values.name,
            parentId: folder.parentId,
          },
        });
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form noValidate className="flex flex-1 mr-2 py-2 flex-shrink-0">
          <Input
            initialValue={folder.name}
            ref={inputRef}
            editMode={editMode}
            onBlur={() => {
              onBlur && onBlur();
            }}
            submitForm={submitForm}
            data-cy="folder-name-input"
            {...rest}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RenameFolder;
