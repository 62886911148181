import { useState } from 'react';

export default function useStatusFilters() {
  const [checkedStatus, setCheckedStatus] = useState(['OPEN']);

  const handleStatusChange = (event) => {
    const { name, checked } = event.currentTarget;
    if (checked) {
      setCheckedStatus((prev) => [...prev, name]);
    } else {
      setCheckedStatus((prev) => prev.filter((status) => status !== name));
    }
  };

  return { checkedStatus, onStatusChange: handleStatusChange };
}
