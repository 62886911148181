import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../System/Button';
import TextField from '../System/TextField';
import Error from '../System/Error';
import { gql, useMutation } from '@apollo/client';
import useRefresh from '../Auth/useRefresh';
import useSetCurrentWorkspace from './useSetCurrentWorkspace';
import { useHistory } from 'react-router-dom';
import WorkspacePricing from './WorkspacePricing';
import useOpen from '../System/useOpen';
import useCurrentUser from '../CurrentUser/useCurrentUser';

const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($name: String!) {
    createWorkspace(name: $name) {
      id
    }
  }
`;

export default function CreateWorkspaceForm() {
  const { replace } = useHistory();

  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();

  const { currentUser } = useCurrentUser();

  const { setCurrentWorkspace } = useSetCurrentWorkspace();
  const { onRefresh } = useRefresh();
  const [createWorkspace, { loading, error }] = useMutation(CREATE_WORKSPACE, {
    async onCompleted({ createWorkspace }) {
      await setCurrentWorkspace({
        variables: { workspaceId: createWorkspace.id },
      });

      await onRefresh();

      replace('/tests');
    },
  });

  const handleSubmit = async ({ name }) => {
    await createWorkspace({ variables: { name } });
  };

  const handleCreate = async ({ name }) => {
    if (currentUser.plan === 'TEAM') {
      onOpen();
    } else {
      await handleSubmit({ name });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required(t('Global.requiredField')),
        })}
        onSubmit={handleCreate}
      >
        {({ isSubmitting, values }) => (
          <Form noValidate>
            <TextField
              label={t('CreateWorkspaceForm.nameLabel')}
              name="name"
              type="text"
            />

            {error ? <Error>{t('Global.serverError')}</Error> : null}

            <div>
              <Button
                className="mt-2"
                loading={isSubmitting}
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
              >
                {t('CreateWorkspaceForm.submitButton')}
              </Button>
            </div>

            <WorkspacePricing
              open={open}
              onSubmit={() => handleSubmit(values)}
              loading={loading}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}
