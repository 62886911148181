import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../System/TextField';
import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import clsx from 'clsx';
import { gql, useMutation } from '@apollo/client';
import useSnackbar from '../System/useSnackbar';
import InviteFragment from '../../fragments/InviteFragment';
import { GET_INVITES } from '../Workspace/WorkspaceUsers';
import useCurrentLanguage from '../Language/useCurrentLanguage';
import useCurrentUser from '../CurrentUser/useCurrentUser';

const INVITE_USER = gql`
  mutation InviteUserToWorkspace($email: String!, $lang: LANG) {
    inviteUserToWorkspace(email: $email, lang: $lang) {
      ...InviteFragment
    }
  }

  ${InviteFragment}
`;

export default function InviteUserForm({ className, users }) {
  const { t } = useTranslation();

  const currentLanguage = useCurrentLanguage();
  const { currentUser } = useCurrentUser();

  const { showSnackbar } = useSnackbar();

  const [inviteUser, { loading }] = useMutation(INVITE_USER, {
    update(cache, { data: { inviteUserToWorkspace } }) {
      const { getWorkspaceInvites } = cache.readQuery({ query: GET_INVITES });
      cache.writeQuery({
        query: GET_INVITES,
        data: {
          getWorkspaceInvites: [...getWorkspaceInvites, inviteUserToWorkspace],
        },
      });
    },
    onCompleted() {
      showSnackbar(t('InviteUserForm.success'), 'success');
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  const handleSubmit = async ({ email }, { resetForm, setFieldError }) => {
    if (currentUser.plan === 'TRIAL') {
      if (users.length >= 2) {
        return setFieldError(
          'email',
          t('InviteUserForm.maxMemberLimitTrial', { count: 2 }),
        );
      }
    }

    if (currentUser.plan === 'SOLO') {
      if (users.length >= 2) {
        return setFieldError(
          'email',
          t('InviteUserForm.maxMemberLimit', { count: 2 }),
        );
      }
    }

    if (currentUser.plan === 'TEAM') {
      if (users.length >= 8) {
        return setFieldError(
          'email',
          t('InviteUserForm.maxMemberLimit', { count: 8 }),
        );
      }
    }

    await inviteUser({
      variables: { email, lang: currentLanguage.toUpperCase() },
    });
    resetForm();
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t('InviteUserForm.invalidEmail'))
          .required(t('Global.requiredField')),
      })}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form
            data-cy="invite-user-form"
            className={clsx('max-w-md', className)}
          >
            <p className="opacity-90 mb-2">
              <b>{t('InviteUserForm.title')}</b>
            </p>

            <TextField
              disabled={isSubmitting}
              label={t('InviteUserForm.emailLabel')}
              name="email"
              type="email"
            />
            <div>
              <Button
                loading={loading}
                type="submit"
                disabled={!values.email}
                variant="contained"
                color="primary"
              >
                {t('InviteUserForm.submit')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
