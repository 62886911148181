import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function TestStatusSelection({
  className,
  checkedStatus = [],
  onChange,
}) {
  const { t } = useTranslation();

  return (
    <div className={clsx('flex', className)}>
      <label className="flex items-center text-sm mr-4 border border-secondary-main border-opacity-50 px-2 py-1 rounded bg-secondary-main bg-opacity-10">
        <input
          type="checkbox"
          name="FAILED"
          className="cursor-pointer rounded-sm w-4 h-4 border border-gray-300 mr-2 text-secondary-main"
          checked={checkedStatus.indexOf('FAILED') > -1}
          onChange={onChange}
        />
        {t('TestStatusSelection.failed')}
      </label>

      <label className="flex items-center text-sm mr-4 px-2 py-1 border border-green-500 border-opacity-30 bg-green-500 bg-opacity-10 rounded">
        <input
          type="checkbox"
          name="PASSED"
          className="cursor-pointer rounded-sm w-4 h-4 border border-gray-300 mr-2 text-green-500"
          checked={checkedStatus.indexOf('PASSED') > -1}
          onChange={onChange}
        />
        {t('TestStatusSelection.passed')}
      </label>

      <label className="flex items-center text-sm border border-gray-400 border-opacity-50 px-2 py-1 rounded bg-gray-400 bg-opacity-10">
        <input
          type="checkbox"
          name="NOT_TESTED"
          className="cursor-pointer rounded-sm w-4 h-4 border border-gray-300 mr-2 text-gray-400"
          checked={checkedStatus.indexOf('NOT_TESTED') > -1}
          onChange={onChange}
        />
        {t('TestStatusSelection.notTested')}
      </label>
    </div>
  );
}
