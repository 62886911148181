import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import extractHtmlContent from '../System/libs/extract-html-content';
import IssueStatusChip from './IssueStatusChip';

export default function IssuesList({ className, issues }) {
  const { t } = useTranslation();
  const { issueId } = useParams();

  return (
    <div className={clsx('flex-1 flex', className)}>
      <div className="flex-1">
        <ul className="grid grid-cols-1 gap-4" data-cy="issues-list">
          {issues.map((issue) => (
            <li
              key={issue.id}
              className="bg-background-paper flex rounded overflow-hidden border border-divider"
            >
              <Link
                to={`/issues/${issue.id}`}
                className={clsx('flex-1 no-underline group max-w-full')}
              >
                <p
                  className={clsx(
                    'text-base',
                    'bg-opacity-30 group-hover:bg-opacity-100',
                    'transition-all duration-200 text-sm px-4 py-2 overflow-ellipsis overflow-hidden whitespace-nowrap',
                    'border-b border-divider',
                    issueId === issue.id &&
                      'bg-opacity-100 bg-primary-main text-primary-contrast-text',
                  )}
                >
                  <span className="font-bold">#{issue.number}</span>{' '}
                  {issue.test.title}
                </p>

                <div
                  className={clsx(
                    'flex items-center',
                    'px-4 py-2 transition-all duration-200',
                  )}
                >
                  <div className="flex-1 mr-4">
                    <p
                      className={clsx(
                        'overflow-ellipsis overflow-hidden whitespace-nowrap text-sm',
                        !issue.title && 'empty-state',
                      )}
                    >
                      {(issue.title && extractHtmlContent(issue.title)) ||
                        t('IssuesList.emptyDescription')}
                    </p>

                    <p className="caption">
                      {t('Global.createdAt', {
                        datetime: DateTime.fromMillis(
                          Number(issue.createdAt),
                        ).toLocaleString(DateTime.DATETIME_MED),
                        author: issue.user.username,
                      })}
                    </p>
                  </div>

                  <div>
                    <IssueStatusChip status={issue.status} />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>

        {/* Spacing */}
        <div className="h-20" />
      </div>
    </div>
  );
}
