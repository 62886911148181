import {
  ApolloClient,
  HttpLink,
  makeVar,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { REFRESH_TOKEN } from './components/Auth/useRefresh';
import { setCurrentUserFromToken } from './components/CurrentUser/useCurrentUser';

export const currentUserVar = makeVar();

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        currentUser: {
          read() {
            return currentUserVar();
          },
        },
      },
    },
  },
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include', // TODO: mandatory to set cookie ?
});

let client;

const refreshToken = async () => {
  const response = await client.mutate({ mutation: REFRESH_TOKEN });

  const { idToken } = response.data.refreshToken;

  setCurrentUserFromToken({ idToken });

  return idToken;
};

// We check token expire time and refresh it if it is less than 5 minutes.
// To prevent concurent refreshes, we await requests if a refresh is
// already in progress.

let isRefreshing = false;
let pendingRequests = [];

const authLink = setContext(async ({ operationName }) => {
  let token;

  const refreshTokenExp = localStorage.getItem('kt_r');

  // Refresh token if it will expire in less than 5 minute
  if (
    refreshTokenExp != null &&
    Number(refreshTokenExp) < new Date().valueOf() + 5 * 60 * 1000
  ) {
    if (operationName !== 'RefreshToken') {
      try {
        if (isRefreshing) {
          // Wait for refreshing to finish
          await new Promise((resolve) => {
            pendingRequests.push(() => resolve());
          });
        } else {
          isRefreshing = true;
          token = await refreshToken();
          isRefreshing = false;
          for (const pendingRequest of pendingRequests) {
            pendingRequest();
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  if (!token) {
    token = currentUserVar()?.idToken;
  }

  return { token };
});

const testLink = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();
  operation.setContext(() => ({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));
  return forward(operation);
});

client = new ApolloClient({
  link: ApolloLink.from([authLink, testLink.concat(httpLink)]),
  cache,
});

export default client;
