import Timeline from '../../Timeline/Timeline';
import useTestTimeline from './useTestTimeline';
import Dialog from '../../System/Dialog';
import DialogTitle from '../../System/DialogTitle';
import DialogContent from '../../System/DialogContent';
import DialogActions from '../../System/DialogActions';
import useURLOpen from '../../System/useURLOpen';
import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import TestActionDiffs from './TestActionDiffs';

export default function TestTimeline({ className, test }) {
  const { t } = useTranslation();

  const { search } = useLocation();
  const { testId } = useParams();

  const { onOpen, open, onClose } = useURLOpen({
    name: 'taid', // Test Action ID
    value: testId,
  });

  const handleDiffOpen = useCallback(
    (actionId) => {
      onOpen(actionId);
    },
    [onOpen],
  );

  const { timelineItems } = useTestTimeline({
    test,
    onDiffOpen: handleDiffOpen,
  });

  // Action Diffs: get the current action and the one before so we can compare them
  const actionDiffs = useMemo(() => {
    if (test) {
      const searchParams = new URLSearchParams(search);
      const actionId = searchParams.get('taid');

      if (!actionId) {
        return null;
      }

      const actionIndex = test.actions.findIndex(
        (action) => action.id === actionId,
      );

      if (actionIndex < 1) {
        return null;
      }

      return test.actions.slice(actionIndex - 1, actionIndex + 1);
    }
  }, [search, test]);

  return (
    <>
      <Timeline
        className={className}
        items={timelineItems}
        bgColor="bg-white"
      />

      <Dialog
        data-cy="test-diff-dialog"
        maxWidth="7xl"
        open={open}
        onClose={onClose}
      >
        <DialogTitle
          subtitle={
            actionDiffs?.length === 2
              ? t('Global.byAuthorAt', {
                  datetime: DateTime.fromMillis(
                    Number(actionDiffs[1].createdAt),
                  ).toLocaleString({
                    ...DateTime.DATETIME_FULL,
                    timeZoneName: undefined,
                  }),
                  author: actionDiffs[1].user.username,
                })
              : null
          }
        >
          {t('Test.actionDiffsTitle')}
        </DialogTitle>
        <DialogContent>
          <TestActionDiffs actionDiffs={actionDiffs} />
        </DialogContent>
        <DialogActions onClose={onClose} />
      </Dialog>
    </>
  );
}
