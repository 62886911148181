import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const STRIPE_CREATE_CHECKOUT_SESSION = gql`
  mutation GetStripeCustomerPortal {
    customerPortal: getStripeCustomerPortal
  }
`;

export default function usePortal() {
  const [getCustomerPortal] = useMutation(STRIPE_CREATE_CHECKOUT_SESSION);

  const goToPortal = useCallback(async () => {
    const { data } = await getCustomerPortal();
    window.location.replace(data.customerPortal);
  }, [getCustomerPortal]);

  return { goToPortal };
}
