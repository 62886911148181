import { gql } from '@apollo/client';

const ScheduledTestFragment = gql`
  fragment ScheduledTestFragment on ScheduledTest {
    id
    createdAt
    limitDate

    test {
      id
      title
      folderId
    }
    createdBy {
      id
      username
    }
    user {
      id
      username
    }
    scheduledTestPassedResult {
      id
    }
    scheduledTestIssueResult {
      id
    }
  }
`;

export default ScheduledTestFragment;
