import { gql, useQuery } from '@apollo/client';
import MainLayout from '../components/Layout/MainLayout';
import IssuesList from '../components/Issue/IssuesList';
import Issue from '../components/Issue/Issue';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import IssueStatusSelection from '../components/Issue/StatusFilter/IssueStatusSelection';
import useStatusFilters from '../components/Issue/StatusFilter/useStatusFilters';
import { useMemo } from 'react';
import emptyStateIllustration from '../assets/done-empty-state.svg';

const GET_ISSUES = gql`
  query GetIssues {
    getIssues {
      id
      number
      createdAt
      title
      status
      test {
        id
        title
      }
      step {
        id
        title
      }
      user {
        id
        username
      }
    }
  }
`;

export default function IssuesPage() {
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(GET_ISSUES);

  const { issueId } = useParams();

  const { checkedStatus, onStatusChange } = useStatusFilters();

  const issues = useMemo(() => {
    return data?.getIssues.filter(
      (issue) => checkedStatus.indexOf(issue.status) > -1,
    );
  }, [data, checkedStatus]);

  if (error) {
    return <p>{t('Global.serverError')}</p>;
  }

  if (!loading && issues?.length && !issueId) {
    return <Redirect to={`/issues/${issues[0].id}`} />;
  }

  return (
    <MainLayout>
      {loading ? null : (
        <div className="flex-1 flex overflow-auto">
          <div className="flex-1 flex flex-col overflow-auto">
            <header className="bg-background-paper border-b border-divider px-8 py-4 flex items-center">
              <h2 className="mr-4">{t('IssuesPage.title')}</h2>

              <IssueStatusSelection
                checkedStatus={checkedStatus}
                onChange={onStatusChange}
              />
            </header>

            {!issues?.length &&
            checkedStatus.length === 1 &&
            checkedStatus[0] === 'OPEN' ? (
              <div className="px-8 py-4 mx-auto mt-8">
                <div>
                  <img src={emptyStateIllustration} alt="empty open issues" />
                </div>

                <p className="text-lg text-primary-main text-opacity-80">
                  {t('IssuesPage.emptyOpenIssues')}
                </p>
              </div>
            ) : null}
            <IssuesList className="px-8 py-4 overflow-auto" issues={issues} />
          </div>

          {issueId ? (
            <div
              className={clsx(
                'w-full bg-background-paper border-l border-divider flex flex-col overflow-auto',
                'md:max-w-lg lg:max-w-3xl',
              )}
              data-cy="issue-container"
            >
              <Issue />
            </div>
          ) : null}
        </div>
      )}
    </MainLayout>
  );
}
