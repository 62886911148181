import { useEffect, useState } from 'react';
import useCurrentUser from '../CurrentUser/useCurrentUser';
import useWorkspaces from '../Workspace/useWorkspaces';
import useRefresh from './useRefresh';
import useSetCurrentWorkspace from '../Workspace/useSetCurrentWorkspace';

export default function useAuth() {
  const { onRefresh } = useRefresh();

  const { currentUser, setCurrentUserFromToken } = useCurrentUser();
  const [loading, setLoading] = useState(true);

  // Refresh token on app load
  useEffect(() => {
    const handleRefresh = async () => {
      const hasRefreshToken = !!localStorage.getItem('kt_r');

      if (hasRefreshToken) {
        try {
          await onRefresh();
        } catch (err) {
          setCurrentUserFromToken({ idToken: null });
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    handleRefresh();
  }, [onRefresh, setCurrentUserFromToken]);

  // Set current workspace if not
  const { workspaces } = useWorkspaces({ skip: !currentUser?.idToken });

  const { setCurrentWorkspace } = useSetCurrentWorkspace();
  // Set current workspace if not set
  useEffect(() => {
    const handleCurrentWorkspace = async () => {
      if (currentUser?.idToken) {
        if (!currentUser.currentWorkspaceId) {
          if (workspaces?.length) {
            // Set current_workspace
            try {
              await setCurrentWorkspace({
                variables: { workspaceId: workspaces[0].id },
              });

              await onRefresh();
            } catch (err) {}
          }
        }
      }
    };

    handleCurrentWorkspace();
  }, [currentUser, workspaces, onRefresh, setCurrentWorkspace]);

  return { loading, currentUser };
}
