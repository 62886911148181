import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export const GET_FOLDERS = gql`
  query GetFolders {
    getFolders {
      id
      name
      parentId
    }
  }
`;

const denormalize = (items, id = null, link = 'parentId') =>
  items
    .filter((item) => item[link] === id)
    .map((item) => ({ ...item, children: denormalize(items, item.id) }));

export default function useFolders() {
  const { data, loading } = useQuery(GET_FOLDERS);

  const folders = useMemo(() => {
    if (!data?.getFolders) {
      return [];
    }

    return denormalize(
      [...data.getFolders].sort((a, b) => (a.name < b.name ? -1 : 1)),
    );
  }, [data]);

  return { loading, folders, rawFolders: data?.getFolders };
}
