import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import DeleteForever from '../Icons/DeleteForever';
import Dialog from '../System/Dialog';
import DialogActions from '../System/DialogActions';
import DialogContent from '../System/DialogContent';
import DialogTitle from '../System/DialogTitle';
import MenuItem from '../System/MenuItem';
import useOpen from '../System/useOpen';
import useSnackbar from '../System/useSnackbar';

const DELETE_USER = gql`
  mutation DeleteUserFromWorkspace($userId: String!) {
    deleteUserFromWorkspace(userId: $userId)
  }
`;

export default function WorkspaceRemoveUser({
  workspaceId,
  userId,
  username,
  userRoles = [],
  onExited,
}) {
  const { t } = useTranslation();

  const { open, onOpen, onClose: onCloseDialog } = useOpen();

  const handleClose = () => {
    onCloseDialog();
    if (onExited) {
      onExited();
    }
  };

  const { showSnackbar } = useSnackbar();
  const [deleteUser, { loading }] = useMutation(DELETE_USER, {
    variables: { userId },
    update(cache) {
      // Remove user from workspace users list on success
      if (userRoles.length === 1 && userRoles[0] === 'JOINING') {
        cache.modify({
          fields: {
            getWorkspaceInvites(existingInvites = []) {
              const newUsers = existingInvites.filter(
                (u) =>
                  u.__ref !==
                  cache.identify({ id: userId, __typename: 'Invite' }),
              );
              return newUsers;
            },
          },
        });
      } else {
        cache.modify({
          id: cache.identify({ id: workspaceId, __typename: 'Workspace' }),
          fields: {
            users(existingUsers = []) {
              const newUsers = existingUsers.filter(
                (u) =>
                  u.user.__ref !==
                  cache.identify({ id: userId, __typename: 'User' }),
              );
              return newUsers;
            },
          },
        });
      }
    },
    onCompleted({ deleteUserFromWorkspace: result }) {
      if (result) {
        showSnackbar(t('WorkspaceRemoveUser.success'), 'success');
        handleClose();
      } else {
        showSnackbar(t('Global.serverError'), 'error');
      }
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  return (
    <>
      <MenuItem onClick={onOpen}>
        <div className="flex items-center">
          <DeleteForever className="w-5 h-5 mr-2" />{' '}
          {t('WorkspaceRemoveUser.remove')}
        </div>
      </MenuItem>

      <Dialog data-cy="remove-user-dialog" open={open} onClose={handleClose}>
        <DialogTitle>{t('WorkspaceRemoveUser.confirmTitle')}</DialogTitle>
        <DialogContent>
          {t('WorkspaceRemoveUser.confirmText', { username })}
        </DialogContent>
        <DialogActions
          loading={loading}
          onCancel={handleClose}
          onDelete={deleteUser}
        />
      </Dialog>
    </>
  );
}
