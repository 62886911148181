import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import CreateIssueButton from '../../Issue/CreateIssueButton';
import CreateTestPassedButton from '../../Issue/CreateTestPassedButton';

export default function StepList({
  className,
  test,
  selected,
  readOnly,
  dense,
}) {
  const { t } = useTranslation();

  return (
    <section className={clsx(className)}>
      <header
        className={clsx(
          'bg-primary-light px-8 text-primary-main flex items-center',
          dense ? 'py-2' : 'py-4',
        )}
      >
        <h3 className="flex-1">{t('StepList.title')}</h3>

        {!readOnly ? (
          <>
            <div>
              <CreateTestPassedButton
                test={test}
                className="ml-2 px-4 py-1"
                noPadding
              />
            </div>

            <div>
              <CreateIssueButton
                test={test}
                className="ml-2 px-4 py-1"
                noPadding
              />
            </div>
          </>
        ) : null}
      </header>

      {test.steps?.length ? (
        <ul>
          {test.steps.map((step) => (
            <li
              key={step.id}
              className={clsx(
                'border-b border-divider px-8 flex items-center group',
                dense ? 'py-2' : 'py-4',
                selected &&
                  selected === step.id &&
                  'bg-primary-main text-primary-contrast-text',
              )}
            >
              <div
                className={clsx(
                  'rounded-full mr-4',
                  dense ? 'w-1.5 h-1.5' : 'w-2 h-2',
                  selected && selected === step.id
                    ? 'bg-primary-contrast-text'
                    : 'bg-primary-main',
                )}
              />
              <p className="flex-1">{step.title}</p>
              {!readOnly ? (
                <div>
                  <CreateIssueButton
                    test={test}
                    stepId={step.id}
                    noPadding
                    className="ml-2 px-4 opacity-0 group-hover:opacity-100"
                  />
                </div>
              ) : null}
            </li>
          ))}
        </ul>
      ) : (
        <p className="px-8 py-6 mb-4 empty-state">{t('StepList.emptyState')}</p>
      )}
    </section>
  );
}
