import React from 'react';
import clsx from 'clsx';
import CircularProgress from './CircularProgress';
import { useMemo } from 'react';

export default React.forwardRef(function Button(
  {
    className,
    classes = {},
    size = 'normal',
    noShadow = false,
    noPadding = false,
    noBg = false,
    squared = false,
    children,
    component = 'button',
    variant,
    fullWidth = false,
    loading = false,
    color = 'default',
    startIcon,
    endIcon,
    disabled: disabledProp = false,
    noTransition = false,
    wrap = false,
    ...rest
  },
  ref,
) {
  const C = useMemo(
    () =>
      component === 'a'
        ? ({ children, ...rest }) => (
            <a ref={ref} {...rest}>
              {children}
            </a>
          )
        : component === 'span'
        ? (props) => <span ref={ref} {...props} />
        : typeof component === 'object'
        ? (props) => {
            const Component = component;
            return <Component {...props} />;
          }
        : (props) => <button ref={ref} type="button" {...props} />,
    [component, ref],
  );

  const disabled = disabledProp || loading;

  return (
    <C
      className={clsx(
        'inline-flex',
        'items-center',
        'justify-center',
        'cursor-pointer',
        !squared && 'rounded-sm',
        'focus:outline-none',
        !noTransition && 'transition-all',
        'disabled:cursor-auto',
        !noShadow && 'hover:shadow-md',
        !noShadow && 'focus:shadow-md',
        !noBg && 'disabled:bg-background-default',
        !noBg && 'disabled:text-primary-main',
        'disabled:text-opacity-40',
        !noBg && 'disabled:shadow-none',
        fullWidth && 'w-full',
        noPadding && 'p-0',
        !noPadding && size === 'small' && 'px-2 py-1',
        !noPadding && size === 'normal' && 'px-8 py-2',
        !noPadding && size === 'large' && 'px-10 py-3',
        !noBg &&
          variant === 'contained' &&
          color === 'default' &&
          'text-primary-main bg-background-default',
        !noBg &&
          variant === 'contained' &&
          color === 'primary' &&
          'text-primary-contrast-text bg-primary-main',
        !noBg && variant === 'outlined' && color === 'default' && 'border',
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {startIcon ? (
        <span
          className={clsx(
            'fill-current flex items-center flex-shrink-0',
            classes.startIcon || (size === 'small' ? 'mr-2 w-5' : 'mr-4 w-6'),
          )}
        >
          {startIcon}
        </span>
      ) : null}
      <span
        className={clsx(
          'flex items-center',
          !wrap && 'whitespace-nowrap',
          classes.label,
        )}
      >
        {children}
      </span>
      {endIcon ? (
        <span
          className={clsx(
            'fill-current flex items-center flex-shrink-0',
            size === 'small' ? 'ml-2 w-5' : 'ml-4 w-6',
          )}
        >
          {endIcon}
        </span>
      ) : null}
      {loading ? (
        <span className="flex items-center">
          <CircularProgress
            className={clsx(
              'ml-4',
              color === 'default' && 'text-primary-main',
              color === 'primary' && !disabled && 'text-primary-contrast-text',
              color === 'primary' && disabled && 'text-primary-main',
              disabled && 'opacity-20',
            )}
            size={20}
          />
        </span>
      ) : null}
    </C>
  );
});
