import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ArrowPreviousIcon from '../Icons/ArrowPrevious';
import TestsFolderIcon from '../Icons/TestsFolder';
import Button from '../System/Button';
import Drawer from '../System/Drawer';
import useOpen from '../System/useOpen';
import TestStatusSelection from './StatusFilter/TestStatusSelection';
import useStatusFilters from './StatusFilter/useStatusFilters';
import TestsSection from './Test/TestsSection';
import useFolders from './useFolders';
import useTests from './useTests';
import FoldersSection from './Folder/FoldersSection';
import useTestsManagment from './useTestsManagment';
import MoveTests from './MoveTests';
import Test from './Test/Test';
import { useTranslation } from 'react-i18next';
import TestsListIcon from '../Icons/TestsList';
import CircularProgress from '../System/CircularProgress';
import emptyFolderImg from '../../assets/undraw_folder_39kl.svg';
import CreateFolderForm from './Folder/CreateFolderForm';

export default function TestsLayout() {
  const { t } = useTranslation();

  const { folderId, testId } = useParams();

  const { push } = useHistory();

  const {
    open: folderOpen,
    onOpen: folderOnOpen,
    onClose: folderOnClose,
  } = useOpen();

  const { folders, loading: foldersLoading } = useFolders();
  const folderName = useMemo(() => {
    const name = folders?.find((f) => f.id === folderId)?.name;

    const result = { name };
    if (name) {
      const maxLength = 13;
      result.slicedName =
        name.length > maxLength
          ? `${name.slice(0, maxLength).trim()}...`
          : name;
    }
    return result;
  }, [folders, folderId]);

  const { checkedStatus, onStatusChange } = useStatusFilters({
    // unfilteredTests: rawTests,
  });

  const [showArchived, setShowArchived] = useState(false);

  const { loading, rawTests, tests, onTestOrderChange } = useTests({
    statusFilter: checkedStatus,
    archivedFilter: showArchived,
  });

  const { checkedTests, onCheck, onCheckAll } = useTestsManagment({ tests });

  return (
    <div className="flex-1 flex overflow-auto">
      {/* Folders drawer */}
      <Drawer
        className={clsx(!folderId && 'hidden')}
        open={folderOpen}
        onClose={folderOnClose}
      >
        {<FoldersSection className="flex-1" onFolderSelect={folderOnClose} />}
      </Drawer>

      {foldersLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : !folders.length ? (
        <div className="flex-1 flex flex-col items-center justify-center">
          <figure className="w-96">
            <img src={emptyFolderImg} alt="create folder" />
          </figure>

          <h2 className="mt-12 mb-4">{t('TestsLayout.emptyFolder')}</h2>
          <p className="mb-8 max-w-xl text-center">
            {t('TestsLayout.emptyFolderInfo')}
          </p>

          <CreateFolderForm className="max-w-sm w-full" />
        </div>
      ) : (
        <>
          {/* Folders fixed section */}
          <div
            className={clsx(
              'bg-background-paper border-r border-divider',
              folderId && 'hidden',
              'w-full md:max-w-sm',
              'xl:flex flex-col',
            )}
            data-cy="desktop-folders"
          >
            {<FoldersSection onFolderSelect={folderOnClose} />}
          </div>

          {/* Tests list section */}
          {folderId ? (
            <div
              className={clsx(
                'flex flex-col flex-1 overflow-auto',
                testId && 'hidden md:block',
              )}
            >
              {rawTests?.length ? (
                <>
                  <div className="bg-background-paper border-b border-divider px-4 py-2 flex justify-between items-center">
                    <div className="xl:hidden">
                      {!checkedTests.length ? (
                        <>
                          <div>
                            <Button
                              variant="outlined"
                              className="bg-background-paper text-primary-main font-bold"
                              onClick={folderOnOpen}
                            >
                              <ArrowPreviousIcon className="fill-current mr-2 h-6" />
                              <TestsFolderIcon className="fill-current mr-2 h-6" />
                              {folderName.slicedName}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <MoveTests tests={checkedTests} />
                      )}
                    </div>

                    <h2 className="hidden xl:block">{folderName.name}</h2>
                  </div>
                  <div
                    className={clsx(
                      'bg-background-paper border-b border-divider px-4 py-2',
                      'flex items-center',
                    )}
                    data-cy="test-list-filters"
                  >
                    <TestStatusSelection
                      className="mr-4"
                      checkedStatus={checkedStatus}
                      onChange={onStatusChange}
                    />

                    <div
                      data-cy="archived-filter"
                      className="pl-4 border-l border-divider"
                    >
                      <label className="flex items-center text-sm mr-4 border border-purple-700 border-opacity-50 px-2 py-1 rounded bg-purple-300 bg-opacity-10">
                        <input
                          type="checkbox"
                          className="cursor-pointer rounded-sm w-4 h-4 border border-gray-300 mr-2 text-purple-500"
                          checked={showArchived}
                          onChange={() => setShowArchived((prev) => !prev)}
                        />
                        {t('TestsLayout.archivedFilter')}
                      </label>
                    </div>
                  </div>
                </>
              ) : null}

              <TestsSection
                loading={loading}
                rawTests={rawTests}
                tests={tests}
                checkedTests={checkedTests}
                onCheck={onCheck}
                onCheckAll={onCheckAll}
                onTestOrderChange={onTestOrderChange}
              />
            </div>
          ) : null}

          {/* Detailed test section */}
          {testId ? (
            <div
              className={clsx(
                'w-full bg-background-paper border-l border-divider flex flex-col overflow-auto',
                'md:max-w-lg lg:max-w-2xl',
              )}
            >
              <div className="flex md:hidden border-b border-divider px-4 py-2 justify-between items-center">
                <div>
                  <Button
                    variant="outlined"
                    className="bg-background-paper text-primary-main font-bold"
                    onClick={() => push(`/tests/${folderId}`)}
                  >
                    <ArrowPreviousIcon className="fill-current mr-2 h-6" />
                    <TestsListIcon className="fill-current mr-2 h-6" />
                    {t('TestsLayout.backToTestsList')}
                  </Button>
                </div>
              </div>

              <Test className="overflow-auto" />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
