import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import StepItem from './StepItem';
import { useTranslation } from 'react-i18next';

export default function StepItemDraggable({
  invisible,
  step,
  steps,
  onAdd,
  onDelete,
  onInputChange,
}) {
  const { t } = useTranslation();

  const {
    attributes,
    transition,
    listeners,
    setNodeRef,
    transform,
  } = useSortable({
    id: step.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="outline-none">
      <StepItem
        invisible={invisible}
        step={step}
        steps={steps}
        sortableListeners={listeners}
        sortableAttributes={attributes}
        undraggable={steps.length < 2}
        placeholder={
          steps.length === 1 ? t('StepItemDraggable.itemPlaceholder') : null
        }
        onAdd={onAdd}
        onDelete={onDelete}
        onInputChange={onInputChange}
      />
    </div>
  );
}
