import clsx from 'clsx';

export default function AutoResizeTextarea({
  className,
  minHeight = 100,
  onChange,
  ...rest
}) {
  return (
    <textarea
      type="text"
      style={{ height: minHeight }}
      className={clsx('resize-none', className)}
      onChange={(event) => {
        event.target.style.height = `${minHeight}px`;
        event.target.style.height = `${Math.max(
          minHeight,
          event.target.scrollHeight + 8,
        )}px`;
        onChange(event);
      }}
      {...rest}
    />
  );
}
