import { useState } from 'react';

export default function useOpen(initialValue = false) {
  const [open, setOpen] = useState(initialValue);

  return {
    open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onToggle: () => setOpen((prevOpen) => !prevOpen),
  };
}
