import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

function Portal({ children }) {
  return createPortal(children, document.getElementById('drawer-root'));
}

export default function Drawer({ children, className, open, onClose }) {
  return (
    <AnimatePresence>
      {open ? (
        <Portal>
          <motion.div
            className={clsx(
              'bg-black bg-opacity-80 fixed top-0 bottom-0 left-0 right-0 z-40',
              className,
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          ></motion.div>

          <div
            className={clsx(
              'fixed top-0 bottom-0 left-0 right-0 z-40',
              className,
            )}
            onClick={onClose}
          >
            <motion.div
              className={clsx(
                'h-full bg-background-paper',
                'w-10/12 sm:w-4/5 md:w-6/12 lg:w-4/12',
                'flex',
              )}
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ bounce: 0, duration: 0.2 }}
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </motion.div>
          </div>
        </Portal>
      ) : null}
    </AnimatePresence>
  );
}
