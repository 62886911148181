import Button from '../System/Button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Dialog from '../System/Dialog';
import useOpen from '../System/useOpen';
import MoveTestsContent from './MoveTestsContent';
import { gql, useMutation } from '@apollo/client';
import useSnackbar from '../System/useSnackbar';
import MoveFolderIcon from '../Icons/MoveFolder';

const MOVE_TESTS = gql`
  mutation MoveTests($testIds: [String!]!, $folderId: String!) {
    updateTestsFolder(testIds: $testIds, folderId: $folderId)
  }
`;

export default function MoveTests({ tests, className }) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();

  const { showSnackbar } = useSnackbar();
  const [moveTests, { loading }] = useMutation(MOVE_TESTS, {
    refetchQueries: ['GetTests'],
    onCompleted() {
      onClose();
      showSnackbar(t('MoveTests.success', { count: tests.length }), 'success');
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  return (
    <>
      <div className={clsx(className)}>
        <Button
          startIcon={<MoveFolderIcon className="h-6" />}
          variant="outlined"
          className="bg-background-paper text-primary-main font-bold"
          onClick={onOpen}
        >
          {t('MoveTests.moveToFolder')}
        </Button>
      </div>

      <Dialog open={open} onClose={onClose}>
        <MoveTestsContent
          confirmLoading={loading}
          testCount={tests.length}
          onCancel={onClose}
          onConfirm={({ folderId }) => {
            return moveTests({
              variables: {
                testIds: tests,
                folderId,
              },
            });
          }}
        />
      </Dialog>
    </>
  );
}
