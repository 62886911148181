import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useURLOpen = ({ name, value }) => {
  const { replace } = useHistory();
  const { pathname, search } = useLocation();

  const computeOpenTo = useCallback(
    (forcedValue) => {
      const searchParams = new URLSearchParams(search);
      searchParams.set(name, forcedValue ?? value ?? 1);
      return `${pathname}?${searchParams.toString()}`;
    },
    [search, name, pathname, value],
  );

  const openTo = useMemo(() => {
    return computeOpenTo;
  }, [computeOpenTo]);

  const closeTo = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete(name);
    return `${pathname}?${searchParams.toString()}`;
  }, [search, name, pathname]);

  const open = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.has(name);
  }, [search, name]);

  const handleOpen = useCallback(
    (forcedValue) => {
      replace(computeOpenTo(forcedValue));
    },
    [replace, computeOpenTo],
  );

  const handleClose = () => {
    replace(closeTo);
  };

  return {
    open,
    openTo,
    closeTo,
    onOpen: handleOpen,
    onClose: handleClose,
  };
};

export default useURLOpen;
