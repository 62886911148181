import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../System/TextField';
import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import clsx from 'clsx';
import { gql, useMutation } from '@apollo/client';
import useSnackbar from '../System/useSnackbar';
import useRefresh from '../Auth/useRefresh';

const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace($name: String!) {
    updateWorkspace(name: $name) {
      id
      name
    }
  }
`;

export default function WorkspaceName({ className, name = '' }) {
  const { t } = useTranslation();

  const { showSnackbar } = useSnackbar();

  const { onRefresh } = useRefresh();
  const [updateWorkspace, { loading }] = useMutation(UPDATE_WORKSPACE, {
    onCompleted() {
      onRefresh();
      showSnackbar(t('WorkspaceName.success'), 'success');
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  const handleSubmit = async ({ name }) => {
    await updateWorkspace({ variables: { name } });
  };

  return (
    <Formik
      initialValues={{
        name,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(t('Global.requiredField')),
      })}
      onSubmit={handleSubmit}
    >
      {({ values }) => {
        return (
          <Form className={clsx('max-w-md', className)}>
            <TextField label={t('WorkspaceName.nameLabel')} name="name" />
            <div>
              <Button
                loading={loading}
                type="submit"
                disabled={name === values.name}
                variant="contained"
                color="primary"
              >
                {t('WorkspaceName.submit')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
