import getLinksFromText from './libs/get-links-from-text';

const DEFAULT_OPTIONS = {
  paste: true,
  type: true,
};

const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

function registerTypeListener(quill, key) {
  quill.keyboard.addBinding({
    collapsed: true,
    key,
    handler: (range, context) => {
      const links = getLinksFromText(context.prefix);

      for (const link of links) {
        const { url } = link;
        const retain = range.index - context.prefix.length + link.index;

        const ops = retain ? [{ retain }] : [];
        ops.push(
          { delete: range.index - retain },
          { insert: url, attributes: { link: url } },
          { insert: context.prefix.slice(link.index + url.length) },
        );

        quill.updateContents({ ops });
      }

      return true;
    },
  });
}

function registerPasteListener(quill) {
  quill.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
    if (typeof node.data !== 'string') {
      return;
    }
    const matches = node.data.match(URL_REGEX);
    if (matches && matches.length > 0) {
      const ops = [];
      let str = node.data;
      matches.forEach((match) => {
        const split = str.split(match);
        const beforeLink = split.shift();
        ops.push({ insert: beforeLink });
        ops.push({ insert: match, attributes: { link: match } });
        str = split.join(match);
      });
      ops.push({ insert: str });
      delta.ops = ops;
    }

    return delta;
  });
}

export default class AutoLinks {
  constructor(quill, options = {}) {
    const opts = { ...DEFAULT_OPTIONS, ...options };

    if (opts.type) {
      registerTypeListener(quill, ' ');
    }
    if (opts.paste) {
      registerPasteListener(quill);
    }
  }
}
