import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

// i18next parser

// t('TestsList.NOT_TESTED')
// t('TestsList.PASSED')
// t('TestsList.FAILED')

export default forwardRef(function TestsListItem(
  {
    invisible,
    test,
    checkedTests,
    sortableListeners,
    sortableAttributes,
    onCheck,
  },
  ref,
) {
  const { folderId, testId } = useParams();

  const { t } = useTranslation();

  return (
    <div
      ref={ref}
      data-cy={`test-item-container-${test.status.toLowerCase()}`}
      className={clsx(
        'flex items-center flex-1 relative',
        'outline-none my-2 rounded border-l-4',
        'transition',
        invisible && 'opacity-0',
        test.status === 'NOT_TESTED' && 'border-primary-light',
        test.status === 'PASSED' && 'border-green-500',
        test.status === 'FAILED' && 'border-secondary-main',
        testId === test.id
          ? ['bg-primary-light']
          : 'bg-background-paper border-opacity-50',
      )}
    >
      <div
        className={
          'ml-4 opacity-50 cursor-move flex items-center touch-action-none outline-none'
        }
        {...sortableAttributes}
        {...sortableListeners}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
      </div>

      <input
        name={test.id}
        type="checkbox"
        className="cursor-pointer rounded-sm w-5 h-5 border border-gray-400 ml-2 mx-4"
        checked={checkedTests.findIndex((id) => id === test.id) >= 0}
        onChange={onCheck}
      />

      <Link
        className="flex-1 no-underline py-2 pr-4 flex items-center"
        to={`/tests/${folderId}/${test.id}`}
      >
        <div className="flex-1">
          <div className="flex">
            {test.archived ? (
              <p className="flex items-center text-sm bg-purple-300 text-purple-800 italic px-2 py-0.5 mr-2 rounded">
                Archivé
              </p>
            ) : null}
            <p
              className={clsx(test.id === testId ? 'font-bold' : 'font-normal')}
            >
              {test.title}
            </p>
          </div>
          <p className="text-sm opacity-75">
            <i>
              {t('TestsList.createdOn')}{' '}
              {DateTime.fromMillis(Number(test.createdAt)).toLocaleString(
                DateTime.DATE_MED,
              )}
            </i>
          </p>
        </div>
      </Link>
    </div>
  );
});
