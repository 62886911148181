import clsx from 'clsx';
import ClickAwayListener from './ClickAwayListener';
import { motion, AnimatePresence } from 'framer-motion';
import { flip } from '@popperjs/core';
import { useRef, useState } from 'react';
import { usePopper } from 'react-popper';

export default function Menu({
  open,
  referenceElement,
  children,
  placement = 'bottom',
  offset = [0, 0],
  classes = {},
  onClose,
  ...rest
}) {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: 'preventOverflow', options: { padding: 16 } },
      flip,
      { name: 'offset', options: { offset } },
    ],
  });

  const backdropRef = useRef();

  return (
    <AnimatePresence>
      {open ? (
        <div
          ref={backdropRef}
          className="fixed top-0 right-0 bottom-0 left-0 z-40"
        >
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            {...rest}
          >
            <ClickAwayListener
              element={backdropRef.current}
              onClickAway={() => {
                onClose();
              }}
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0, transition: { duration: 0.2 } }}
                className={clsx(
                  'shadow-lg bg-background-paper rounded-md relative',
                )}
              >
                <ul className={classes.list}>{children}</ul>
              </motion.div>
            </ClickAwayListener>
          </div>
        </div>
      ) : null}
    </AnimatePresence>
  );
}
