import { Formik } from 'formik';
import { DateTime } from 'luxon';
import Button from '../System/Button';
import { useTranslation } from 'react-i18next';
import Schedule from '../Icons/Schedule';
import Dialog from '../System/Dialog';
import DialogTitle from '../System/DialogTitle';
import DialogContent from '../System/DialogContent';
import DialogActions from '../System/DialogActions';
import useURLOpen from '../System/useURLOpen';
import { useParams } from 'react-router';
import MemberSelect from './MemberSelect';
import SchedulerDate from './SchedulerDate';

import * as Yup from 'yup';
import useCreateScheduledTest from './useCreateScheduledTest';
import TestScheduledTests from './TestScheduledTests';

export default function TestScheduler({ scheduledTests }) {
  const { t } = useTranslation();

  const { testId } = useParams();

  const { open, onOpen, onClose } = useURLOpen({
    name: 'test-scheduler',
    value: testId,
  });

  const { onCreate } = useCreateScheduledTest({ testId });

  return (
    <div className="flex items-center">
      <Button
        variant="outlined"
        size="small"
        startIcon={<Schedule />}
        onClick={() => onOpen()}
      >
        {t('TestScheduler.scheduleButton')}
      </Button>

      <TestScheduledTests className="ml-2" scheduledTests={scheduledTests} />

      <Dialog
        fullWidth
        maxWidth="4xl"
        data-cy="test-schedule-dialog"
        open={open}
        onClose={onClose}
      >
        <Formik
          initialValues={{
            members: [],
            date: DateTime.now().plus({ weeks: 2 }).toFormat('yyyy-MM-dd'),
            disabled: false,
          }}
          validationSchema={Yup.object({
            date: Yup.date().test(
              'is-valid',
              t('SchedulerDate.invalidDate'),
              (value) => {
                return !!value && DateTime.fromJSDate(value).isValid;
              },
            ),
          })}
          onSubmit={async (...args) => {
            await onCreate(...args);
            onClose();
          }}
        >
          {({ values, isSubmitting, setFieldValue, errors, submitForm }) => {
            return (
              <>
                <DialogTitle subtitle={t('TestScheduler.info')}>
                  {t('TestScheduler.dialogTitle')}
                </DialogTitle>
                <DialogContent>
                  <MemberSelect
                    onChange={(members) => setFieldValue('members', members)}
                  />

                  <hr className="my-8" />

                  <SchedulerDate
                    date={values.date}
                    disabled={values.disabled}
                    setDisabled={(value) => setFieldValue('disabled', value)}
                    setDate={(date) => {
                      setFieldValue('date', date.toFormat('yyyy-MM-dd'));
                    }}
                  />
                </DialogContent>
                <DialogActions
                  loading={isSubmitting}
                  confirmText={t('TestScheduler.submitText')}
                  disabled={
                    !values.members.length ||
                    (!values.disabled && Object.keys(errors).length)
                  }
                  onCancel={onClose}
                  onConfirm={submitForm}
                />
              </>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
}
