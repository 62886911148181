import clsx from 'clsx';
import Button from '../System/Button';
import CheckIcon from '../../assets/check.svg';

export default function PricingCard({
  className,
  price,
  title,
  subtitle,
  features = [],
  ButtonProps: { text: ButtonText, ...ButtonPropsRest } = {},
  ...rest
}) {
  return (
    <div
      className={clsx(
        'bg-background-paper soft-blue-shadow rounded-2xl',
        'px-12 py-6 md:py-8',
        'text-center',
        'flex flex-col',
        className,
      )}
      {...rest}
    >
      <h2 className="text-primary-main mb-2 md:mb-4">{price}</h2>

      <p className="font-bold text-lg md:text-xl">{title}</p>

      <hr className="my-4" />

      <p className="italic text-sm mb-8 md:mb-12">{subtitle}</p>

      <ul
        className="text-left w-60 mx-auto max-w-full mb-6 md:mb-16"
        style={{ listStyleImage: `url("${CheckIcon}")` }}
      >
        {features.map((feature, index) => (
          <li key={index} className="pl-2 mb-4">
            {feature}
          </li>
        ))}
      </ul>

      <div className="mt-auto">
        <Button {...ButtonPropsRest}>{ButtonText}</Button>
      </div>
    </div>
  );
}
