export default function ArrowDropDown({ fill, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      {...rest}
    >
      <path d="M7 10l5 5 5-5z" fill={fill} />
    </svg>
  );
}
