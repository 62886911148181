import { gql, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import useSnackbar from '../System/useSnackbar';
import { useTranslation } from 'react-i18next';
import ScheduledTestFragment from '../../fragments/ScheduledTestFragment';
import useCurrentUser from '../CurrentUser/useCurrentUser';

const CREATE_SCHEDULED_TEST = gql`
  mutation CreateScheduledTest(
    $userId: String!
    $testId: String!
    $limitDate: String
  ) {
    createScheduledTest(
      userId: $userId
      testId: $testId
      limitDate: $limitDate
    ) {
      ...ScheduledTestFragment
    }
  }

  ${ScheduledTestFragment}
`;

export default function useCreateScheduledTest({ testId }) {
  const { t } = useTranslation();

  const { currentUser } = useCurrentUser();

  const { showSnackbar } = useSnackbar();

  const [create, { loading }] = useMutation(CREATE_SCHEDULED_TEST, {
    update(cache, { data: { createScheduledTest } }) {
      const updateCache = (existingScheduledTestRefs = [], { readField }) => {
        const newScheduledTestRef = cache.writeFragment({
          data: createScheduledTest,
          fragment: ScheduledTestFragment,
        });

        if (
          existingScheduledTestRefs.some(
            (ref) => readField('id', ref) === createScheduledTest.id,
          )
        ) {
          return existingScheduledTestRefs;
        }

        return [...existingScheduledTestRefs, newScheduledTestRef];
      };

      cache.modify({
        id: cache.identify({ __typename: 'Test', id: testId }),
        fields: {
          scheduledTests(existingScheduledTestRefs = [], { readField }) {
            return updateCache(existingScheduledTestRefs, { readField });
          },
        },
      });

      if (createScheduledTest.user.id === currentUser.id) {
        cache.modify({
          fields: {
            getScheduledTests(existingScheduledTestRefs = [], { readField }) {
              return updateCache(existingScheduledTestRefs, { readField });
            },
          },
        });
      }
    },
    onCompleted() {
      showSnackbar(t('useCreateScheduledTest.success'), 'success');
    },
    onError(err) {
      console.log(err);
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  const handleSubmit = async ({ members, date, disabled }) => {
    for (const { id: userId } of members) {
      await create({
        variables: {
          userId,
          testId,
          limitDate:
            !disabled && date
              ? DateTime.fromISO(date).toFormat('yyyy-MM-dd')
              : null,
        },
      });
    }
  };

  return { onCreate: handleSubmit, loading };
}
