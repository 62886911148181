import { useTranslation } from 'react-i18next';
import AuthLayout from '../components/Auth/AuthLayout';
import GoogleSignInButton from '../components/Google/GoogleSignInButton';
import SignInForm from '../components/SignIn/SignInForm';
import Or from '../components/System/Or';

export default function SignInPage() {
  const { t } = useTranslation();

  return (
    <AuthLayout title={t('SignInPage.title')}>
      <GoogleSignInButton />

      <Or className="my-8" />

      <SignInForm />
    </AuthLayout>
  );
}
