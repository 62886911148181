import shortid from 'shortid';
import UploadButton from '../System/UploadButton';
import AttachFileIcon from '../Icons/AttachFile';

export default function UploadMediaField({
  uploadLabel,
  noIcon = false,
  classes = {},
  accept,
  multiple,
  onChange,
}) {
  const handleContentsChange = ({ contents }) => {
    if (onChange) {
      onChange(
        contents.map((content) => ({
          ...content,
          id: shortid.generate(),
          description: '',
        })),
      );
    }
  };

  return (
    <UploadButton
      startIcon={noIcon ? null : <AttachFileIcon />}
      label={uploadLabel}
      accept={accept}
      multiple={multiple}
      className={classes.button}
      onChange={handleContentsChange}
    />
  );
}
