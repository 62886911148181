import Button from '../System/Button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DeleteIcon from '../Icons/DeleteForever';
import AutoResizeTextarea from '../System/AutoResizeTextarea';
import Document from '../Icons/Document';

export default function MediaGrid({
  className,
  mediaList,
  onChange,
  onDelete,
}) {
  const { t } = useTranslation();

  const handleChange = ({ id, media }) => {
    const newMediaList = mediaList.map((oldMedia) => {
      return oldMedia.id === id ? media : oldMedia;
    });

    onChange(newMediaList);
  };

  return (
    <div
      data-cy="media-grid"
      className={clsx('grid grid-cols-1 gap-4', className)}
    >
      {mediaList.map((media) => (
        <div
          key={media.id}
          className="flex bg-background-default border border-divider p-4"
        >
          <div
            className="w-16 h-16 bg-cover bg-center bg-gray-200 rounded mr-4 flex-shrink-0 flex items-center justify-center"
            style={{
              backgroundImage:
                media.src || media.preview
                  ? `url("${media.src || media.preview}")`
                  : null,
            }}
          >
            {media.src || media.preview ? null : (
              <Document className="w-8 opacity-70" />
            )}
          </div>

          <div className="flex-1 flex flex-col overflow-auto">
            <header className="flex items-start mb-2 max-w-full">
              <div className="px-2 overflow-hidden flex-1">
                <p
                  title={media.label}
                  className="text-sm mb-1 italic overflow-ellipsis overflow-hidden whitespace-nowrap"
                >
                  {media.label}
                </p>
                <AutoResizeTextarea
                  className="rounded border-none bg-transparent w-full p-0 focus:ring-0"
                  value={media.description}
                  placeholder="Description..."
                  minHeight={40}
                  onChange={(e) =>
                    handleChange({
                      id: media.id,
                      media: { ...media, description: e.currentTarget.value },
                    })
                  }
                />
              </div>

              <div className="ml-auto">
                <Button
                  startIcon={<DeleteIcon />}
                  size="small"
                  className="text-opacity-75 text-sm border border-divider"
                  onClick={() => onDelete(media)}
                >
                  {t('MediaGrid.deleteMedia')}
                </Button>
              </div>
            </header>
          </div>
        </div>
      ))}
    </div>
  );
}
