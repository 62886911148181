import { useState } from 'react';

export default function useTestsManagment({ tests = [] }) {
  const [checkedTests, setCheckedTests] = useState([]);

  const handleCheck = (event) => {
    const { name, checked } = event.currentTarget;
    if (checked) {
      setCheckedTests((prev) => [...prev, name]);
    } else {
      setCheckedTests((prev) => prev.filter((id) => id !== name));
    }
  };

  const handleCheckAll = () => {
    if (checkedTests.length === tests.length) {
      setCheckedTests([]);
    } else {
      setCheckedTests(tests.map((t) => t.id));
    }
  };

  return { checkedTests, onCheck: handleCheck, onCheckAll: handleCheckAll };
}
