import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import ScheduledDate from '../Issue/ScheduledDate';
import clsx from 'clsx';

export default function UpdateScheduledTests({
  className,
  assignedScheduledTests,
  checkedScheduledTests,
  setCheckedScheduledTests,
  isFailed = false,
}) {
  const { t } = useTranslation();

  return assignedScheduledTests.length ? (
    <div className={clsx('mt-6 bg-background-default px-6 py-4', className)}>
      {assignedScheduledTests.length === 1 ? (
        <div>
          <p className="text-sm">
            {assignedScheduledTests[0].limitDate
              ? t('UpdateScheduledTests.onePlannedWithLimitDate', {
                  limitDate: DateTime.fromMillis(
                    Number(assignedScheduledTests[0].limitDate),
                  ).toLocaleString(DateTime.DATE_HUGE),
                })
              : t('UpdateScheduledTests.onePlanned')}
          </p>

          <ScheduledDate
            checked={
              !!checkedScheduledTests.find((t) => {
                return t.id === assignedScheduledTests[0].id;
              })
            }
            label={
              isFailed
                ? t('UpdateScheduledTests.markPlannedAsFailed')
                : t('UpdateScheduledTests.markPlannedAsSuccess')
            }
            onChange={(event) =>
              setCheckedScheduledTests(
                event.target.checked ? [assignedScheduledTests[0]] : [],
              )
            }
          />
        </div>
      ) : (
        <>
          <p className="text-sm">
            {t('UpdateScheduledTests.hasScheduledTest', {
              // We do not use count variable so we do not generate
              // a _plural version as we are in a conditionnal if block
              // checking that assignedScheduledTests has a length > 1
              number: assignedScheduledTests.length,
            })}
          </p>

          <div>
            {assignedScheduledTests.map((scheduledTest) => (
              <ScheduledDate
                checked={
                  !!checkedScheduledTests.find((t) => {
                    return t.id === scheduledTest.id;
                  })
                }
                label={
                  isFailed
                    ? t('UpdateScheduledTests.markPlannedAsFailedWithDate', {
                        date: DateTime.fromMillis(
                          Number(scheduledTest.limitDate),
                        ).toLocaleString(DateTime.DATE_HUGE),
                      })
                    : t('UpdateScheduledTests.markPlannedAsSuccessWithDate', {
                        date: DateTime.fromMillis(
                          Number(scheduledTest.limitDate),
                        ).toLocaleString(DateTime.DATE_HUGE),
                      })
                }
                onChange={(event) => {
                  const { checked } = event.target;
                  if (checked) {
                    setCheckedScheduledTests((prev) => [
                      ...prev,
                      scheduledTest,
                    ]);
                  } else {
                    setCheckedScheduledTests((prev) => {
                      return prev.filter((s) => s.id !== scheduledTest.id);
                    });
                  }
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  ) : null;
}
