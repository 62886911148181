import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import CloseIcon from '../Icons/Close';
import Button from './Button';
import Alert from './Alert';

export default function Snackbar({
  open,
  message,
  children, // Not used but declared to prevent its used
  className,
  variant = 'info',
  onClose,
  ...rest
}) {
  const timerRef = useRef();

  const [localOpen, setLocalOpen] = useState(open);
  const [localVariant, setLocalVariant] = useState(variant);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open && localOpen) {
      setLocalOpen(false);
      setTimeout(() => {
        setLocalVariant(variant);
        setLocalOpen(true);
      }, 300);
    } else {
      setLocalVariant(variant);
      setLocalOpen(open);
    }
  }, [open, variant, message]);

  useEffect(() => {
    if (open) {
      timerRef.current = setTimeout(() => {
        onClose();
        timerRef.current = null;
      }, 7000);
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      };
    }
  }, [open, onClose]);

  return (
    <AnimatePresence>
      {localOpen ? (
        <motion.div
          className={clsx(
            'flex items-center',
            'fixed top-2 left-2 right-2',
            'md:bottom-12 md:top-auto md:right-auto md:left-12 ',
          )}
          style={{ zIndex: 100 }}
          transition={{ duration: 0.2 }}
          initial={{ x: -400 }}
          animate={{ x: 0 }}
          exit={{ x: -400 }}
          {...rest}
        >
          <Alert
            variant={localVariant}
            className={clsx(
              'flex items-center rounded shadow-lg',
              'px-8 py-8 md:py-4',
              className,
            )}
            endAdornment={
              <Button
                className="ml-8"
                noShadow
                noPadding
                noBg
                onClick={onClose}
              >
                <CloseIcon
                  className={clsx(
                    'w-6 h-6 fill-current',
                    localVariant === 'info' && 'text-blue-500',
                    localVariant === 'error' && 'text-red-500',
                    localVariant === 'success' && 'text-green-500',
                    localVariant === 'warning' && 'text-yellow-500',
                  )}
                />
              </Button>
            }
          >
            {message.text}
          </Alert>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
