import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../System/Button';
import PasswordTextField from '../System/PasswordTextField';
import TextField from '../System/TextField';
import useSignIn from './useSignIn';
import Error from '../System/Error';

export default function SignInForm() {
  const { t } = useTranslation();

  const { error, onSubmit } = useSignIn();

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t('Global.invalidEmail'))
            .required(t('Global.requiredField')),
          password: Yup.string()
            .min(6, t('Global.passwordMin6'))
            .required(t('Global.requiredField')),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <TextField
              autoComplete="username"
              label={t('SignInForm.emailLabel')}
              name="email"
              type="email"
            />

            <PasswordTextField
              autoComplete="current-password"
              label={t('SignInForm.passwordLabel')}
              name="password"
            />

            <div className="mb-4 text-sm flex justify-between">
              <p>
                <Link to="/forgot-password">
                  {t('SignInForm.forgotPassword')}
                </Link>
              </p>

              <p className="text-right">
                {t('SignInForm.noAccount')}{' '}
                <Link to="/sign-up">{t('SignInForm.register')}</Link>
              </p>
            </div>

            {error ? <Error>{t('SignInForm.error.global')}</Error> : null}

            <div>
              <Button
                className="mt-2"
                loading={isSubmitting}
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
              >
                {t('SignInForm.signInButton')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
