import { Form, Formik } from 'formik';
import Button from '../../System/Button';
import TextField from '../../System/TextField';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { CREATE_FOLDER } from '../CreateFolder';
import { useMutation, gql } from '@apollo/client';
import * as Yup from 'yup';

export default function CreateFolderForm({ className }) {
  const { t } = useTranslation();

  const [createFolder, { loading }] = useMutation(CREATE_FOLDER, {
    update(cache, { data: { createFolder } }) {
      cache.modify({
        fields: {
          getFolders(existingFolders = []) {
            const newFolderRef = cache.writeFragment({
              data: createFolder,
              fragment: gql`
                fragment NewFolder on Folder {
                  id
                  name
                  parentId
                }
              `,
            });
            return [...existingFolders, newFolderRef];
          },
        },
      });
    },
  });

  const handleSubmit = async ({ name }) => {
    await createFolder({ variables: { name } });
  };

  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={Yup.object({
        name: Yup.string().required(t('Global.requiredField')),
      })}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className={clsx(className)}>
          <div>
            <TextField
              name="name"
              placeholder={t('CreateFolderForm.namePlaceholder')}
            />
          </div>

          <div className="w-full">
            <Button
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              {t('CreateFolderForm.submitButton')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
