import { gql } from '@apollo/client';

const MediaFragment = gql`
  fragment MediaFragment on Media {
    id
    createdAt
    key
    src
    name
    description
    contentType
    size
    user {
      id
      username
    }
    thumbnail {
      id
      src
    }
  }
`;

export default MediaFragment;
