import { gql, useMutation } from '@apollo/client';
import MediaFragment from '../../fragments/MediaFragment';
import useMediaUpload from '../System/useMediaUpload';

const CREATE_ISSUE_COMMENT = gql`
  mutation CreateIssueComment(
    $issueId: String!
    $comment: String!
    $scheduledTestIds: [String!]
  ) {
    createIssueComment(
      issueId: $issueId
      comment: $comment
      scheduledTestIds: $scheduledTestIds
    ) {
      id
      createdAt
      comment
    }
  }
`;

const CREATE_MEDIA = gql`
  mutation CreateMedia(
    $key: String!
    $thumbnailMediaId: String
    $name: String
    $description: String
    $contentType: String
    $size: String
  ) {
    createMedia(
      key: $key
      thumbnailMediaId: $thumbnailMediaId
      name: $name
      description: $description
      contentType: $contentType
      size: $size
    ) {
      id
      key
      name
      description
      contentType
    }
  }
`;

const CREATE_ISSUE_COMMENT_MEDIA = gql`
  mutation CreateIssueCommentMedia(
    $issueCommentId: String!
    $mediaId: String!
  ) {
    createIssueCommentMedia(
      issueCommentId: $issueCommentId
      mediaId: $mediaId
    ) {
      id
      mediaList {
        ...MediaFragment
      }
    }
  }

  ${MediaFragment}
`;

export default function useCreateIssueComment() {
  const [createIssueComment, { loading, error }] = useMutation(
    CREATE_ISSUE_COMMENT,
  );

  const [createMedia] = useMutation(CREATE_MEDIA);

  const [createIssueCommentMedia] = useMutation(CREATE_ISSUE_COMMENT_MEDIA);

  const { upload } = useMediaUpload();

  const handleCreate = async (
    { issueId, description, mediaList, scheduledTestIds },
    { setValues } = {},
  ) => {
    const { data: issueCommentData } = await createIssueComment({
      variables: { issueId, comment: description, scheduledTestIds },
      refetchQueries: ['GetTest'],
      update(cache, { data: { createIssueComment } }) {
        cache.modify({
          id: cache.identify({ __typename: 'Issue', id: issueId }),
          fields: {
            comments(existingComments = []) {
              const newCommentRef = cache.writeFragment({
                data: createIssueComment,
                fragment: gql`
                  fragment NewIssue on Issue {
                    id
                    createdAt
                    comment
                  }
                `,
              });
              return [...existingComments, newCommentRef];
            },
          },
        });
      },
    });

    // Upload media
    for (const media of mediaList) {
      upload(media, {
        async onCompleted({ previewKey, key }) {
          let previewMediaId;
          if (previewKey) {
            const { data } = await createMedia({
              variables: {
                key: previewKey,
                name: `${media.file.name
                  .split('.')
                  .slice(0, -1)
                  .join('.')}_preview.jpg`,
                contentType: 'image/jpeg',
              },
            });

            previewMediaId = data.createMedia.id;
          }

          const { data } = await createMedia({
            variables: {
              key,
              thumbnailMediaId: previewMediaId,
              name: media.file.name,
              description: media.description,
              contentType: media.file.type,
              size: media.file.size.toString(),
            },
          });
          await createIssueCommentMedia({
            variables: {
              issueCommentId: issueCommentData.createIssueComment.id,
              mediaId: data.createMedia.id,
            },
          });
        },
      });
    }

    if (setValues) {
      setValues({ description: '', mediaList: [] });
    }
  };

  return { loading, error, onCreate: handleCreate };
}
