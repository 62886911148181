import Billing from '../components/Billing/Billing';
import MainLayout from '../components/Layout/MainLayout';

export default function BillingPage() {
  return (
    <MainLayout>
      <div className="mt-16 mb-8 w-full max-w-4xl mx-auto">
        <Billing />
      </div>
    </MainLayout>
  );
}
