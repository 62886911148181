import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CREATE_FOLDER = gql`
  mutation CreateFolder($name: String!, $parentId: String) {
    createFolder(name: $name, parentId: $parentId) {
      id
      name
      parentId
    }
  }
`;

export default function CreateFolder({
  className,
  style,
  noHelper,
  parentId,
  onBlur,
}) {
  const { t } = useTranslation();

  const [name, setName] = useState('');

  const [createFolder, { loading }] = useMutation(CREATE_FOLDER, {
    update(cache, { data: { createFolder } }) {
      cache.modify({
        fields: {
          getFolders(existingFolders = []) {
            const newFolderRef = cache.writeFragment({
              data: createFolder,
              fragment: gql`
                fragment NewFolder on Folder {
                  id
                  name
                  parentId
                }
              `,
            });
            return [...existingFolders, newFolderRef];
          },
        },
      });
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    await createFolder({ variables: { name, parentId } });

    onBlur();

    setName('');
  };

  return (
    <form className={className} style={style} onSubmit={handleSubmit}>
      <input
        type="text"
        className="w-full py-1 bg-white bg-opacity-10 rounded border-primary-dark"
        placeholder={t('CreateFolder.createPlaceholder')}
        value={name}
        disabled={loading}
        onBlur={onBlur}
        onChange={(e) => setName(e.target.value)}
      />

      {!noHelper && name ? (
        <p className="text-sm opacity-50 mx-2 my-1">
          {t('CreateFolder.helper')}
        </p>
      ) : null}
    </form>
  );
}
