import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import useCurrentUser from '../CurrentUser/useCurrentUser';
import DeleteScheduledTest from './DeleteScheduledTest';
import UpdateScheduledTest from './UpdateScheduledTest';
import ScheduledTestResult from './ScheduledTestResult';
import { Link } from 'react-router-dom';

export default function ScheduledTest({ scheduledTest }) {
  const { t } = useTranslation();

  const { currentUser } = useCurrentUser();
  return (
    <div
      data-cy="test-item"
      className="rounded bg-background-paper mb-4 flex flex-1 hover:shadow-md transition-shadow"
    >
      <div className="flex-1">
        <Link
          className="no-underline flex flex-col px-6 py-2"
          to={`/tests/${scheduledTest.test.folderId}/${scheduledTest.test.id}`}
        >
          <p>{scheduledTest.test.title}</p>

          <p className="caption">
            {currentUser.id === scheduledTest.createdBy.id
              ? t('ScheduledTest.assignedByYou', {
                  datetime: DateTime.fromMillis(
                    Number(scheduledTest.createdAt),
                  ).toLocaleString(DateTime.DATETIME_MED),
                })
              : t('ScheduledTest.assignedBy', {
                  datetime: DateTime.fromMillis(
                    Number(scheduledTest.createdAt),
                  ).toLocaleString(DateTime.DATETIME_MED),
                  author: scheduledTest.createdBy.username,
                })}
          </p>
        </Link>
      </div>

      {scheduledTest.scheduledTestIssueResult ||
      scheduledTest.scheduledTestPassedResult ? (
        <ScheduledTestResult scheduledTest={scheduledTest} />
      ) : (
        <div className="flex">
          <UpdateScheduledTest
            className="hover:bg-background-default px-4 py-2"
            scheduledTestId={scheduledTest.id}
            initialLimitDate={scheduledTest.limitDate}
          />
          <DeleteScheduledTest
            className="hover:bg-background-default px-4 py-2"
            testId={scheduledTest.test.id}
            scheduledTestId={scheduledTest.id}
          />
        </div>
      )}
    </div>
  );
}
