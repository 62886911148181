import clsx from 'clsx';
import useCurrentUser from '../CurrentUser/useCurrentUser';

export default function UserAvatar({
  src,
  size = 'medium',
  user: providedUser,
  className,
  ...rest
}) {
  const { currentUser } = useCurrentUser();

  const user = providedUser || currentUser;

  return (
    <div
      className={clsx(
        'rounded-full flex items-center justify-center font-bold overflow-hidden',
        'text-opacity-75 bg-gray-200',
        size === 'large'
          ? 'w-32 h-32 text-3xl'
          : size === 'medium'
          ? 'w-10 h-10 text-lg'
          : 'w-7 h-7 text-base',
        className,
      )}
      {...rest}
    >
      {src || user?.picture?.src ? (
        <img
          className="object-cover h-full w-full"
          src={src || user.picture.src}
          alt=""
        />
      ) : (
        user?.username?.length && user.username[0].toUpperCase()
      )}
    </div>
  );
}
