import Agenda from '../components/Agenda/Agenda';
import MainLayout from '../components/Layout/MainLayout';

export default function AgendaPage() {
  return (
    <MainLayout>
      <div className="my-8 w-full max-w-7xl mx-auto">
        <Agenda />
      </div>
    </MainLayout>
  );
}
