import { Form, Formik } from 'formik';
import { useState } from 'react';
import Update from '../Icons/Update';
import Button from '../System/Button';
import Menu from '../System/Menu';
import useOpen from '../System/useOpen';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import TextField from '../System/TextField';
import { gql, useMutation } from '@apollo/client';
import useSnackbar from '../System/useSnackbar';
import ScheduledTestFragment from '../../fragments/ScheduledTestFragment';
import clsx from 'clsx';

const UPDATE_SCHEDULED_TEST = gql`
  mutation UpdateScheduledTest($scheduledTestId: String!, $limitDate: String!) {
    updateScheduledTest(
      scheduledTestId: $scheduledTestId
      limitDate: $limitDate
    ) {
      ...ScheduledTestFragment
    }
  }

  ${ScheduledTestFragment}
`;

export default function UpdateScheduledTest({
  className,
  scheduledTestId,
  initialLimitDate,
}) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();
  const [menuReferenceElement, setMenuReferenceElement] = useState(null);

  const { showSnackbar } = useSnackbar();
  const [updateScheduledTest] = useMutation(UPDATE_SCHEDULED_TEST, {
    onCompleted() {
      showSnackbar(t('UpdateScheduledTest.success'), 'success');
      onClose();
    },
    onError() {
      showSnackbar(t('Global.serverError'));
    },
  });

  const handleSubmit = async ({ date }) => {
    await updateScheduledTest({
      variables: { scheduledTestId, limitDate: date },
    });
  };

  return (
    <>
      <Button
        title={t('UpdateScheduledTest.buttonTooltip')}
        data-cy="update-scheduled-date"
        ref={setMenuReferenceElement}
        noPadding
        noShadow
        className={clsx('opacity-60 hover:opacity-100', className)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onOpen();
        }}
      >
        <Update className="w-6 h-6" />
      </Button>

      <Menu
        data-cy="update-scheduled-date-menu"
        open={open}
        placement="bottom-start"
        onClose={onClose}
        referenceElement={menuReferenceElement}
      >
        <div className="px-6 py-4">
          <Formik
            initialValues={{
              date:
                (initialLimitDate &&
                  DateTime.fromMillis(Number(initialLimitDate)).toFormat(
                    'yyyy-MM-dd',
                  )) ||
                DateTime.now().toFormat('yyyy-MM-dd'),
            }}
            validationSchema={Yup.object({
              date: Yup.date().test(
                'is-valid',
                t('UpdateScheduledTest.invalidDate'),
                (value) => {
                  return !!value && DateTime.fromJSDate(value).isValid;
                },
              ),
            })}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="w-52">
                  <TextField
                    type="date"
                    name="date"
                    label={t('UpdateScheduledTest.updateDate')}
                    skipTouchedError
                  />
                </div>

                <div className="flex items-center">
                  <div className="mr-4">
                    <Button variant="outlined" size="small" onClick={onClose}>
                      {t('UpdateScheduledTest.cancel')}
                    </Button>
                  </div>

                  <div className="flex-1">
                    <Button
                      loading={isSubmitting}
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      {t('UpdateScheduledTest.submit')}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Menu>
    </>
  );
}
