import { useTranslation } from 'react-i18next';

export default function ScheduledTestResult({ scheduledTest }) {
  const { t } = useTranslation();

  return scheduledTest.scheduledTestIssueResult ? (
    <p className="px-4 py-1 rounded-sm text-sm bg-yellow-100 text-yellow-800">
      {t('ScheduledTestResult.issue')}
    </p>
  ) : scheduledTest.scheduledTestPassedResult ? (
    <p className="px-4 py-1 rounded-sm text-sm bg-green-100 text-green-900">
      {t('ScheduledTestResult.success')}
    </p>
  ) : null;
}
