import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import StepList from '../Step/StepList';
import TestHeader from './TestHeader';
import TestTopBar from './TestTopBar';
import useTest from './useTest';

import { useTranslation } from 'react-i18next';
import TestTimeline from './TestTimeline';

const TestContent = () => {
  const { t } = useTranslation();

  const { loading, test } = useTest();

  return loading ? null : test ? (
    <>
      <TestTopBar
        scheduledTests={test.scheduledTests}
        archived={test.archived}
      />

      <AnimatePresence exitBeforeEnter>
        <motion.div
          className="flex flex-col"
          initial={{ y: -5, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -5, opacity: 0 }}
          transition={{ bounce: 0, duration: 0.2 }}
          key={test.id}
        >
          <TestHeader className="px-8 pt-4 pb-6 shadow-lg" test={test} />

          <div className="overflow-auto hide-scrollbar border-t border-divider pb-16">
            <StepList test={test} />

            <hr className="border-t-8 border-background-default" />

            <h2 className="mx-8 my-8">{t('Test.resultTitle')}</h2>
            <TestTimeline className="mx-8 my-6" test={test} />
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  ) : null;
};

export default function Test({ className }) {
  const { testId } = useParams();

  return (
    <div
      className={clsx('bg-background-paper flex flex-col', className)}
      data-cy="test-detail"
    >
      {testId ? <TestContent /> : null}
    </div>
  );
}
