import { useCallback } from 'react';

const useOrder = ({
  objects: unsortedObjects,
  positionStep,
  onOrderChange,
}) => {
  const reorder = useCallback(
    ({ oldIndex, newIndex }) => {
      const objects = unsortedObjects.sort((a, b) =>
        a.position < b.position ? -1 : 1,
      );

      if (oldIndex !== newIndex) {
        let newPos;
        let diff = 0;
        if (newIndex < oldIndex) {
          const before = newIndex > 0 ? objects[newIndex - 1] : { position: 0 };
          const after = objects[newIndex];
          diff = after.position - before.position;
          newPos = before.position + (after.position - before.position) / 2;
        } else {
          const before = objects[newIndex];
          const after =
            newIndex < objects.length - 1
              ? objects[newIndex + 1]
              : {
                  position: objects[newIndex].position + 2 * positionStep,
                };
          diff = after.position - before.position;
          newPos = before.position + (after.position - before.position) / 2;
        }

        onOrderChange({
          changes: [
            { object: objects[oldIndex], position: Math.round(newPos), diff },
          ],
        });
      }
    },

    [unsortedObjects, positionStep, onOrderChange],
  );

  return { onOrder: reorder };
};

export default useOrder;
