import { useField } from 'formik';

export default function Checkbox({ children, label, hideError, ...props }) {
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return (
    <div>
      <label className="flex items-center text-sm">
        <input
          type="checkbox"
          className="cursor-pointer rounded-sm w-5 h-5 border border-gray-300 mr-2"
          {...field}
          {...props}
        />
        {label}
      </label>

      {!hideError && meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
}
