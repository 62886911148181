import React from 'react';
import ReactDOM from 'react-dom';

import initSentry from './sentry';

import './i18n';
import 'react-quill/dist/quill.snow.css';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

// We load Stripe JS globally to laverage Stripe's fraud functionality
// https://github.com/stripe/stripe-js#import-as-a-side-effect
import '@stripe/stripe-js';

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
