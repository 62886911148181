import { useState } from 'react';
import Visibility from '../Icons/Visibility';
import VisibilityOff from '../Icons/VisibilityOff';
import TextField from './TextField';

export default function PasswordTextField({ ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      endAdornment={showPassword ? <Visibility /> : <VisibilityOff />}
      onAdornmentClick={() => setShowPassword((prev) => !prev)}
      {...rest}
    />
  );
}
