import getVideoThumbnail from '../get-video-thumbnail';

const getThumbnailFromFile = async (file) => {
  if (file.type.startsWith('image/')) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        resolve('');
      }
    });
  } else if (file.type.startsWith('video/')) {
    return new Promise(async (resolve) => {
      const thumbnail = await getVideoThumbnail({ file });
      resolve(thumbnail);
    });
  }
};

export default async function filesToContents(files) {
  const contents = await Promise.all(
    files.map(async (file) => {
      const preview = await getThumbnailFromFile(file);

      return { file, preview, label: file.name };
    }),
  );

  return contents;
}
