import clsx from 'clsx';
import DeleteIcon from '../../Icons/DeleteForever';
import Button from '../../System/Button';

export default function StepItem({
  invisible,
  step,
  steps,
  sortableListeners,
  sortableAttributes,
  undraggable = false,
  placeholder,
  onAdd,
  onDelete,
  onInputChange,
}) {
  return (
    <div
      key={step.id}
      className={clsx('w-full flex items-center', invisible && 'opacity-0')}
    >
      {undraggable ? null : (
        <div
          className="cursor-move touch-action-none outline-none"
          {...sortableListeners}
          {...sortableAttributes}
        >
          <svg
            className={clsx('mr-2 opacity-50 flex items-center')}
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
        </div>
      )}

      <input
        data-cy="step-input"
        className="flex-1 border-gray-300 rounded"
        type="text"
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onAdd();
          }
        }}
        value={step.title}
        autoFocus={steps.length > 1}
        onChange={(e) => onInputChange(e, step.id)}
      />

      {steps.length > 1 ? (
        <Button noPadding noShadow noBg className="flex-shrink-0 ml-4">
          <DeleteIcon className="w-5 h-5" onClick={() => onDelete(step.id)} />
        </Button>
      ) : null}
    </div>
  );
}
