import ChangeLanguage from '../Language/ChangeLanguage';
import Card from '../System/Card';
import LogoLetter from '../../assets/logo-kt-letter.svg';

export default function AuthLayout({ title, children }) {
  return (
    <div className="flex-1 flex flex-col bg-background-paper sm:bg-background-default">
      <header className="flex justify-end py-4 px-4 md:px-16">
        <ChangeLanguage />
      </header>

      <main className="flex-1 flex flex-col items-center px-4">
        <div className="mt-0 mb-0 sm:mb-4 md:my-16">
          <img
            src={LogoLetter}
            alt="Keep Testing"
            className="w-12 md:w-16 h-14 md:h-18"
          />
        </div>
        <Card className="w-full max-w-lg">
          <h1 className="mb-6 text-center">{title}</h1>

          {children}
        </Card>
      </main>

      <footer className="mx-auto p-4">
        ©{' '}
        <a href="https://keep-testing.com/" className="no-underline">
          Keept Testing
        </a>{' '}
        - 2021
      </footer>
    </div>
  );
}
