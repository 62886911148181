import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import InviteFragment from '../../fragments/InviteFragment';
import WorspaceUser from './WorkspaceUser';

export const GET_INVITES = gql`
  query GetWorkspaceInvites {
    getWorkspaceInvites {
      ...InviteFragment
    }
  }

  ${InviteFragment}
`;

// i18next parser
//
// t('UserRole.OWNER');
// t('UserRole.TESTER');

export default function WorspaceUsers({ workspaceId, users }) {
  const { t } = useTranslation();

  return (
    <div data-cy="workspace-users">
      <p className="opacity-90 mb-2">
        <b>{t('WorkspaceUsers.title')}</b>
      </p>

      <ul>
        {users.map(({ user, roles }) => (
          <WorspaceUser
            workspaceId={workspaceId}
            key={user.id}
            user={user}
            roles={roles}
          />
        ))}
      </ul>
    </div>
  );
}
