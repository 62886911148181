import { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import TestsListItem from './TestsListItem';
import TestListItemDraggable from './TestsListItemDraggable';

// i18next parser

// t('TestsList.NOT_TESTED')
// t('TestsList.PASSED')
// t('TestsList.FAILED')

export default function TestsList({
  className,
  tests,
  unfilteredTests,
  checkedTests,
  onCheck,
  onTestOrderChange,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [activeId, setActiveId] = useState();

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(e) {
    if (e.active && e.over) {
      const oldIndex = tests.findIndex((t) => t.id === e.active.id);
      const newIndex = tests.findIndex((t) => t.id === e.over.id);
      onTestOrderChange({ oldIndex, newIndex });
    }

    setActiveId(null);
  }

  const { t } = useTranslation();

  return (
    <section className={clsx(className)}>
      <div>
        <>
          {tests.length ? (
            <ul className="mx-4 py-2" data-cy="tests-list">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={tests.map((t) => t.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {tests.map((test) => (
                    <TestListItemDraggable
                      key={test.id}
                      invisible={activeId === test.id}
                      test={test}
                      checkedTests={checkedTests}
                      onCheck={onCheck}
                    />
                  ))}
                </SortableContext>
                <DragOverlay>
                  {activeId ? (
                    <TestsListItem
                      test={tests.find((t) => t.id === activeId)}
                      checkedTests={checkedTests}
                      onCheck={onCheck}
                    />
                  ) : null}
                </DragOverlay>
              </DndContext>
            </ul>
          ) : (
            <p className="mt-4 mx-4 empty-state">{t('TestsList.emptyState')}</p>
          )}
        </>
      </div>
    </section>
  );
}
