import MainLayout from '../components/Layout/MainLayout';
import TestsLayout from '../components/Tests/TestsLayout';

export default function TestsPage() {
  return (
    <MainLayout>
      <TestsLayout />
    </MainLayout>
  );
}
