import Button from '../System/Button';
import { useTranslation } from 'react-i18next';
import Dialog from '../System/Dialog';
import DialogTitle from '../System/DialogTitle';
import useOpen from '../System/useOpen';
import DialogContent from '../System/DialogContent';
import TextField from '../System/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DialogActions from '../System/DialogActions';
import { gql, useMutation } from '@apollo/client';
import useRefresh from '../Auth/useRefresh';

const DELETE_WORKSPACE = gql`
  mutation DeleteWorkspace {
    deleteWorkspace
  }
`;

export default function DeleteWorkspace({ workspaceName }) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();
  const { onRefresh } = useRefresh();

  const [deleteWorkspace, { loading }] = useMutation(DELETE_WORKSPACE, {
    onCompleted() {
      onRefresh();
    },
  });

  return (
    <>
      <Button
        variant="outlined"
        className="text-red-500 border-red-200"
        onClick={onOpen}
      >
        {t('DeleteWorkspace.buttonText')}
      </Button>

      <Dialog data-cy="delete-workspace-dialog" open={open} onClose={onClose}>
        <DialogTitle>{t('DeleteWorkspace.dialogTitle')}</DialogTitle>

        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(t('Global.requiredField')),
          })}
          onSubmit={() => null}
        >
          {({ values }) => (
            <>
              <DialogContent>
                <p>{t('DeleteWorkspace.dialogText')}</p>

                <p className="mt-4">
                  {t('DeleteWorkspace.dialogInputInfo', {
                    name: workspaceName,
                  })}
                </p>

                <TextField
                  className="mt-2"
                  placeholder={t('DeleteWorkspace.dialogInputPlaceholder')}
                  name="name"
                />
              </DialogContent>

              <DialogActions
                classes={{
                  delete: 'bg-red-100 text-red-600',
                }}
                loading={loading}
                disabled={values.name !== workspaceName}
                onCancel={onClose}
                onDelete={deleteWorkspace}
              />
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
