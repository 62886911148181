import { useTranslation } from 'react-i18next';
import MoreVert from '../Icons/MoreVert';
import Menu from '../System/Menu';
import Button from '../System/Button';
import useOpen from '../System/useOpen';
import WorkspaceRemoveUser from './WorkspaceRemoveUser';
import UserAvatar from '../System/UserAvatar';
import { useState } from 'react';

// i18next parser
//
// t('UserRole.OWNER');
// t('UserRole.TESTER');

export default function WorspaceUser({ user, roles, workspaceId }) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();
  const [menuReferenceElement, setMenuReferenceElement] = useState(null);

  return (
    <li
      key={user.id}
      className="bg-background-default mb-4 px-6 py-2 rounded border border-divider flex items-center"
      data-cy="workspace-user"
    >
      <UserAvatar user={user} className="mr-4" />
      <p className="flex-1">{user.username}</p>
      <div>
        {roles.map((role) =>
          role === 'JOINING' ? (
            <p key={role} className="empty-state">
              {t('WorkspaceUsers.joiningRole')}
            </p>
          ) : (
            <p
              key={role}
              className="caption font-bold bg-gray-200 py-1 px-2 rounded"
            >
              {t(`UserRole.${role}`)}
            </p>
          ),
        )}
      </div>

      {roles.indexOf('OWNER') === -1 ? (
        <div className="ml-4">
          <Button
            data-cy="workspace-user-more-options"
            noPadding
            noShadow
            onClick={onOpen}
            ref={setMenuReferenceElement}
          >
            <MoreVert className="w-6 h-6 opacity-70" />
          </Button>

          <Menu
            offset={[0, 4]}
            referenceElement={menuReferenceElement}
            open={open}
            onClose={onClose}
          >
            <WorkspaceRemoveUser
              workspaceId={workspaceId}
              onExited={onClose}
              userId={user.id}
              username={user.username}
              userRoles={roles}
            />
          </Menu>
        </div>
      ) : null}
    </li>
  );
}
