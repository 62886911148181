import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import TextField from '../System/TextField';
import * as Yup from 'yup';
import useCurrentUser from '../CurrentUser/useCurrentUser';
import Button from '../System/Button';
import { gql, useMutation } from '@apollo/client';
import useSnackbar from '../System/useSnackbar';
import useRefresh from '../Auth/useRefresh';
import UserAvatar from '../System/UserAvatar';
import UploadMediaField from '../Media/UploadMediaField';
import useMediaUpload from '../System/useMediaUpload';
import s3PostSignedUrl from '../System/s3-post-signed-url';

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($username: String!, $pictureMediaId: String) {
    updateProfile(username: $username, pictureMediaId: $pictureMediaId) {
      id
      username
      picture {
        id
        key
        src
      }
    }
  }
`;

const CREATE_MEDIA = gql`
  mutation CreateMedia($key: String!, $contentType: String) {
    createMedia(key: $key, contentType: $contentType) {
      id
    }
  }
`;

export default function ProfileForm() {
  const { t } = useTranslation();

  const { currentUser } = useCurrentUser();

  const { showSnackbar } = useSnackbar();
  const { onRefresh } = useRefresh();
  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted() {
      onRefresh();
      showSnackbar(t('ProfileForm.updateSuccess'), 'success');
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  const { getSignedUrl } = useMediaUpload();
  const [createMedia] = useMutation(CREATE_MEDIA);

  const handleSubmit = async ({ username, uploadContent }) => {
    let pictureMediaId = currentUser.picture?.id;
    if (uploadContent) {
      const signedUrl = await getSignedUrl({
        file: uploadContent.file,
        context: 'USER',
      });

      const { key } = await s3PostSignedUrl({
        signedUrl,
        file: uploadContent.file,
      });

      const {
        data: {
          createMedia: { id: mediaId },
        },
      } = await createMedia({
        variables: { key, contentType: uploadContent.file.type },
      });

      pictureMediaId = mediaId;
    }

    await updateProfile({ variables: { username, pictureMediaId } });
  };

  return (
    <div>
      <h1 className="mb-8">{t('ProfileForm.title')}</h1>

      <div
        className="bg-background-paper border border-divider px-16 py-8 rounded"
        data-cy="profile-form"
      >
        <Formik
          initialValues={{
            username: currentUser.username,
            picture: currentUser.picture,
          }}
          validationSchema={Yup.object({
            username: Yup.string().required(t('Global.requiredField')),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, setValues, errors, isSubmitting }) => {
            return (
              <Form className="max-w-md" noValidate>
                <div>
                  <TextField
                    name="username"
                    label={t('ProfileForm.usernameLabel')}
                  />
                </div>

                <div className="my-8">
                  <p className="opacity-75 font-bold text-sm mb-2">
                    {t('ProfileForm.pictureLabel')}
                  </p>
                  <div className="flex items-center">
                    <UserAvatar
                      size="large"
                      src={values.uploadContent?.preview}
                    />
                    <div className="ml-4">
                      <UploadMediaField
                        classes={{
                          button: 'border-red-500 text-primary-main',
                        }}
                        accept="image/png, image/jpeg"
                        uploadLabel={t('ProfileForm.editButton')}
                        noIcon
                        onChange={(contents) => {
                          if (contents?.length) {
                            setValues((prev) => {
                              return {
                                ...prev,
                                uploadContent: contents[0],
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={Object.keys(errors).length}
                    variant="contained"
                    color="primary"
                  >
                    {t('ProfileForm.submitButton')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
