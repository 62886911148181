import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import Button from '../../System/Button';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../Icons/Close';
import { DateTime } from 'luxon';
import DownloadIcon from '../../Icons/Download';
import fileSize from 'filesize';

function Portal({ children }) {
  return createPortal(children, document.getElementById('modal-root'));
}

export default function Carousel({
  mediaList,
  currentMediaId,
  open,
  className = null,
  children,
  onClose,
  onPrevious,
  onNext,
  ...rest
}) {
  const { t } = useTranslation();

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      if (onClose) {
        onClose();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  const media = useMemo(() => {
    return currentMediaId && mediaList.find((m) => m.id === currentMediaId);
  }, [currentMediaId, mediaList]);

  return (
    <AnimatePresence>
      {open ? (
        <Portal>
          <motion.div
            className={clsx(
              'flex',
              'items-center',
              'justify-center',
              'fixed top-0',
              'bottom-0',
              'left-0',
              'right-0',
              'bg-white',
              'bg-opacity-50',
              'z-30',
              children,
            )}
            style={{ backdropFilter: 'saturate(180%) blur(15px)' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{ opacity: 0, transition: { duration: 0.15 } }}
            {...rest}
          >
            <div
              className={clsx(`absolute top-0 bottom-0 left-0 right-0 flex`)}
            >
              <div
                className="flex-1 flex items-center justify-center px-12 py-12"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                {media.contentType.startsWith('image/') ? (
                  <img
                    className="max-w-full max-h-full shadow-lg rounded"
                    src={media.src || media.preview}
                    alt=""
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                ) : media.contentType.startsWith('video/') ? (
                  <video
                    className="shadow-lg"
                    src={media.src}
                    controls
                    autoPlay
                    onClick={(e) => e.stopPropagation()}
                  />
                ) : (
                  <div
                    className="bg-background-paper px-8 py-8 rounded shadow-lg flex flex-col items-center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <p>{t('Carousel.unknownFormat')}</p>

                    <p
                      title={`${media.name} - ${fileSize(Number(media.size))}`}
                      className="mt-4 mb-4 caption overflow-ellipsis overflow-hidden whitespace-nowrap max-w-sm"
                    >
                      {media.name} - {fileSize(Number(media.size))}
                    </p>

                    <div>
                      <Button
                        startIcon={<DownloadIcon />}
                        variant="contained"
                        className="border border-divider no-underline"
                        component="a"
                        href={media.src}
                      >
                        {t('Carousel.download')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div className="bg-background-paper w-full max-w-sm border-l border-divider">
                <header className="flex justify-end border-b border-divider px-4 py-2">
                  <div>
                    <Button
                      size="small"
                      startIcon={<CloseIcon />}
                      variant="contained"
                      onClick={onClose}
                    >
                      {t('Carousel.close')}
                    </Button>
                  </div>
                </header>

                <div className="px-4 py-4">
                  <p className="caption mb-2 overflow-ellipsis overflow-hidden whitespace-nowrap">
                    {t('Global.createdAt', {
                      datetime: DateTime.fromMillis(
                        Number(media.createdAt),
                      ).toLocaleString(DateTime.DATETIME_MED),
                      author: media.user.username,
                    })}
                  </p>

                  {media.name ? (
                    <p
                      title={media.name}
                      className="mt-2 font-bold overflow-ellipsis overflow-hidden whitespace-nowrap"
                    >
                      {media.name}
                    </p>
                  ) : null}

                  {media.description ? (
                    <p className="mt-2 caption text-base">
                      {media.description}
                    </p>
                  ) : null}

                  <hr className="my-6" />

                  <p className="caption mb-2">
                    {t('Carousel.size')} {fileSize(Number(media.size))}
                  </p>

                  <div>
                    <Button
                      fullWidth
                      startIcon={<DownloadIcon />}
                      variant="outlined"
                      className="no-underline"
                      component="a"
                      href={media.src}
                    >
                      {t('Carousel.download')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </Portal>
      ) : null}
    </AnimatePresence>
  );
}
