import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import { gql, useMutation } from '@apollo/client';
import IssueActionFragment from '../../fragments/IssueCommentFragment';

const OPEN_ISSUE = gql`
  mutation UpdateIssue($issueId: String!, $status: ISSUE_STATUS!) {
    updateIssue(issueId: $issueId, status: $status) {
      id
      status
      test {
        id
        title
        position
        status
        createdAt
      }
      actions {
        ...IssueActionFragment
      }
    }
  }

  ${IssueActionFragment}
`;

export default function ReopenIssueButton({
  withComment = false,
  issueId,
  onBeforeReopened,
}) {
  const { t } = useTranslation();

  const [reopenIssue] = useMutation(OPEN_ISSUE, {
    variables: {
      issueId,
      status: 'OPEN',
    },
  });

  const handleReopen = async () => {
    if (onBeforeReopened) {
      await onBeforeReopened();
    }

    await reopenIssue();
  };

  return (
    <Button
      size="small"
      className="text-gray-500 bg-gray-50 border border-gray-200"
      color="default"
      onClick={handleReopen}
    >
      {withComment
        ? t('ReopenIssueButton.reopenIssueWithComment')
        : t('ReopenIssueButton.reopenIssue')}
    </Button>
  );
}
