import { gql, useQuery } from '@apollo/client';

const GET_CURRENT_WORKSPACE = gql`
  query GetCurrentWorkspace {
    getCurrentWorkspace {
      id
      name
      users {
        user {
          id
          username
          picture {
            src
          }
        }
        roles
      }
    }
  }
`;

export default function useCurrentWorkspace() {
  const { loading, data, error } = useQuery(GET_CURRENT_WORKSPACE);

  return { loading, error, currentWorkspace: data?.getCurrentWorkspace };
}
