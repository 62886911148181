import { useEffect, useMemo, useState } from 'react';
import Menu from '../System/Menu';
import MenuItem from '../System/MenuItem';
import useOpen from '../System/useOpen';
import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import ArrowDropDown from '../Icons/ArrowDropDown';
import useCurrentWorkspace from '../Workspace/useCurrentWorkspace';
import CircularProgress from '../System/CircularProgress';
import UserAvatar from '../System/UserAvatar';
import Close from '../Icons/Close';
import { motion } from 'framer-motion';

export default function MemberSelect({ onChange }) {
  const { t } = useTranslation();

  const [menuReferenceElement, setMenuReferenceElement] = useState(null);

  const { open, onOpen, onClose } = useOpen();

  const { loading, currentWorkspace } = useCurrentWorkspace();

  const [selected, setSelected] = useState([]);

  // Disable ESLint for onChange function as it is recreated on each
  // render of the parent component via a render props pattern
  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const usersList = useMemo(() => {
    return currentWorkspace?.users.filter(
      (u) => !selected.find((selectedUser) => selectedUser.id === u.user.id),
    );
  }, [currentWorkspace, selected]);

  return (
    <div>
      <p className="mb-2 opacity-75 font-bold text-sm">
        {t('MemberSelect.addMemberLabel')}
      </p>

      <div>
        <Button
          ref={setMenuReferenceElement}
          variant="outlined"
          endIcon={<ArrowDropDown />}
          noPadding
          className="px-4 py-2"
          onClick={onOpen}
        >
          {t('MemberSelect.addButton')}
        </Button>
      </div>

      <div className="mt-2 flex items-center flex-wrap">
        {selected.length ? (
          selected.map((user) => (
            <motion.div
              key={user.id}
              data-cy="added-member-item"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="mr-4 mb-2 bg-primary-main bg-opacity-10 px-4 py-1 rounded-sm flex items-center"
            >
              <div className="flex items-center">
                <UserAvatar user={user} className="mr-2" size="small" />
                <p className="caption">{user.username}</p>
              </div>

              <Button
                noPadding
                noBg
                noShadow
                size="small"
                className="ml-2"
                onClick={() =>
                  setSelected((prev) => prev.filter((u) => u.id !== user.id))
                }
              >
                <Close className="w-5 h-5" />
              </Button>
            </motion.div>
          ))
        ) : (
          <p className="empty-state text-sm">{t('MemberSelect.emptyState')}</p>
        )}
      </div>

      <Menu
        open={open}
        placement="bottom-start"
        onClose={onClose}
        referenceElement={menuReferenceElement}
      >
        <p className="font-bold px-4 pt-3 pb-1 caption">
          {t('MemberSelect.pickAMember')}
        </p>

        {loading ? (
          <div className="py-4 px-4 flex items-center">
            <CircularProgress />
          </div>
        ) : usersList.length ? (
          usersList.map(({ user }) => (
            <MenuItem
              key={user.id}
              data-cy="member-select-item"
              onClick={() => {
                setSelected((prev) => [...prev, user]);
                onClose();
              }}
            >
              <UserAvatar className="mr-4" user={user} /> {user.username}
            </MenuItem>
          ))
        ) : (
          <MenuItem className="empty-state text-sm">
            {t('MemberSelect.allAdded')}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
