import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HtmlDiffer } from 'html-differ';

export default function TestActionDiffs({ actionDiffs }) {
  const { t } = useTranslation();

  const checkDiff = useCallback(
    (property, { renderObject } = {}) => {
      if (
        JSON.stringify(JSON.parse(actionDiffs[0].valueJSON)[property]) !==
        JSON.stringify(JSON.parse(actionDiffs[1].valueJSON)[property])
      ) {
        let before = JSON.parse(actionDiffs[0].valueJSON)[property];
        let after = JSON.parse(actionDiffs[1].valueJSON)[property];

        if (typeof before === 'object') {
          before = renderObject(before);
        }
        if (typeof after === 'object') {
          after = renderObject(after);
        }

        const htmlDiffer = new HtmlDiffer({ ignoreWhitespaces: false });

        const hDiff = htmlDiffer.diffHtml(before, after);

        const b = hDiff
          .filter((d) => !d.added)
          .reduce((acc, d) => {
            return `${acc}<span${d.removed ? ` class="removed-diff"` : ''}>${
              d.value
            }</span>`;
          }, ``);

        const a = hDiff
          .filter((d) => !d.removed)
          .reduce((acc, d) => {
            return `${acc}<span${d.added ? ` class="added-diff"` : ''}>${
              d.value
            }</span>`;
          }, ``);

        return {
          before: b,
          after: a,
        };
      }
    },
    [actionDiffs],
  );

  const DiffItem = useCallback(({ title, item, className, ...rest }) => {
    return (
      <div
        className={clsx('bg-background-default px-4 py-2 flex-1', className)}
        {...rest}
      >
        <p className="font-bold opacity-50 mb-2">{title}</p>
        <p dangerouslySetInnerHTML={{ __html: item }} />
      </div>
    );
  }, []);

  const Diff = useCallback(
    ({ title, property, renderObject }) => {
      const diff = checkDiff(property, { renderObject });
      if (!diff) {
        return null;
      }

      return (
        <div data-cy="diff" className="mb-6 last:mb-0">
          <p className="font-bold mb-2">{title}</p>

          <div className="flex">
            <DiffItem
              data-cy="diff-before"
              className="mr-4"
              title={t('TestActionDiffs.before')}
              item={diff.before}
            />
            <DiffItem
              data-cy="diff-after"
              className="ml-4"
              title={t('TestActionDiffs.after')}
              item={diff.after}
            />
          </div>
        </div>
      );
    },
    [checkDiff, t],
  );

  if (actionDiffs.length !== 2) {
    return null;
  }

  return (
    <div>
      <Diff title={t('TestActionDiffs.title')} property="title" />
      <Diff title={t('TestActionDiffs.description')} property="description" />
      <Diff
        title={t('TestActionDiffs.steps')}
        property="steps"
        renderObject={(stepsArr) => {
          return stepsArr.length
            ? stepsArr
                .sort((a, b) => a.position < b.position)
                .reduce((acc, step) => {
                  return `${acc}<li>${step.title}</li>`;
                }, '')
            : 'Aucune étape';
        }}
      />
    </div>
  );
}
