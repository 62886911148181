import clsx from 'clsx';

export default function DialogTitle({
  className = null,
  subtitle,
  children,
  ...rest
}) {
  return (
    <div className={clsx('px-12 pt-8', className)} {...rest}>
      <h1 className="mb-1">{children}</h1>

      {subtitle ? <p className="caption">{subtitle}</p> : null}
    </div>
  );
}
