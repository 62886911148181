import { useState } from 'react';

const ALL_STATUS = ['FAILED', 'PASSED', 'NOT_TESTED'];

export default function useStatusFilters() {
  const [checkedStatus, setCheckedStatus] = useState(ALL_STATUS);

  const handleStatusChange = (event) => {
    const { name, checked } = event.currentTarget;
    if (checked) {
      setCheckedStatus((prev) => [...prev, name]);
    } else {
      setCheckedStatus((prev) => prev.filter((status) => status !== name));
    }
  };

  return { checkedStatus, onStatusChange: handleStatusChange };
}
