import { useTranslation } from 'react-i18next';
import DialogTitle from '../System/DialogTitle';
import DialogContent from '../System/DialogContent';
import useFolders from './useFolders';
import FolderItem from './FolderItem';
import DialogActions from '../System/DialogActions';
import { useMemo, useState } from 'react';

export default function MoveTestsContent({
  testCount,
  onCancel,
  confirmLoading,
  onConfirm,
}) {
  const { t } = useTranslation();

  const { loading, folders, rawFolders } = useFolders();

  const [selected, setSelected] = useState();

  const selectedName = useMemo(() => {
    return selected && rawFolders.find((f) => f.id === selected)?.name;
  }, [selected, rawFolders]);

  return (
    <>
      <DialogTitle>{t('MoveTests.title')}</DialogTitle>
      <DialogContent>
        <p className="mb-4">{t('MoveTests.where', { count: testCount })}</p>

        {!loading ? (
          <ul className="bg-background-default">
            {folders.map((folder) => (
              <FolderItem
                classes={{
                  item: 'hover:bg-primary-light',
                  selected: 'bg-primary-main text-primary-contrast-text',
                }}
                key={folder.id}
                folder={folder}
                onClick={setSelected}
                selected={(folderId) => folderId === selected}
                noActions
              />
            ))}
          </ul>
        ) : null}
      </DialogContent>
      <DialogActions
        loading={confirmLoading}
        confirmDisabled={!selected}
        confirmText={t('MoveTests.confirmText', {
          folderName: selectedName ? `"${selectedName}"` : '...',
        })}
        onCancel={onCancel}
        onConfirm={() => onConfirm({ folderId: selected })}
      />
    </>
  );
}
