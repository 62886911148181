import { gql } from '@apollo/client';

const InviteFragment = gql`
  fragment InviteFragment on Invite {
    id
    username
  }
`;

export default InviteFragment;
