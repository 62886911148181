import { Form, Formik } from 'formik';
import TextField from '../System/TextField';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import { gql, useMutation } from '@apollo/client';
import { useLocation, useParams } from 'react-router';
import { useMemo } from 'react';
import PasswordTextField from '../System/PasswordTextField';
import useSignIn from '../SignIn/useSignIn';
import useSnackbar from '../System/useSnackbar';

const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $email: String!, $password: String!) {
    resetPassword(token: $token, email: $email, password: $password)
  }
`;

export default function ResetPasswordForm() {
  const { t } = useTranslation();

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const { token } = useParams();

  const { onSubmit: onSignIn } = useSignIn();

  const { showSnackbar } = useSnackbar();

  const handleSubmit = async ({ email, password }) => {
    await resetPassword({
      variables: { token, email, password },
    });

    await onSignIn({ email, password });

    showSnackbar(t('ResetPasswordForm.success'), 'success');
  };

  const { search } = useLocation();
  const emailUrlParam = useMemo(() => {
    return decodeURIComponent(new URLSearchParams(search).get('email'));
  }, [search]);

  return (
    <Formik
      initialValues={{ email: emailUrlParam, password: '' }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t('Global.invalidEmail'))
          .required(t('Global.requiredField')),
        password: Yup.string()
          .min(6, t('Global.passwordMin6'))
          .required(t('Global.requiredField')),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <p className="mb-6">{t('ResetPasswordForm.subtitle')}</p>

          <div>
            <TextField
              label={t('ResetPasswordForm.emailLabel')}
              name="email"
              type="email"
            />
          </div>

          <div>
            <PasswordTextField
              autoComplete="new-password"
              label={t('ResetPasswordForm.passwordLabel')}
              name="password"
            />
          </div>

          <div className="w-full flex">
            <Button
              loading={isSubmitting}
              className="flex-1"
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('ResetPasswordForm.submit')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
