import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import CreateTestButton from '../CreateTestButton';
import EmptyTestList from '../EmptyTestList';
import MoveTests from '../MoveTests';
import TestsList from '../TestsList';

export default function TestsSection({
  loading,
  rawTests,
  tests,
  checkedTests,
  onCheck,
  onCheckAll,
  onTestOrderChange,
}) {
  const { t } = useTranslation();

  return loading ? null : (
    <section className="overflow-auto flex-1 flex">
      {rawTests.length ? (
        <div className="flex-1">
          <div>
            <div>
              <div className="flex items-center">
                <div className="md:ml-4 mr-4 md:mt-4">
                  <CreateTestButton />
                </div>

                <MoveTests
                  className={clsx(
                    'mt-4',
                    checkedTests?.length ? 'hidden xl:block' : 'hidden',
                  )}
                  tests={checkedTests}
                />
              </div>

              {tests.length ? (
                <div
                  className={
                    'bg-background-paper border border-divider rounded-sm mx-4 mt-4 py-2 px-4'
                  }
                >
                  <div
                    className={clsx(
                      'pl-8 pr-4 mr-4',
                      checkedTests.length
                        ? 'border-opacity-1'
                        : 'border-opacity-0',
                    )}
                  >
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="cursor-pointer rounded-sm w-5 h-5 border border-gray-400 ml-2 mx-4"
                        checked={
                          tests.length && tests.length === checkedTests.length
                        }
                        onChange={onCheckAll}
                      />
                      <span className={clsx('text-sm opacity-75')}>
                        {t('TestsSection.selected', {
                          count: checkedTests.length,
                        })}
                      </span>
                    </label>
                  </div>
                </div>
              ) : null}
            </div>

            <TestsList
              tests={tests}
              checkedTests={checkedTests}
              unfilteredTests={rawTests}
              onCheckAll={onCheckAll}
              onCheck={onCheck}
              onTestOrderChange={onTestOrderChange}
            />
          </div>
        </div>
      ) : (
        <EmptyTestList />
      )}
    </section>
  );
}
