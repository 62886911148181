import { gql, useMutation } from '@apollo/client';
import { useStripe } from '@stripe/react-stripe-js';
import useCurrentLanguage from '../Language/useCurrentLanguage';

const STRIPE_CREATE_CHECKOUT_SESSION = gql`
  mutation StripeCreateCheckoutSession($priceId: String!, $lang: LANG) {
    sessionId: stripeCreateCheckoutSession(priceId: $priceId, lang: $lang)
  }
`;

export default function useSubscribe() {
  const currentLanguage = useCurrentLanguage();

  const [createSession] = useMutation(STRIPE_CREATE_CHECKOUT_SESSION, {});

  const stripe = useStripe();

  const goToSubscription = async ({ priceId }) => {
    const { data } = await createSession({
      variables: {
        priceId,
        lang: currentLanguage.toUpperCase(),
      },
    });
    await stripe.redirectToCheckout({
      sessionId: data.sessionId,
    });
  };

  return { goToSubscription };
}
