import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '../Icons/Edit';
import Button from '../System/Button';
import DeleteFolder from './DeleteFolder';
import RenameFolder from './RenameFolder';

export default function FolderItem({
  className,
  classes = {},
  to,
  folder,
  editMode = false,
  selected = () => {},
  endIcon,
  onClick,
}) {
  const { t } = useTranslation();

  const [renameMode, setRenameMode] = useState(false);

  const Component = useMemo(() => (to ? Link : (props) => <div {...props} />), [
    to,
  ]);

  const handleClick = () => {
    if (onClick) {
      onClick(folder.id);
    }
  };

  let isSelected = false;
  if (selected(folder.id)) {
    isSelected = true;
  }

  return (
    <>
      <li className={className}>
        <Component
          className={clsx(
            'group flex-1 no-underline pr-4 flex items-center',
            !isSelected && classes.item,
            isSelected && classes.selected,
          )}
          to={to ? to(folder) : null}
          onClick={handleClick}
        >
          <RenameFolder
            editMode={renameMode}
            folder={folder}
            onBlur={() => {
              setRenameMode(false);
            }}
          />

          {editMode ? (
            <>
              <Button
                title={t('FolderItem.rename')}
                noTransition
                noPadding
                noBg
                className="fill-current w-5 h-5 ml-2 opacity-80 flex-shrink-0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setRenameMode(true);
                }}
              >
                <EditIcon />
              </Button>

              <DeleteFolder className="flex-shrink-0" folder={folder} />
            </>
          ) : (
            <span className="w-6 h-6 fill-current">{endIcon}</span>
          )}
        </Component>
      </li>
    </>
  );
}
