export const getNextMediaId = ({ currentMediaId, mediaList = [] }) => {
  if (!mediaList.length) {
    return null;
  }

  const currentIndex =
    typeof currentMediaId !== undefined &&
    mediaList.findIndex((m) => m.id === currentMediaId);

  const nextIndex =
    typeof currentIndex !== undefined && currentIndex >= mediaList.length - 1
      ? 0
      : currentIndex + 1;
  return mediaList[nextIndex].id;
};

export const getPreviousMediaId = ({ currentMediaId, mediaList = [] }) => {
  if (!mediaList.length) {
    return null;
  }

  const currentIndex =
    currentMediaId && mediaList.findIndex((m) => m.id === currentMediaId);

  const previousIndex =
    typeof currentIndex !== undefined && currentIndex <= 0
      ? mediaList.length - 1
      : currentIndex - 1;
  return mediaList[previousIndex].id;
};
