import { gql, useMutation } from '@apollo/client';
import useCurrentUser from '../CurrentUser/useCurrentUser';

const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      idToken
    }
  }
`;

export default function useSignIn() {
  const [signIn, { error }] = useMutation(SIGN_IN);

  const { setCurrentUserFromToken } = useCurrentUser();

  const handleSubmit = async (values) => {
    const { data } = await signIn({
      variables: { email: values.email, password: values.password },
    });

    setCurrentUserFromToken({ idToken: data.signIn.idToken });
  };

  return { error, onSubmit: handleSubmit };
}
