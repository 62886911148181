import { useField } from 'formik';
import ReactQuill, { Quill } from 'react-quill';
import AutoLinks from './AutoLinksPlugin';
import UploadMediaField from '../../Media/UploadMediaField';

import { useTranslation } from 'react-i18next';
import MediaGrid from '../../Media/MediaGrid';
import clsx from 'clsx';
import Button from '../Button';
import RadioButtonCheck from '../../Icons/RadioButtonCheck';
import Capture from '../../Capture/Capture';
import useOpen from '../useOpen';
import { useEffect, useState } from 'react';
import getVideoThumbnail from '../get-video-thumbnail';
import shortid from 'shortid';
import getLinksFromText from './libs/get-links-from-text';
import filesToContents from '../libs/files-to-contents';

//////////////////////////////////////////
let Inline = Quill.import('blots/inline');
class LinkBlot extends Inline {
  static create(value) {
    let node = super.create();
    // Sanitize url value if desired
    node.setAttribute('href', value);
    // Okay to set other non-format related attributes
    // These are invisible to Parchment so must be static
    node.setAttribute('target', '_blank');
    node.setAttribute('title', value);

    return node;
  }

  static formats(node) {
    // We will only be called with a node already
    // determined to be a Link blot, so we do
    // not need to check ourselves
    return node.getAttribute('href');
  }
}
LinkBlot.blotName = 'link';
LinkBlot.tagName = 'a';

Quill.register(LinkBlot);

//////////////////////////////////////////

Quill.register('modules/autoLinks', AutoLinks);

const MODULES = {
  keyboard: {
    bindings: {
      enter: {
        key: 13,
        collapsed: true,
        handler: function (range, context) {
          const links = getLinksFromText(context.prefix);

          for (const link of links) {
            const { url } = link;
            const retain = range.index - context.prefix.length + link.index;

            const ops = retain ? [{ retain }] : [];
            ops.push(
              { delete: range.index - retain },
              { insert: url, attributes: { link: url } },
              { insert: context.prefix.slice(link.index + url.length) },
            );

            this.quill.updateContents({ ops });
          }

          return true;
        },
      },
    },
  },
  toolbar: {
    container: '#ql-toolbar',
  },
  autoLinks: true,
};

const FORMATS = ['bold', 'italic', 'strike', 'list', 'bullet', 'link'];

const CustomToolbar = ({ hideMedia, position = 'top', onMediaChange }) => {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();
  const [delayedOpen, setDelayedOpen] = useState(open);
  useEffect(() => {
    if (open) {
      setDelayedOpen(open);
    } else {
      setTimeout(() => setDelayedOpen(open), 150);
    }
  }, [open]);

  return (
    <div id="ql-toolbar">
      <div
        className={clsx(
          'flex border-l border-r border-divider',
          position === 'bottom'
            ? 'border-b rounded-bl rounded-br'
            : 'border-t rounded-tl rounded-tr',
        )}
      >
        <div className="flex items-center px-2">
          <span className="ql-formats">
            <button className="ql-bold my-1"></button>
            <button className="ql-italic my-1"></button>
          </span>

          <span className="ql-formats">
            <button className="ql-list my-1" value="ordered"></button>
            <button className="ql-list my-1" value="bullet"></button>
          </span>
        </div>

        {hideMedia ? null : (
          <div className="ml-auto bg-background-default px-4 py-2 border-left border-divider flex items-center">
            <UploadMediaField
              classes={{ button: 'text-sm bg-background-paper' }}
              name="mediaList"
              multiple
              uploadLabel={t('Editor.uploadLabel')}
              onChange={onMediaChange}
            />

            <div className="ml-4">
              <Button
                startIcon={<RadioButtonCheck />}
                variant="outlined"
                id="record-btn"
                className=" flex-shrink-0"
                onClick={onOpen}
              >
                {t('Editor.screenRecording')}
              </Button>

              {delayedOpen ? (
                <Capture
                  open={open}
                  onClose={onClose}
                  onSave={async (file) => {
                    const preview = await getVideoThumbnail({ file });
                    const media = {
                      file,
                      preview,
                      label: file.name,
                      id: shortid.generate(),
                      description: '',
                    };
                    onMediaChange([media]);
                    onClose();
                  }}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function Editor({
  className,
  name,
  mediaListName,
  EditorProps = {},
  hideMedia = false,
  ...rest
}) {
  const [{ value }, , { setValue }] = useField(name);
  const [{ value: mediaList }, , { setValue: setMediaList }] = useField(
    mediaListName,
  );

  // TODO: closure issue
  const handleMediaChange = (newContents) => {
    setMediaList([...mediaList, ...newContents]);
  };

  const handleMediaDelete = (media) => {
    setMediaList(mediaList.filter((m) => m.id !== media.id));
  };

  useEffect(() => {
    const handlePaste = async (event) => {
      const { items } = event.clipboardData;
      for (const item of items) {
        // https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem
        const { kind } = item;
        if (kind === 'file') {
          const content = {
            ...(await filesToContents([item.getAsFile()]))[0],
            id: shortid.generate(),
            description: '',
          };
          handleMediaChange([content]);
        }
      }
    };
    if (!hideMedia) {
      document.addEventListener('paste', handlePaste);

      return () => {
        document.removeEventListener('paste', handlePaste);
      };
    }
  });

  return (
    <div className={clsx(className)} {...rest}>
      <CustomToolbar hideMedia={hideMedia} onMediaChange={handleMediaChange} />

      <ReactQuill
        theme="snow"
        modules={MODULES}
        formats={FORMATS}
        value={value}
        onChange={setValue}
        {...EditorProps}
      />

      {!hideMedia && mediaList.length ? (
        <MediaGrid
          className="my-4"
          mediaList={mediaList}
          onChange={setMediaList}
          onDelete={handleMediaDelete}
        />
      ) : null}
    </div>
  );
}
