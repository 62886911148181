import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export default function TestHeader({ className, test }) {
  const { t } = useTranslation();

  return (
    <div className={clsx(className)}>
      <h2>{test.title}</h2>

      <p
        className="mt-4 whitespace-pre-wrap"
        dangerouslySetInnerHTML={
          test.description
            ? {
                __html: test.description,
              }
            : null
        }
      >
        {!test.description ? (
          <span className="empty-state">
            {t('TestHeader.emptyDescription')}
          </span>
        ) : null}
      </p>
    </div>
  );
}
