import { gql, useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import { MediaUploadContext } from './MediaUploadProvider';

const GET_UPLOAD_SIGNED_URL = gql`
  mutation GetUploadSignedUrl(
    $filename: String!
    $contentType: String!
    $context: MEDIA_CONTEXT
  ) {
    getUploadSignedUrl(
      filename: $filename
      contentType: $contentType
      context: $context
    ) {
      url
      fields
    }
  }
`;

export default function useMediaUpload() {
  const [
    signUrl,
    { loading: getSignedUrlLoading, error: getSignedUrlError },
  ] = useMutation(GET_UPLOAD_SIGNED_URL);

  const getSignedUrl = useCallback(
    async ({ file, context }) => {
      const {
        data: { getUploadSignedUrl },
      } = await signUrl({
        variables: { filename: file.name, contentType: file.type, context },
      });
      return getUploadSignedUrl;
    },
    [signUrl],
  );

  const uploadContext = useContext(MediaUploadContext);
  const upload = uploadContext?.upload;

  return {
    upload,
    getSignedUrl,
    getSignedUrlLoading,
    getSignedUrlError,
  };
}
