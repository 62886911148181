import { currentUserVar } from '../../apollo';
import { useReactiveVar } from '@apollo/client';
import jwt from 'jsonwebtoken';

const setCurrentUser = (user) => {
  currentUserVar(user);
};

export const setCurrentUserFromToken = ({ idToken }) => {
  if (idToken) {
    // We set a localstorage item to know if we should refresh token on page load

    const payload = jwt.decode(idToken);
    localStorage.setItem('kt_r', new Date(payload.exp * 1000).valueOf());

    setCurrentUser({ idToken, ...payload });
  } else {
    setCurrentUser({});
  }
};

export default function useCurrentUser() {
  const currentUser = useReactiveVar(currentUserVar);

  return { currentUser, setCurrentUser, setCurrentUserFromToken };
}
