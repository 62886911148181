import { useMemo } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import useSnackbar from '../System/useSnackbar';
import useOrder from '../System/useOrder';

export const POSITION_STEP = 65536;

const GET_TESTS = gql`
  query GetTests($folderId: String!) {
    getTests(folderId: $folderId) {
      id
      title
      position
      archived
      status
      createdAt
    }
  }
`;

const UPDATE_TEST_POSITION = gql`
  mutation UpdateTestPosition($test: UpdateTestInput!) {
    updateTest(test: $test) {
      id
      title
      description
      position
      folderId
    }
  }
`;

const REORDER_TESTS = gql`
  mutation ReorderTests($folderId: String!) {
    reorderTests(folderId: $folderId) {
      id
      title
      position
      status
      createdAt
    }
  }
`;

const useTests = ({ statusFilter, archivedFilter }) => {
  const { t } = useTranslation();

  const { folderId } = useParams();

  const { loading, data, error } = useQuery(GET_TESTS, {
    variables: { folderId },
    skip: !folderId,
  });

  const { showSnackbar } = useSnackbar();
  const [updateTestPosition] = useMutation(UPDATE_TEST_POSITION);
  const [reorderTests] = useMutation(REORDER_TESTS);

  const handleOrderChange = async ({ changes }) => {
    for (const { object, position, diff } of changes) {
      await updateTestPosition({
        variables: {
          test: {
            testId: object.id,
            position,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateTest: {
            id: object.id,
            position,
            __typename: 'Test',
          },
        },
        onError() {
          showSnackbar(t('Global.serverError'), 'error');
        },
      });

      // Max DB value for position => 2147483647 (PostgreSQL integer)
      if (diff < 100 || position < 500 || position > 1000000000) {
        reorderTests({
          variables: { folderId },
        });
      }
    }
  };

  const sortedTests = useMemo(() => {
    if (data?.getTests) {
      const sorted = [...data.getTests]
        .filter((test) => {
          const filteredFromArchived = archivedFilter ? true : !test.archived;
          return filteredFromArchived && statusFilter.indexOf(test.status) > -1;
        })
        .sort((a, b) => {
          return a.position < b.position ? -1 : 1;
        });
      return sorted;
    }
    return null;
  }, [data, statusFilter, archivedFilter]);

  const { onOrder } = useOrder({
    objects: sortedTests,
    positionStep: POSITION_STEP,
    onOrderChange: handleOrderChange,
  });

  return {
    loading,
    error,
    rawTests: data?.getTests,
    tests: sortedTests,
    onTestOrderChange: onOrder,
  };
};

export { GET_TESTS };
export default useTests;
