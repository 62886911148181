import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import clsx from 'clsx';
import Editor from '../System/Editor/Editor';

export default function CreateIssueCommentForm({
  className,
  children,
  classes = {},
  placeholder,
  onSubmit,
}) {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        description: '',
        mediaList: [],
      }}
      validationSchema={Yup.object({
        description: Yup.string(),
      })}
      onSubmit={onSubmit}
    >
      {(...renderProps) => {
        return (
          <Form
            className={clsx('flex-1 overflow-auto flex flex-col', className)}
            noValidate
          >
            <Editor
              data-cy="issue-editor"
              className={clsx('mb-2', classes.editor)}
              name="description"
              mediaListName="mediaList"
              EditorProps={{
                placeholder:
                  placeholder || t('CreateIssueCommentForm.descriptionLabel'),
              }}
            />

            {children ? children(...renderProps) : null}
          </Form>
        );
      }}
    </Formik>
  );
}
