import clsx from 'clsx';

export default function Card({ className, ...rest }) {
  return (
    <div
      className={clsx(
        'bg-background-paper px-4 md:px-12 py-8 rounded shadow-xl',
        className,
      )}
      {...rest}
    />
  );
}
