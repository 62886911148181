import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../System/Button';
import PasswordTextField from '../System/PasswordTextField';
import TextField from '../System/TextField';
import useSignUp from './useSignUp';
import Error from '../System/Error';
import useCurrentLanguage from '../Language/useCurrentLanguage';
import Checkbox from '../System/Checkbox';

// i18next parser
//
// t('SignUpForm.termsAccept');

export default function SignUpForm({ join }) {
  const { t } = useTranslation();

  const { error, onSubmit } = useSignUp({ joinWorkspaceId: join });

  const lang = useCurrentLanguage();

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          termsAccept: false,
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t('SignUpForm.invalidEmail'))
            .required(t('Global.requiredField')),
          password: Yup.string()
            .min(6, t('Global.passwordMin6'))
            .required(t('Global.requiredField')),
          termsAccept: Yup.bool().oneOf(
            [true],
            t('SignUpForm.termsAcceptRequired'),
          ),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, getFieldMeta }) => {
          console.log(errors);
          return (
            <Form noValidate>
              <TextField
                autoComplete="username"
                label={t('SignUpForm.emailLabel')}
                name="email"
                type="email"
              />

              <PasswordTextField
                autoComplete="new-password"
                label={t('SignUpForm.passwordLabel')}
                name="password"
              />

              <div className="mt-8 mb-4">
                <Checkbox
                  hideError
                  name="termsAccept"
                  label={
                    <span>
                      <Trans
                        i18nKey="SignUpForm.termsAccept"
                        components={{
                          a: (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <a
                              href={`${process.env.REACT_APP_WWW_URL}/${lang}/terms`}
                              target="_blank"
                              rel="noopener noreferrer"
                            />
                          ),
                        }}
                      />
                    </span>
                  }
                />
              </div>

              {errors.termsAccept && getFieldMeta('termsAccept').touched ? (
                <Error className="mb-2">{errors.termsAccept}</Error>
              ) : null}

              {error ? (
                <Error>
                  {error.message === 'email_already_exists'
                    ? t('SignUpForm.error.email_already_exists')
                    : t('SignUpForm.error.global')}
                </Error>
              ) : null}

              <div>
                <Button
                  className="mt-2"
                  loading={isSubmitting}
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  {t('SignUpForm.SignUpButton')}
                </Button>
              </div>

              <div className="mt-8 text-sm flex justify-end">
                <p className="text-right">
                  {t('SignUpForm.haveAccount')}{' '}
                  <Link to="/sign-in">{t('SignUpForm.signin')}</Link>
                </p>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
