import Button from './Button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import filesToContents from './libs/files-to-contents';

export default function UploadButton({
  className,
  label,
  startIcon,
  accept,
  multiple,
  onChange,
  ...rest
}) {
  const { t } = useTranslation();

  const handleChange = async (event) => {
    if (onChange) {
      const files = [...event.currentTarget.files];
      const contents = await filesToContents(files);
      onChange({ contents });
    }
  };

  return (
    <div className={clsx('flex items-center', className)}>
      <input
        className="hidden"
        type="file"
        id="upload-file-input"
        multiple={multiple}
        accept={accept}
        onChange={handleChange}
      />
      <label htmlFor="upload-file-input">
        <Button
          component="span"
          variant="outlined"
          className="bg-background-paper px-4 py-2 flex-shrink-0"
          noPadding
          startIcon={startIcon}
          {...rest}
        >
          {label || t('UploadButton.upload')}
        </Button>
      </label>
    </div>
  );
}
