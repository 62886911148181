import { useMemo, useState } from 'react';
import useCurrentUser from '../CurrentUser/useCurrentUser';

export default function useUpdateScheduledTests({ testScheduledTests }) {
  const { currentUser } = useCurrentUser();

  const assignedScheduledTests = useMemo(() => {
    return (
      testScheduledTests.filter(
        (t) =>
          !t.scheduledTestPassedResult &&
          !t.scheduledTestIssueResult &&
          t.user?.id === currentUser.id,
      ) || []
    );
  }, [testScheduledTests, currentUser.id]);

  const [checkedScheduledTests, setCheckedScheduledTests] = useState(
    assignedScheduledTests || [],
  );

  return {
    assignedScheduledTests,
    checkedScheduledTests,
    setCheckedScheduledTests,
  };
}
