import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import Gallery from '../Media/Gallery';
import UserAvatar from '../System/UserAvatar';

export default function Timeline({ className, items = [] }) {
  const { t } = useTranslation();

  return (
    <div className={clsx('relative', className)} data-cy="timeline">
      <div className="absolute top-0 bottom-0 left-4 w-0.5 bg-divider z-0" />
      <div data-cy="timeline-items">
        {items.map((item) => {
          if (item.type === 'comment') {
            const comment = item;
            return (
              <section
                key={comment.id}
                className={clsx(
                  comment.className ||
                    'border bg-background-paper border-divider rounded-lg px-6 py-4 relative z-20 mb-4',
                )}
              >
                {comment.createdAt && comment.user?.username ? (
                  <div className="flex items-center mb-2">
                    <UserAvatar
                      user={comment.user}
                      size="small"
                      className="mr-2"
                    />
                    <p className="caption">
                      {t('Global.createdAtSimple', {
                        datetime: DateTime.fromMillis(
                          Number(comment.createdAt),
                        ).toLocaleString(DateTime.DATETIME_MED),
                        author: comment.user.username,
                      })}
                    </p>
                  </div>
                ) : null}

                <p
                  className="whitespace-pre-wrap"
                  dangerouslySetInnerHTML={
                    comment.html
                      ? {
                          __html: comment.html,
                        }
                      : null
                  }
                >
                  {comment.html
                    ? null
                    : comment.comment || (
                        <span className="empty-state">
                          {t('Timeline.noComments')}
                        </span>
                      )}
                </p>

                {comment.mediaList.length ? (
                  <Gallery className="mt-4" mediaList={comment.mediaList} />
                ) : null}
              </section>
            );
          } else if (item.type === 'action') {
            const action = item;
            return (
              <div
                key={action.id}
                className="flex items-center caption italic mb-4 last:mb-0"
              >
                <div className=" ml-4 w-6 h-1 bg-divider" />
                <div className="w-4 h-4 rounded-full flex-shrink-0 border-2 border-primary-main border-opacity-70 mr-2" />

                <div>
                  <p>
                    {DateTime.fromMillis(
                      Number(action.createdAt),
                    ).toLocaleString(DateTime.DATETIME_MED)}{' '}
                    - {action.text}
                  </p>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}
