import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import { gql, useMutation } from '@apollo/client';
import IssueActionFragment from '../../fragments/IssueCommentFragment';
import Dialog from '../System/Dialog';
import DialogTitle from '../System/DialogTitle';
import DialogContent from '../System/DialogContent';
import useOpen from '../System/useOpen';
import DialogActions from '../System/DialogActions';
import { useState } from 'react';
import { CREATE_TEST_PASSED } from './CreateTestPassedButton';

const CLOSE_ISSUE = gql`
  mutation UpdateIssue($issueId: String!, $status: ISSUE_STATUS!) {
    updateIssue(issueId: $issueId, status: $status) {
      id
      status
      test {
        id
        title
        position
        status
        createdAt
      }
      actions {
        ...IssueActionFragment
      }
    }
  }

  ${IssueActionFragment}
`;

export default function CloseIssueButton({
  withComment = false,
  testId,
  issueId,
  onBeforeClosed,
}) {
  const { t } = useTranslation();

  const [markPassed, setMarkPassed] = useState(false);
  const [passTest, { loading: passTestLoading }] = useMutation(
    CREATE_TEST_PASSED,
    {
      refetchQueries: ['GetTest', 'GetTests'],
      variables: {
        testId,
      },
    },
  );

  const [closeIssue, { loading }] = useMutation(CLOSE_ISSUE, {
    variables: {
      issueId,
      status: 'CLOSE',
    },
  });

  const handleClose = async () => {
    if (onBeforeClosed) {
      await onBeforeClosed();
    }

    if (markPassed) {
      await passTest();
    }

    await closeIssue();
  };

  const { open, onOpen, onClose } = useOpen();

  return (
    <>
      <Button
        size="small"
        className="text-red-500 border border-red-200"
        color="default"
        onClick={onOpen}
      >
        {withComment
          ? t('CloseIssueButton.closeIssueWithComment')
          : t('CloseIssueButton.closeIssue')}
      </Button>

      <Dialog
        data-cy="close-issue-confirm-dialog"
        open={open}
        onClose={() => {
          onClose();
          setMarkPassed(false);
        }}
      >
        <DialogTitle>{t('CloseIssueButton.confirmTitle')}</DialogTitle>

        <DialogContent>
          <p className="mb-4">{t('CloseIssueButton.confirm')}</p>

          <div>
            <label className="flex items-center caption">
              <input
                type="checkbox"
                name="PASSED"
                className="cursor-pointer rounded-sm w-4 h-4 focus:ring-2 focus:ring-green-500 border border-gray-500 mr-2 text-green-500"
                checked={markPassed}
                onChange={(e) => setMarkPassed(e.target.checked)}
              />
              {t('CloseIssueButton.markPassed')}
            </label>
          </div>
        </DialogContent>

        <DialogActions
          loading={loading || passTestLoading}
          onCancel={() => {
            onClose();
            setMarkPassed(false);
          }}
          onConfirm={handleClose}
        />
      </Dialog>
    </>
  );
}
