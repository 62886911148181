import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export default function init() {
  Sentry.init({
    // TODO: uncomment before going to production
    // enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
