import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import Button from '../../System/Button';
import SuccessIcon from '../../Icons/Success';
import ErrorIcon from '../../Icons/Error';

export default function useTestTimeline({ test, onDiffOpen }) {
  const { t } = useTranslation();

  const timelineItems = useMemo(() => {
    return test
      ? [
          ...test.actions
            .filter((action) => {
              // Remove position updates
              const valueJSON = action.valueJSON
                ? JSON.parse(action.valueJSON)
                : {};

              if (
                action.action === 'UPDATE' &&
                Object.keys(valueJSON).length === 1 &&
                Object.keys(valueJSON)[0] === 'position'
              ) {
                return false;
              }
              return true;
            })
            .map((action, index) => {
              const valueJSON = action.valueJSON
                ? JSON.parse(action.valueJSON)
                : null;

              let diffs = {};
              if (action.action === 'UPDATE' && index > 0) {
                const prevValueJSON = test.actions[index - 1].valueJSON
                  ? JSON.parse(test.actions[index - 1].valueJSON)
                  : null;

                if (prevValueJSON) {
                  for (const [key, value] of Object.entries(valueJSON)) {
                    if (
                      JSON.stringify(value) !==
                      JSON.stringify(prevValueJSON[key])
                    ) {
                      diffs = { ...diffs, [key]: value };
                    }
                  }
                }
              }

              return {
                ...action,
                type: 'action',
                text: (
                  <>
                    {action.action === 'CREATE'
                      ? t('Test.actions.CREATE', action)
                      : action.action === 'UPDATE'
                      ? Object.keys(diffs).length === 1
                        ? diffs.title
                          ? t('Test.actions.UPDATE_TITLE', action)
                          : diffs.description
                          ? t('Test.actions.UPDATE_DESCRIPTION', action)
                          : diffs.steps
                          ? t('Test.actions.UPDATE_STEPS', action)
                          : ''
                        : t('Test.actions.UPDATE', action)
                      : ''}{' '}
                    {action.action === 'UPDATE' &&
                    Object.keys(valueJSON).length ? (
                      <Button
                        className="underline italic"
                        noBg
                        noPadding
                        noShadow
                        onClick={() => onDiffOpen(action.id)}
                      >
                        {t('Test.getActionDetail')}
                      </Button>
                    ) : null}
                  </>
                ),
              };
            }),
          ...test.passes.map((passedTest) => {
            return {
              type: 'comment',
              createdAt: passedTest.createdAt,
              id: passedTest.id,
              comment: (
                <span className="flex items-center">
                  <SuccessIcon className="w-5 h-5 fill-current flex-shrink-0 text-green-600 mr-2" />
                  <span className="text-sm">
                    {t('Test.passedComment', {
                      username: passedTest.user.username,
                      datetime: DateTime.fromMillis(
                        Number(passedTest.createdAt),
                      ).toLocaleString(DateTime.DATETIME_MED),
                    })}
                  </span>
                </span>
              ),
              mediaList: [],
              className: clsx(
                'px-8 py-3 relative z-20 mt-6 mb-6',
                'rounded-tl rounded-bl rounded-tr-lg rounded-br-lg',
                'text-gray-700',
                'bg-background-default border border-divider',
              ),
            };
          }),
          ...test.issues.map((issue) => {
            return {
              type: 'comment',
              createdAt: issue.createdAt,
              id: issue.id,
              comment: (
                <span className="flex items-center">
                  <ErrorIcon className="w-5 h-5 fill-current flex-shrink-0 text-yellow-600 mr-2" />
                  <span>
                    <span className="flex items-center">
                      <span
                        className={clsx(
                          'text-sm',
                          issue.status === 'CLOSE' && 'line-through',
                        )}
                      >
                        {t('Test.issueComment', {
                          username: issue.user.username,
                          datetime: DateTime.fromMillis(
                            Number(issue.createdAt),
                          ).toLocaleString(DateTime.DATETIME_MED),
                        })}
                      </span>
                      <Link
                        to={`/issues/${issue.id}`}
                        className="underline italic ml-2 text-sm whitespace-nowrap"
                      >
                        {t('Test.commentSeeMore')}
                      </Link>
                    </span>
                    {issue.status === 'CLOSE' ? (
                      <p
                        data-cy="closedIssueChip"
                        className="text-sm mt-1 font-bold opacity-80"
                      >
                        {t('useTestTimeline.closedIssue')}
                      </p>
                    ) : null}
                  </span>
                </span>
              ),
              mediaList: [],
              className: clsx(
                'px-8 py-3 relative z-20 mt-6 mb-6',
                'border-l-4 rounded-tl rounded-bl rounded-tr-lg rounded-br-lg',
                'text-gray-700',
                'bg-background-default border border-divider',
              ),
            };
          }),
        ].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      : [];
  }, [test, t, onDiffOpen]);

  return { timelineItems };
}
