import useCurrentUser from '../CurrentUser/useCurrentUser';
import TrialBilling from './TrialBilling';
import WIPBilling from './WIPBilling';

export default function Billing() {
  const { currentUser } = useCurrentUser();

  return (
    <div>{currentUser.subscription ? <WIPBilling /> : <TrialBilling />}</div>
  );
}
