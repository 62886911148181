import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import useCurrentUser from '../CurrentUser/useCurrentUser';
import * as Sentry from '@sentry/react';

export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      idToken
    }
  }
`;

export default function useRefresh() {
  const [refreshToken, { loading, error }] = useMutation(REFRESH_TOKEN);

  const { setCurrentUserFromToken } = useCurrentUser();

  const apolloClient = useApolloClient();

  const handleRefresh = useCallback(async () => {
    const { data } = await refreshToken();
    if (data?.refreshToken.idToken) {
      setCurrentUserFromToken({ idToken: data.refreshToken.idToken });
      try {
        await apolloClient.cache.reset();
        await apolloClient.resetStore();
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  }, [apolloClient, refreshToken, setCurrentUserFromToken]);

  return { onRefresh: handleRefresh, loading, error };
}
