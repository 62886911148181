import Button from '../../System/Button';
import { useTranslation } from 'react-i18next';
import AddIcon from '../../Icons/Add';
import { useField } from 'formik';
import { POSITION_STEP } from '../../System/useSortable';
import { getInitialStep } from '../Test/TestDialog';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import useOrder from '../../System/useOrder';
import { useState } from 'react';
import StepItem from './StepItem';
import StepItemDraggable from './StepItemDraggable';

export default function Steps({ className }) {
  const { t } = useTranslation();

  const [{ value: steps }, , { setValue: setSteps }] = useField('steps');

  const handleInputChange = (e, stepId) => {
    const { value } = e.target;

    setSteps(
      steps.map((step) => {
        if (stepId === step.id) {
          return { ...step, title: value };
        }
        return step;
      }),
    );
  };

  const handleAdd = () => {
    if (steps[steps.length - 1].title) {
      setSteps([
        ...steps,
        {
          ...getInitialStep(),
          position: steps[steps.length - 1].position + POSITION_STEP,
        },
      ]);
    }
  };

  const handleDelete = (stepId) => {
    setSteps(steps.filter((step) => stepId !== step.id));
  };

  const handleOrderChange = ({ changes }) => {
    for (const { object, position, diff } of changes) {
      let newSteps = steps
        .map((step) => {
          if (step.id === object.id) {
            return { ...step, position };
          }
          return step;
        })
        .sort((a, b) => (a.position < b.position ? -1 : 1));

      // If diff too small, reorder all
      if (diff < 100 || position < 500 || position > 1000000000) {
        newSteps = newSteps.map((step, index) => ({
          ...step,
          position: POSITION_STEP * (index + 1),
        }));
      }

      setSteps(newSteps);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [activeId, setActiveId] = useState();

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  const { onOrder } = useOrder({
    objects: steps,
    positionStep: POSITION_STEP,
    onOrderChange: handleOrderChange,
  });

  function handleDragEnd(e) {
    if (e.active && e.over) {
      const oldIndex = steps.findIndex((s) => s.id === e.active.id);
      const newIndex = steps.findIndex((s) => s.id === e.over.id);
      onOrder({ oldIndex, newIndex });
    }

    setActiveId(null);
  }

  return (
    <div className={className}>
      <h2>{t('Steps.title')}</h2>

      <p className="mt-2 mb-4">{t('Steps.info')}</p>

      <ul className="grid grid-cols-1 gap-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={steps.map((s) => s.id)}
            strategy={verticalListSortingStrategy}
          >
            {steps.map((step) => (
              <StepItemDraggable
                key={step.id}
                invisible={activeId === step.id}
                step={step}
                steps={steps}
                onAdd={handleAdd}
                onDelete={handleDelete}
                onInputChange={handleInputChange}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <StepItem
                step={steps.find((s) => s.id === activeId)}
                steps={steps}
                onAdd={handleAdd}
                onInputChange={handleInputChange}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </ul>

      <div className="mt-4">
        <div>
          <Button
            disabled={!steps[steps.length - 1].title.length}
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            {t('Steps.AddButton')}
          </Button>
        </div>
      </div>
    </div>
  );
}
