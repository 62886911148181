import Card from '../components/System/Card';
import CreateWorkspaceForm from '../components/Workspace/CreateWorkspaceForm';
import { useTranslation } from 'react-i18next';
import MainLayout from '../components/Layout/MainLayout';
import useCurrentUser from '../components/CurrentUser/useCurrentUser';
import UpgradeImage from '../assets/undraw_upgrade_06a0.svg';
import Button from '../components/System/Button';
import { Link } from 'react-router-dom';

export default function CreateWorkspacePage() {
  const { t } = useTranslation();

  const { currentUser } = useCurrentUser();

  return (
    <MainLayout>
      <div className="flex-1 flex items-center justify-center">
        {currentUser.allowToCreateWorkspace ? (
          <Card className="w-full max-w-xl">
            <h1>{t('CreateWorkspacePage.title')}</h1>

            <p className="mb-4">{t('CreateWorkspacePage.info')}</p>

            <CreateWorkspaceForm />
          </Card>
        ) : (
          <div className="flex flex-col items-center">
            <div className="max-w-xl">
              <img src={UpgradeImage} alt="upgrade plan" />
            </div>

            <p className="mt-16 text-lg mb-8 max-w-3xl text-center">
              {t('CreateWorkspacePage.upgrade')}
            </p>

            <div>
              <Button
                component={Link}
                to="/billing"
                variant="contained"
                color="primary"
                className="no-underline"
              >
                {t('CreateWorkspacePage.upgradeButton')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}
