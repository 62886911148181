import Dialog from '../../System/Dialog';
import DialogTitle from '../../System/DialogTitle';
import DialogContent from '../../System/DialogContent';
import DialogActions from '../../System/DialogActions';
import TestForm from '../TestForm';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { POSITION_STEP } from '../../System/useSortable';
import shortid from 'shortid';

export const getInitialStep = () => {
  return { id: shortid.generate(), title: '', position: POSITION_STEP };
};

export default function TestDialog({
  error,
  open,
  test = {
    title: '',
    description: '',
    steps: [getInitialStep()],
  },
  onSubmit,
  onClose,
}) {
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="4xl" open={open} onClose={onClose}>
      <DialogTitle>
        {test.id
          ? t('TestDialog.DialogTitleUpdate')
          : t('TestDialog.DialogTitleCreate')}
      </DialogTitle>

      <Formik
        initialValues={{
          ...test,
          steps: test.steps.length ? test.steps : [getInitialStep()],
          mediaList: [],
        }}
        validationSchema={Yup.object({
          title: Yup.string().required(t('Global.requiredField')),
          description: Yup.string(),
        })}
        onSubmit={onSubmit}
      >
        {({ submitForm }) => {
          return (
            <>
              <DialogContent>
                <TestForm error={error} />
              </DialogContent>
              <DialogActions
                onCancel={onClose}
                confirmText={
                  test.id
                    ? t('TestDialog.updateButton')
                    : t('TestDialog.createButton')
                }
                onConfirm={submitForm}
              />
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}
