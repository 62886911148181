import Dialog from '../System/Dialog';
import DialogActions from '../System/DialogActions';
import { useTranslation } from 'react-i18next';

export default function WorkspacePricing({ open, loading, onSubmit, onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="2xl" onClose={onClose}>
      <div className="px-8 py-12">
        <p className="text-center text-4xl opacity-80 mb-8 max-w-lg mx-auto">
          {t('WorkspacePricing.willBeBilled')}
        </p>

        <p className="mb-4 italic">{t('WorkspacePricing.features')}</p>

        <p className="mb-4">{t('WorkspacePricing.addedToTotal')}</p>

        <p className="mb-4">{t('WorkspacePricing.prorated')}</p>

        <p>
          <strong>{t('WorkspacePricing.confirm')}</strong>
        </p>
      </div>

      <DialogActions
        onCancel={onClose}
        onConfirm={onSubmit}
        loading={loading}
        confirmText={t('WorkspacePricing.submitText')}
      />
    </Dialog>
  );
}
