import React, {
  useRef,
  useEffect,
  RefCallback,
  cloneElement,
  MutableRefObject,
} from 'react';

const eventTypeMapping = {
  click: 'onClick',
  mousedown: 'onMouseDown',
  mouseup: 'onMouseUp',
  touchstart: 'onTouchStart',
  touchend: 'onTouchEnd',
};

const ClickAwayListener = ({
  children,
  onClickAway,
  element,
  mouseEvent = 'click',
  touchEvent = 'touchend',
}) => {
  const node = useRef(null);
  const bubbledEventTarget = useRef(null);
  const mountedRef = useRef(false);

  /**
   * Prevents the bubbled event from getting triggered immediately
   * https://github.com/facebook/react/issues/20074
   */
  useEffect(() => {
    setTimeout(() => {
      mountedRef.current = true;
    }, 0);

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleBubbledEvents = (type) => (event) => {
    bubbledEventTarget.current = event.target;

    const handler = children?.props[type];

    if (handler) {
      handler(event);
    }
  };

  const handleChildRef = (childRef) => {
    node.current = childRef;
    let { ref } =
      children &
      {
        ref: RefCallback | MutableRefObject,
      };

    if (typeof ref === 'function') {
      ref(childRef);
    } else if (ref) {
      ref.current = childRef;
    }
  };

  useEffect(() => {
    const handleEvents = (event) => {
      if (!mountedRef.current) return;

      if (
        (node.current && node.current.contains(event.target)) ||
        bubbledEventTarget.current === event.target
      ) {
        return;
      }

      onClickAway(event);
    };

    (element || document).addEventListener(mouseEvent, handleEvents);
    (element || document).addEventListener(touchEvent, handleEvents);

    return () => {
      (element || document).removeEventListener(mouseEvent, handleEvents);
      (element || document).removeEventListener(touchEvent, handleEvents);
    };
  }, [mouseEvent, onClickAway, touchEvent, element]);

  const mappedMouseEvent = eventTypeMapping[mouseEvent];
  const mappedTouchEvent = eventTypeMapping[touchEvent];

  return React.Children.only(
    cloneElement(children, {
      ref: handleChildRef,
      [mappedMouseEvent]: handleBubbledEvents(mappedMouseEvent),
      [mappedTouchEvent]: handleBubbledEvents(mappedTouchEvent),
    }),
  );
};

export default ClickAwayListener;
