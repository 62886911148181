import { gql, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InviteFragment from '../../fragments/InviteFragment';
import CircularProgress from '../System/CircularProgress';
import InviteUserForm from '../users/InviteUserForm';
import useCurrentWorkspace from '../Workspace/useCurrentWorkspace';
import WorkspaceName from '../Workspace/WorkspaceName';
import WorkspaceUsers from '../Workspace/WorkspaceUsers';
import DeleteWorkspace from './DeleteWorkspace';

export const GET_INVITES = gql`
  query GetWorkspaceInvites {
    getWorkspaceInvites {
      ...InviteFragment
    }
  }

  ${InviteFragment}
`;

export default function SettingsForm({ className, ...rest }) {
  const { t } = useTranslation();

  const { loading, error, currentWorkspace } = useCurrentWorkspace();

  const { data, loading: invitesLoading } = useQuery(GET_INVITES);

  const users = currentWorkspace?.users;

  const sortedUsers = useMemo(() => {
    if (Array.isArray(users) && Array.isArray(data?.getWorkspaceInvites)) {
      const activeOwners = users.filter((u) => u.roles.indexOf('OWNER') > -1);
      const activeTesters = users.filter((u) => u.roles.indexOf('TESTER') > -1);
      return [
        ...activeOwners,
        ...[
          ...activeTesters,
          ...data.getWorkspaceInvites.map((u) => ({
            user: u,
            roles: ['JOINING'],
          })),
        ].sort((a, b) => (a.user.username < b.user.username ? -1 : 1)),
      ];
    } else {
      return [];
    }
  }, [users, data]);

  return (
    <div className={clsx(className)} {...rest}>
      <h1 className="mb-8">{t('SettingsForm.settings')}</h1>

      {loading ? null : error ? (
        <p>{t('Global.serverError')}</p>
      ) : (
        <>
          <div
            className="bg-background-paper border border-divider px-16 py-8 rounded"
            data-cy="workspace-name-setting"
          >
            <WorkspaceName name={currentWorkspace.name} />
          </div>

          <div className="bg-background-paper border border-divider px-16 py-8 my-8 rounded">
            <h2 className="mb-4">{t('SettingsForm.inviteTitle')}</h2>

            <hr className="mb-4" />

            {invitesLoading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <InviteUserForm users={sortedUsers} />
            )}

            <hr className="mt-8 mb-4" />

            <WorkspaceUsers
              workspaceId={currentWorkspace.id}
              users={sortedUsers}
            />
          </div>

          <div className="bg-background-paper border border-divider px-16 py-8 my-8 rounded">
            <h2 className="mb-4 text-red-500">
              {t('SettingsForm.deleteWorkspaceTitle')}
            </h2>

            <DeleteWorkspace workspaceName={currentWorkspace.name} />
          </div>
        </>
      )}
    </div>
  );
}
