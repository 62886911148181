export default function Folder({ fill, ...rest }) {
  return (
    <svg
      width="29"
      height="23"
      viewBox="0 0 29 23"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M2.58929 0C1.16539 0 0 1.16539 0 2.58929V20.1964C0 21.6203 1.16539 22.7857 2.58929 22.7857H26.4107C27.8346 22.7857 29 21.6203 29 20.1964V5.69643C29 4.27253 27.8346 3.10714 26.4107 3.10714H15.0179C14.4919 3.10714 13.9879 2.89852 13.616 2.52657L11.9734 0.883982C11.4075 0.318068 10.6396 0 9.83929 0H2.58929ZM2.58929 1.03571H9.83929C10.3653 1.03571 10.8692 1.24433 11.2411 1.61628L12.8837 3.25887C13.4496 3.82479 14.2176 4.14286 15.0179 4.14286H26.4107C27.2749 4.14286 27.9643 4.83228 27.9643 5.69643V6.74732C27.5298 6.41768 26.9949 6.21428 26.4107 6.21428H2.58929C2.00514 6.21428 1.47025 6.41768 1.03571 6.74732V2.58929C1.03571 1.72514 1.72514 1.03571 2.58929 1.03571ZM2.58929 7.25H26.4107C27.2749 7.25 27.9643 7.93942 27.9643 8.80357V20.1964C27.9643 21.0606 27.2749 21.75 26.4107 21.75H2.58929C1.72514 21.75 1.03571 21.0606 1.03571 20.1964V8.80357C1.03571 7.93942 1.72514 7.25 2.58929 7.25Z" />
    </svg>
  );
}
