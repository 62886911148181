import { useEffect, useRef } from 'react';
import useOrder from './useOrder';

export const POSITION_STEP = 65536;

export default function useSortable({
  sortableObjects = [],
  appendTo,
  handleSelector,
  onOrderChange,
}) {
  const listRef = useRef();

  const { onOrder } = useOrder({
    objects: sortableObjects,
    positionStep: POSITION_STEP,
    onOrderChange,
  });

  useEffect(() => {
    if (listRef?.current) {
      const { Plugins, Sortable } = require('@shopify/draggable');
      const sortable = new Sortable(listRef.current, {
        draggable: 'li',
        handle: `.${handleSelector}`,

        mirror: {
          constrainDimensions: true,
          xAxis: false,
          appendTo,
        },
        swapAnimation: {
          duration: 200,
          vertical: true,
          horizontal: false,
        },
        plugins: [Plugins.SwapAnimation],
      });

      sortable.on('sortable:stop', onOrder);

      return () => {
        sortable.destroy();
      };
    }
  }, [onOrder, appendTo, handleSelector]);

  return { listRef };
}
