import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ScheduledTestFragment from '../../../fragments/ScheduledTestFragment';

export const GET_TEST = gql`
  query GetTest($testId: String!) {
    getTest(testId: $testId) {
      id
      title
      description
      status
      archived
      steps {
        id
        title
        position
      }
      issues {
        id
        number
        createdAt
        status
        step {
          id
          title
        }
        user {
          id
          username
        }
      }
      passes {
        id
        createdAt
        user {
          id
          username
        }
      }
      actions {
        id
        createdAt
        action
        valueJSON
        user {
          id
          username
        }
      }
      scheduledTests {
        ...ScheduledTestFragment
      }
    }
  }

  ${ScheduledTestFragment}
`;

export default function useTests() {
  const { testId } = useParams();

  const { data, loading } = useQuery(GET_TEST, {
    variables: {
      testId,
    },
  });

  const test = useMemo(() => {
    return (
      data?.getTest && {
        ...data.getTest,
        scheduledTests: data.getTest.scheduledTests
          .slice()
          .sort((a, b) => (Number(a.limitDate) < Number(b.limitDate) ? -1 : 1)),
        steps: [...(data.getTest.steps || [])].sort((a, b) =>
          a.position < b.position ? -1 : 1,
        ),
      }
    );
  }, [data]);

  const results = useMemo(() => {
    const merged = [
      ...(data?.getTest.issues || []).map((issue) => ({
        ...issue,
        status: 'bug',
      })),
      ...(data?.getTest.passes || []).map((passedTest) => ({
        ...passedTest,
        status: 'passed',
      })),
    ];

    return merged.sort((a, b) =>
      Number(a.createdAt) < Number(b.createdAt) ? 1 : -1,
    );
  }, [data]);

  return { loading, test, results };
}
