import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowDropDown from '../Icons/ArrowDropDown';
import ChangeLanguage from '../Language/ChangeLanguage';
import Button from '../System/Button';
import Menu from '../System/Menu';
import MenuItem from '../System/MenuItem';
import useOpen from '../System/useOpen';
import WorkspacesSelect from '../Workspace/WorkspacesSelect';
import Logo from '../../assets/logo-kt.svg';
import MenuIcon from '../Icons/Menu';
import useLogout from '../Auth/useLogout';
import UserAvatar from '../System/UserAvatar';
import useWorkspaces from '../Workspace/useWorkspaces';
import { useState } from 'react';
import Event from '../Icons/Event';
import Settings from '../Icons/Settings';
import BugReport from '../Icons/BugReport';
import Assignment from '../Icons/Assignment';
import useCurrentUser from '../CurrentUser/useCurrentUser';

const ListItem = ({ className, active, ...rest }) => {
  const { pathname } = useLocation();

  return (
    <li
      className={clsx(
        'mr-2 nav-link transition hover:bg-primary-main hover:bg-opacity-5',
        'rounded',
        pathname.startsWith(active) &&
          'bg-primary-main bg-opacity-5 text-primary-main',
        className,
      )}
      {...rest}
    />
  );
};

export default function TopBar() {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();

  const { onLogout } = useLogout();

  const { workspaces } = useWorkspaces();

  const [menuReferenceElement, setMenuReferenceElement] = useState(null);

  const { currentUser } = useCurrentUser();

  return (
    <header
      data-cy="main-topbar"
      className="bg-background-paper border-b border-divider px-4 xl:px-8 py-4 flex items-center"
    >
      <div className="w-32 mr-24">
        <img src={Logo} alt="Keep Testing" />
      </div>

      <div className=" lg:hidden ml-auto flex items-center">
        <Button noPadding noBg noShadow>
          <MenuIcon className="w-8" />
        </Button>
      </div>

      {!workspaces?.length ? null : (
        <nav data-cy="topbar-nav" className="flex-1 hidden lg:block">
          <ul className="flex items-center">
            <ListItem active="/tests">
              <Link
                className={clsx(
                  'no-underline flex flex-col items-center',
                  'px-2 py-2 w-24',
                )}
                to="/tests"
              >
                <Assignment className="w-6 mb-1 fill-current" />
                {t('TestsPage.nav.tests')}
              </Link>
            </ListItem>

            <ListItem active="/issues">
              <Link
                className={clsx(
                  'no-underline flex flex-col items-center',
                  'px-2 py-2 w-24',
                )}
                to="/issues"
              >
                <BugReport className="w-6 mb-1 fill-current" />
                {t('TestsPage.nav.issues')}
              </Link>
            </ListItem>

            <ListItem active="/agenda">
              <Link
                className={clsx(
                  'no-underline flex flex-col items-center',
                  'px-2 py-2 w-24',
                )}
                to="/agenda"
              >
                <Event className="w-6 mb-1 fill-current" />
                {t('TestsPage.nav.agenda')}
              </Link>
            </ListItem>

            {currentUser.workspaceRoles.includes('OWNER') ? (
              <ListItem active="/settings">
                <Link
                  className={clsx(
                    'no-underline flex flex-col items-center',
                    'px-2 py-2 w-24',
                  )}
                  to="/settings"
                >
                  <Settings className="w-6 mb-1 fill-current" />
                  {t('TestsPage.nav.settings')}
                </Link>
              </ListItem>
            ) : null}
          </ul>
        </nav>
      )}

      <div className="hidden lg:block ml-auto">
        <WorkspacesSelect classes={{ actionButton: 'mr-4' }} />
      </div>

      <div className="hidden lg:block">
        <ChangeLanguage />
      </div>

      <Button
        noPadding
        noShadow
        className="bg-background-paper ml-8"
        data-cy="user-menu-open"
        onClick={onOpen}
      >
        <UserAvatar />
        <div ref={setMenuReferenceElement}>
          <ArrowDropDown data-cy="topbar-drop-down" />
        </div>
      </Button>

      <Menu
        data-cy="user-menu"
        open={open}
        onClose={onClose}
        referenceElement={menuReferenceElement}
      >
        <MenuItem to="/me">{t('TopBar.userMenu.profile')}</MenuItem>
        <MenuItem to="/billing">{t('TopBar.userMenu.billing')}</MenuItem>
        <MenuItem onClick={onLogout}>{t('TopBar.userMenu.logout')}</MenuItem>
      </Menu>
    </header>
  );
}
