import clsx from 'clsx';
import { motion } from 'framer-motion';

const SIZE = 44;

export default function CircularProgress({
  className,
  style = {},
  size = 20,
  thickness = 3.6,
  state = 'undefined',
  color = 'primary',
  ...rest
}) {
  return state === 'success' || state === 'error' ? (
    <span
      className={clsx('inline-block', className)}
      style={{ width: size, height: size, ...style }}
      role="progressbar"
      {...rest}
    >
      <svg
        className="block"
        viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}
      >
        <circle
          className={clsx(
            'stroke-current',
            state === 'success' && 'text-green-600',
            state === 'error' && 'text-error',
          )}
          cx={SIZE}
          cy={SIZE}
          r={(SIZE - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
        />
        <circle
          className={clsx(
            'stroke-current fill-current',
            state === 'success' && 'text-green-600',
            state === 'error' && 'text-error',
          )}
          cx={SIZE}
          cy={SIZE}
          fill=""
          r={((SIZE - thickness) / 2) * 0.5}
          strokeWidth={thickness}
        />
      </svg>
    </span>
  ) : (
    <motion.span
      className={clsx(
        'inline-block',
        color === 'primary' ? 'text-primary-main' : '',
        className,
      )}
      style={{ width: size, height: size, ...style }}
      role="progressbar"
      animate={{
        rotate: 360,
      }}
      transition={{ duration: 1.4, ease: 'linear', repeat: Infinity }}
      {...rest}
    >
      <svg
        className="block"
        viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}
      >
        <motion.circle
          className={clsx('stroke-current')}
          cx={SIZE}
          cy={SIZE}
          r={(SIZE - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
          animate={{
            strokeDasharray: ['1px, 200px', '100px 200px', '100px, 200px'],
            strokeDashoffset: ['0px', '-15px', '-125px'],
          }}
          transition={{ duration: 1.4, repeat: Infinity }}
        />
      </svg>
    </motion.span>
  );
}
