import { DateTime } from 'luxon';
import TextField from '../System/TextField';
import { useTranslation } from 'react-i18next';
import Checkbox from '../System/Checkbox';
import Button from '../System/Button';
import clsx from 'clsx';

const Shortcut = ({
  className,
  date,
  disabled,
  expectedDate,
  children,
  setDate,
  setDisabled,
  ...rest
}) => {
  return (
    <Button
      size="small"
      noPadding
      className={clsx(
        'bg-background-default px-2 py-1 rounded text-sm mr-4 mb-2',
        !disabled &&
          DateTime.fromISO(date).hasSame(expectedDate, 'day') &&
          'ring-2 ring-primary-main',
        className,
      )}
      onClick={() => {
        setDisabled(false);
        setDate(expectedDate);
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default function SchedulerDate({
  date,
  setDate,
  setDisabled,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row">
      <div>
        <div>
          <div className="w-60 flex items-center">
            <TextField
              data-cy="scheduler-limit-date"
              label={t('SchedulerDate.inputLabel')}
              name="date"
              type="date"
              classes={{ label: 'mb-2' }}
              skipTouchedError
              disabled={disabled}
            />
          </div>
        </div>

        <div>
          {!disabled && date ? (
            <p className="caption mb-4 italic -mt-2">
              {t('SchedulerDate.dueDate')}{' '}
              {DateTime.fromISO(date)
                .endOf('day')
                .toRelative({
                  unit:
                    DateTime.fromISO(date)
                      .endOf('day')
                      .diffNow('days')
                      .toObject().days < 1
                      ? undefined
                      : 'days',
                })
                ?.toLowerCase()}
              .
            </p>
          ) : null}

          <div className="flex">
            <Checkbox
              label={t('SchedulerDate.disabledLabel')}
              name="disabled"
            />
          </div>
        </div>
      </div>

      <div className="md:border-l border-divider mt-6 md:mt-0 md:pl-4 md:ml-4">
        <p className="mb-2 opacity-75 font-bold text-sm">
          {t('SchedulerDate.shortcut.title')}
        </p>

        <div className="flex items-center flex-wrap">
          <Shortcut
            date={date}
            expectedDate={DateTime.now().plus({ weeks: 1 })}
            disabled={disabled}
            setDisabled={setDisabled}
            setDate={setDate}
          >
            {t('SchedulerDate.shortcut.oneWeek')}
          </Shortcut>

          <Shortcut
            date={date}
            expectedDate={DateTime.now().plus({ weeks: 2 })}
            disabled={disabled}
            setDisabled={setDisabled}
            setDate={setDate}
          >
            {t('SchedulerDate.shortcut.twoWeeks')}
          </Shortcut>

          <Shortcut
            date={date}
            expectedDate={DateTime.now().plus({ months: 1 })}
            disabled={disabled}
            setDisabled={setDisabled}
            setDate={setDate}
          >
            {t('SchedulerDate.shortcut.oneMonth')}
          </Shortcut>

          <Shortcut
            date={date}
            expectedDate={DateTime.now().plus({ months: 2 })}
            disabled={disabled}
            setDisabled={setDisabled}
            setDate={setDate}
          >
            {t('SchedulerDate.shortcut.twoMonths')}
          </Shortcut>

          <Shortcut
            date={date}
            expectedDate={DateTime.now().plus({ months: 6 })}
            disabled={disabled}
            setDisabled={setDisabled}
            setDate={setDate}
          >
            {t('SchedulerDate.shortcut.sixMonths')}
          </Shortcut>
        </div>
      </div>
    </div>
  );
}
