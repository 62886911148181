import { useTranslation } from 'react-i18next';
import Dialog from '../System/Dialog';
import DialogTitle from '../System/DialogTitle';
import DialogActions from '../System/DialogActions';
import { gql, useMutation } from '@apollo/client';
import useSnackbar from '../System/useSnackbar';
import Alert from '../System/Alert';
import useTest from '../Tests/Test/useTest';
import useCreateIssueComment from './useCreateIssueComment';
import CreateIssueCommentForm from './CreateIssueCommentForm';
import clsx from 'clsx';
import useUpdateScheduledTests from '../TestScheduler/useUpdateScheduledTests';
import UpdateScheduledTests from '../TestScheduler/UpdateScheduledTests';

const CREATE_ISSUE = gql`
  mutation CreateIssue($testId: String, $stepId: String) {
    createIssue(testId: $testId, stepId: $stepId) {
      id
      number
      createdAt
      title
      status
      test {
        id
        title
      }
      step {
        id
        title
      }
      user {
        id
        username
      }
    }
  }
`;

const FromStep = ({ className, stepId }) => {
  const { t } = useTranslation();

  const { loading, test } = useTest();

  const step =
    test?.steps?.length && test.steps.find((step) => step.id === stepId);

  return !loading && !step ? null : (
    <Alert className={clsx(className)}>
      {loading ? (
        ''
      ) : (
        <>
          <strong>{t('FromStep.fromStep')}</strong> <i>{step.title}</i>
        </>
      )}
    </Alert>
  );
};

export default function CreateIssueDialog({ open, test, stepId, onClose }) {
  const { t } = useTranslation();

  const {
    assignedScheduledTests,
    checkedScheduledTests,
    setCheckedScheduledTests,
  } = useUpdateScheduledTests({ testScheduledTests: test.scheduledTests });

  const { loading, onCreate: onCreateIssueComment } = useCreateIssueComment();

  const [createIssue] = useMutation(CREATE_ISSUE, {
    refetchQueries: ['GetTest'],
  });

  const { showSnackbar } = useSnackbar();

  const handleCreate = async ({ description, mediaList }) => {
    try {
      const { data: issueData } = await createIssue({
        variables: {
          testId: test.id,
          stepId,
        },
        update(cache, { data: { createIssue } }) {
          cache.modify({
            fields: {
              getIssues(existingIssues = []) {
                const newIssueRef = cache.writeFragment({
                  data: { ...createIssue, title: description },
                  fragment: gql`
                    fragment NewIssue on Issue {
                      id
                      number
                      createdAt
                      title
                      status
                      comments
                      test {
                        id
                        title
                      }
                      step {
                        id
                        title
                      }
                      user {
                        id
                        username
                      }
                    }
                  `,
                });
                return [newIssueRef, ...existingIssues];
              },
            },
          });
        },
      });

      await onCreateIssueComment({
        issueId: issueData.createIssue.id,
        description,
        mediaList,
        scheduledTestIds: checkedScheduledTests.map((s) => s.id),
      });

      showSnackbar(t('CreateIssueDialog.success'), 'success');
      onClose();
    } catch (err) {
      console.log(err);
      showSnackbar(t('Global.serverError'), 'error');
    }
  };

  return (
    <Dialog data-cy="issue-dialog" open={open} maxWidth="4xl" onClose={onClose}>
      <DialogTitle>{t('CreateIssueDialog.dialogTitle')}</DialogTitle>

      <FromStep className="text-sm mt-4 mx-12" stepId={stepId} />

      <CreateIssueCommentForm
        classes={{ editor: 'my-4 mx-12' }}
        placeholder={t('CreateIssueDialog.editorPlaceholder')}
        onSubmit={handleCreate}
      >
        {({ values, submitForm }) => (
          <>
            <UpdateScheduledTests
              className="mx-12 mb-8"
              isFailed
              assignedScheduledTests={assignedScheduledTests}
              checkedScheduledTests={checkedScheduledTests}
              setCheckedScheduledTests={setCheckedScheduledTests}
            />

            <DialogActions
              onCancel={onClose}
              loading={loading}
              onConfirm={() => {
                submitForm(values);
              }}
            />
          </>
        )}
      </CreateIssueCommentForm>
    </Dialog>
  );
}
