import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AuthLayout from '../components/Auth/AuthLayout';
import ResetPasswordForm from '../components/Auth/ResetPasswordForm';

export default function ResetPasswordPage() {
  const { t } = useTranslation();

  return (
    <AuthLayout title={t('ResetPasswordPage.title')}>
      <ResetPasswordForm />

      <p className=" mt-8 text-sm no-underline hover:underline">
        <Link to="/sign-in">{t('ResetPasswordPage.backToSignIn')}</Link>
      </p>
    </AuthLayout>
  );
}
