import { gql } from '@apollo/client';

const IssueActionFragment = gql`
  fragment IssueActionFragment on IssueAction {
    id
    createdAt
    action
    user {
      id
      username
    }
  }
`;

export default IssueActionFragment;
