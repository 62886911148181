import { useTranslation, Trans } from 'react-i18next';
import usePortal from '../Subscription/usePortal';
import Button from '../System/Button';

// i18next parser
//
// t('WIPBilling.upgradeInfo');

export default function WIPBilling() {
  const { t } = useTranslation();

  const { goToPortal } = usePortal();

  return (
    <div>
      <h1>{t('WIPBilling.title')}</h1>

      <h2>{t('WIPBilling.upgrade')}</h2>

      <p className="mt-2 mb-8">
        <Trans
          i18nKey="WIPBilling.upgradeInfo"
          components={{
            // eslint-disable-next-line
            a: <a className="underline" href="mailto:contact@keeptesting.io" />,
          }}
        />
      </p>

      <h2>{t('WIPBilling.update')}</h2>

      <p className="mt-2 mb-4">{t('WIPBilling.updateInfo')}</p>

      <div>
        <Button variant="contained" color="primary" onClick={goToPortal}>
          {t('WIPBilling.accessPortal')}
        </Button>
      </div>
    </div>
  );
}
