import clsx from 'clsx';

export default function LinearProgress({ progress, classes = {} }) {
  return (
    <div className={clsx('bg-gray-200 h-1 rounded-full flex', classes.root)}>
      <div
        style={{ width: `${progress}%` }}
        className={clsx('bg-black transition-all', classes.progressBar)}
      />
    </div>
  );
}
