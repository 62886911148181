import React, { useState, createContext } from 'react';
import Snackbar from './Snackbar';

const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
  const [snackState, setSnackState] = useState({
    open: false,
    message: { text: '' },
    variant: 'info',
  });

  const handleClose = () => {
    setSnackState((prev) => ({ ...prev, open: false }));
  };

  const showSnackbar = (message, variant = 'info') => {
    setSnackState((prev) => ({
      ...prev,
      message: { text: message },
      variant,
      open: true,
    }));
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, onClose: handleClose }}>
      <>
        {children}
        <Snackbar
          open={snackState.open}
          variant={snackState.variant}
          message={snackState.message}
          onClose={handleClose}
        />
      </>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
export { SnackbarContext };
