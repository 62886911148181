import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import TextField from '../System/TextField';
import Error from '../System/Error';
import Steps from './Step/Steps';
import Editor from '../System/Editor/Editor';

export default function TestForm({ error }) {
  const { t } = useTranslation();

  return (
    <Form noValidate>
      <TextField
        label={t('CreateTestForm.titleLabel')}
        name="title"
        data-cy="test-title-editor"
      />

      <p className="mb-1 opacity-75 font-bold text-sm">
        {t('CreateTestForm.descriptionLabel')}
      </p>

      <Editor
        hideMedia
        name="description"
        mediaListName="mediaList"
        data-cy="test-desc-editor"
      />

      <Steps className="mt-6" />

      {error ? <Error>{t('Global.serverError')}</Error> : null}
    </Form>
  );
}
