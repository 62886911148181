import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AuthLayout from '../components/Auth/AuthLayout';
import ForgotPasswordForm from '../components/Auth/ForgotPasswordForm';

export default function ForgotPasswordPage() {
  const { t } = useTranslation();

  return (
    <AuthLayout title={t('ForgotPasswordPage.title')}>
      <ForgotPasswordForm />

      <p className=" mt-8 text-sm no-underline hover:underline">
        <Link to="/sign-in">{t('ForgotPasswordPage.backToSignIn')}</Link>
      </p>
    </AuthLayout>
  );
}
