import React, { useState, createContext, useCallback, useEffect } from 'react';
import s3PostSignedUrl from './s3-post-signed-url';
import useMediaUpload from './useMediaUpload';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import clsx from 'clsx';
import LinearProgress from './LinearProgress';
import CircularProgress from './CircularProgress';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import Document from '../Icons/Document';
import KeyboardArrowDown from '../Icons/KeyboardArrowDown';
import Button from './Button';
import useOpen from './useOpen';

let id = 1;

const MediaUploadContext = createContext();

const MediaUploadProvider = ({ children }) => {
  const { t } = useTranslation();

  const { getSignedUrl } = useMediaUpload();

  const [uploadQueue, setUploadQueue] = useState([]);

  const upload = useCallback(
    async ({ file, label, preview }, { onCompleted = () => {} } = {}) => {
      const contentId = id++;

      setUploadQueue((queue) => [
        ...queue,
        { id: contentId, name: file.name, label, preview, progress: 0 },
      ]);

      try {
        // Upload preview
        let previewKey;
        if (preview && file.type.startsWith('video/')) {
          const nameWithoutExt = file.name.split('.').slice(0, -1).join('.');
          const signedUrl = await getSignedUrl({
            file: { name: `${nameWithoutExt}_preview.jpg`, type: 'image/jpeg' },
          });

          // https://stackoverflow.com/a/36297034
          const dataURItoBlob = (dataURI) => {
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for (var i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
          };

          const { key } = await s3PostSignedUrl({
            signedUrl,
            file: dataURItoBlob(preview),
          });
          previewKey = key;
        }

        const signedUrl = await getSignedUrl({ file });

        const { key } = await s3PostSignedUrl(
          { signedUrl, file },
          {
            onProgress({ progress }) {
              setUploadQueue((queue) =>
                queue.map((content) => {
                  if (content.id === contentId) {
                    return { ...content, progress: Math.min(progress, 99) };
                  }
                  return content;
                }),
              );
            },
          },
        );

        // Set progress at 100%
        setUploadQueue((queue) =>
          queue.map((content) => {
            if (content.id === contentId) {
              return { ...content, progress: 100 };
            }
            return content;
          }),
        );

        onCompleted({ previewKey, key });
      } catch (err) {
        Sentry.captureException(err);

        setUploadQueue((queue) =>
          queue.map((content) => {
            if (content.id === contentId) {
              return { ...content, progress: 0, error: true };
            }
            return content;
          }),
        );
      } finally {
        setTimeout(() => {
          setUploadQueue((queue) => {
            return queue.filter((content) => content.id !== contentId);
          });
        }, 3000);
      }
    },
    [getSignedUrl],
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!uploadQueue.length) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [uploadQueue]);

  const { open: detailOpen, onToggle: detailOnToggle } = useOpen(true);

  return (
    <MediaUploadContext.Provider value={{ upload }}>
      {children}

      <AnimateSharedLayout>
        <AnimatePresence>
          {open ? (
            <motion.div
              layout
              className={clsx(
                'z-40 fixed bottom-12 right-12 rounded',
                'bg-background-paper shadow-xl',
                detailOpen ? 'px-8 py-6' : 'px-8 py-2',
                'overflow-hidden',
                'w-80',
                'border border-divider',
              )}
              transition={{ duration: 0.2, staggerChildren: 0.01 }}
              initial={{ x: 400 }}
              animate={{ x: 0 }}
              exit={{ x: 400 }}
            >
              <motion.div layout className="flex items-center">
                <CircularProgress size={16} color="inherit" />

                <p className="font-bold flex-1 ml-2">
                  {t('MediaUploadProvider.title')}
                </p>
                <Button noPadding noShadow noBg onClick={detailOnToggle}>
                  <KeyboardArrowDown
                    className={clsx(
                      'w-6 transition-transform',
                      !detailOpen && 'transform -rotate-90',
                    )}
                  />
                </Button>
              </motion.div>
              {detailOpen ? (
                <ul className="grid grid-cols-1 gap-2 mt-4">
                  {uploadQueue.map((content) => (
                    <li
                      key={content.id}
                      className="flex items-center"
                      initial={{ x: '100%' }}
                      animate={{ x: 0 }}
                      exit={{ x: 2000 }}
                      transition={{ staggerChildren: 0.01 }}
                    >
                      <div
                        className="w-12 h-12 bg-cover bg-center bg-background-default rounded mr-4 flex-shrink-0 flex items-center justify-center"
                        style={{
                          backgroundImage: content.preview
                            ? `url("${content.preview}")`
                            : null,
                        }}
                      >
                        {content.preview ? null : (
                          <Document className="w-6 opacity-70" />
                        )}
                      </div>
                      <div className="flex-1 overflow-hidden">
                        <div className="flex items-center">
                          <CircularProgress
                            className="flex-shrink-0"
                            size={12}
                            state={
                              content.error
                                ? 'error'
                                : content.progress === 100
                                ? 'success'
                                : 'undefined'
                            }
                          />
                          <p className="text-sm mb-1 ml-2 overflow-ellipsis overflow-hidden whitespace-nowrap">
                            {content.label}
                          </p>
                        </div>
                        <LinearProgress
                          classes={{
                            root: clsx(content.error && 'bg-error'),
                            progressBar: clsx(
                              content.progress === 100 && 'bg-green-500',
                            ),
                          }}
                          progress={content.progress}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              ) : null}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </AnimateSharedLayout>
    </MediaUploadContext.Provider>
  );
};

export default MediaUploadProvider;
export { MediaUploadContext };
