import clsx from 'clsx';
import { useMemo } from 'react';
import ErrorIcon from '../Icons/Error';
import InfoIcon from '../Icons/Info';
import SuccessIcon from '../Icons/Success';
import WarningIcon from '../Icons/Warning';

export default function Alert({
  children,
  className,
  variant = 'info',
  endAdornment = null,
  ...rest
}) {
  const Icon = useMemo(() => {
    switch (variant) {
      case 'success':
        return SuccessIcon;
      case 'error':
        return ErrorIcon;
      case 'warning':
        return WarningIcon;

      default:
        return InfoIcon;
    }
  }, [variant]);

  return (
    <div
      className={clsx(
        variant === 'info' &&
          'border-blue-500 border-text-blue-900 text-blue-900',
        variant === 'error' && 'border-red-500 text-red-900',
        variant === 'success' && 'border-green-500 text-green-900',
        variant === 'warning' && 'border-yellow-500 text-yellow-900',
        'bg-background-paper px-4 py-2 rounded flex flex-1 items-center border-2',
        className,
      )}
      {...rest}
    >
      <Icon
        className={clsx(
          'w-6 h-6 mr-4 fill-current flex-shrink-0',
          variant === 'info' && 'text-blue-500',
          variant === 'error' && 'text-red-500',
          variant === 'success' && 'text-green-500',
          variant === 'warning' && 'text-yellow-500',
        )}
      />
      <p className=" flex-1 opacity-80">{children}</p>

      {endAdornment}
    </div>
  );
}
