import clsx from 'clsx';
import Button from '../../System/Button';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '../../Icons/DeleteForever';
import UpdateTest from './UpdateTest';
import Dialog from '../../System/Dialog';
import DialogTitle from '../../System/DialogTitle';
import DialogContent from '../../System/DialogContent';
import DialogActions from '../../System/DialogActions';
import useOpen from '../../System/useOpen';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import useSnackbar from '../../System/useSnackbar';
import TestScheduler from '../../TestScheduler/TestScheduler';

const ARCHIVE_TEST = gql`
  mutation ArchiveTest($test: UpdateTestInput!) {
    updateTest(test: $test) {
      id
      archived
    }
  }
`;

export default function TestTopBar({
  className,
  scheduledTests,
  archived,
  ...rest
}) {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useOpen();

  const { folderId, testId } = useParams();
  const { replace } = useHistory();
  const { showSnackbar } = useSnackbar();

  const [archiveTest, { loading: archiveLoading }] = useMutation(ARCHIVE_TEST, {
    variables: {
      test: {
        testId,
        archived: true,
      },
    },
    onCompleted() {
      showSnackbar(t('TestTopBar.archiveSuccess'), 'success');
      replace(`/tests/${folderId}/`);
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  const [unarchiveTest, { loading: unarchiveLoading }] = useMutation(
    ARCHIVE_TEST,
    {
      variables: {
        test: {
          testId,
          archived: false,
        },
      },
      onCompleted() {
        showSnackbar(t('TestTopBar.unarchiveSuccess'), 'success');
        onClose();
      },
      onError() {
        showSnackbar(t('Global.serverError'), 'error');
      },
    },
  );

  return (
    <div
      data-cy="test-topbar"
      className={clsx('flex items-center px-4 py-2', className)}
      {...rest}
    >
      <div className="flex-1 flex items-center">
        <UpdateTest className="mr-4" />

        <TestScheduler scheduledTests={scheduledTests} />
      </div>

      <div>
        <div>
          <Button
            className="border-purple-500 text-purple-700 opacity-90 hover:opacity-100 rounded-md"
            variant="outlined"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={onOpen}
          >
            {archived
              ? t('TestTopBar.unarchiveButton')
              : t('TestTopBar.archiveButton')}
          </Button>

          <Dialog data-cy="archive-dialog" open={open} onClose={onClose}>
            <DialogTitle>{t('TestTopBar.archiveTitle')}</DialogTitle>
            <DialogContent>
              <p>
                {archived
                  ? t('TestTopBar.unarchiveConfirmText')
                  : t('TestTopBar.archiveConfirmText')}
              </p>
            </DialogContent>
            <DialogActions
              loading={archiveLoading || unarchiveLoading}
              confirmText={
                archived
                  ? t('TestTopBar.unarchiveSubmit')
                  : t('TestTopBar.archiveSubmit')
              }
              onCancel={onClose}
              onConfirm={archived ? unarchiveTest : archiveTest}
            />
          </Dialog>
        </div>
      </div>
    </div>
  );
}
