export default async function getVideoThumbnail({ file }) {
  return new Promise((resolve) => {
    const videoElement = document.createElement('video');
    videoElement.style.display = 'none';
    const sourceElement = document.createElement('source');
    videoElement.append(sourceElement);

    const canvasElement = document.createElement('canvas');
    canvasElement.style.display = 'none';

    document.body.append(videoElement);
    document.body.append(canvasElement);

    videoElement.addEventListener('loadedmetadata', () => {
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;
      videoElement.play();
    });

    videoElement.addEventListener('timeupdate', () => {
      const context = canvasElement.getContext('2d');
      context.drawImage(
        videoElement,
        0,
        0,
        videoElement.videoWidth,
        videoElement.videoHeight,
      );

      videoElement.remove();
      canvasElement.remove();

      resolve(canvasElement.toDataURL('image/jpeg'));
    });

    sourceElement.src = URL.createObjectURL(file);
  });
}
