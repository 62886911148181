import { useTranslation } from 'react-i18next';
import Button from '../System/Button';
import SendIcon from '../Icons/Send';
import CreateIssueCommentForm from './CreateIssueCommentForm';
import useCreateIssueComment from './useCreateIssueComment';
import useSnackbar from '../System/useSnackbar';
import CloseIssueButton from './CloseIssueButton';
import ReopenIssueButton from './ReopenIssueButton';

export default function CreateIssueComment({ issue }) {
  const { t } = useTranslation();

  const { onCreate: onCreateIssueComment } = useCreateIssueComment();

  const { showSnackbar } = useSnackbar();

  const handleCreate = async ({ description, mediaList }, { setValues }) => {
    try {
      await onCreateIssueComment({
        issueId: issue.id,
        description,
        mediaList,
      });

      setValues({ description: '', mediaList: [] });
      showSnackbar(t('CreateIssueComment.success'), 'success');
    } catch (err) {
      console.log(err);
      showSnackbar(t('Global.serverError'), 'error');
    }
  };

  return (
    <CreateIssueCommentForm onSubmit={handleCreate}>
      {({ values, submitForm }) => {
        const isEmpty =
          values.mediaList.length === 0 &&
          (!values.description.length || values.description === '<p><br></p>');

        return (
          <div className="flex">
            <div className="mr-4">
              <Button
                data-cy="submit-issue-comment"
                type="submit"
                className="px-4"
                disabled={isEmpty}
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
              >
                {t('CreateIssueComment.submit')}
              </Button>
            </div>

            <div className="ml-auto">
              {issue.status === 'CLOSE' ? (
                <ReopenIssueButton
                  withComment={!isEmpty}
                  issueId={issue.id}
                  onBeforeReopened={async () => {
                    if (!isEmpty) {
                      await submitForm();
                    }
                  }}
                />
              ) : (
                <CloseIssueButton
                  withComment={!isEmpty}
                  testId={issue.test.id}
                  issueId={issue.id}
                  onBeforeClosed={async () => {
                    if (!isEmpty) {
                      await submitForm();
                    }
                  }}
                />
              )}
            </div>
          </div>
        );
      }}
    </CreateIssueCommentForm>
  );
}
