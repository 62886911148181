import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

function Portal({ children }) {
  return createPortal(children, document.getElementById('modal-root'));
}

export default function Dialog({
  open,
  className = null,
  children,
  maxWidth = 'xl',
  onClose,
  ...rest
}) {
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      if (onClose) {
        onClose();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  return (
    <AnimatePresence>
      {open ? (
        <Portal>
          <motion.div
            className={clsx(
              'flex',
              'items-center',
              'justify-center',
              'fixed top-0',
              'bottom-0',
              'left-0',
              'right-0',
              'bg-black',
              'bg-opacity-50',
              'z-50',
              children,
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{ opacity: 0, transition: { duration: 0.15 } }}
            onClick={onClose}
            {...rest}
          >
            <motion.div
              className={clsx(
                `bg-background-paper rounded w-full max-h-80p max-w-${maxWidth} overflow-auto flex flex-col`,
              )}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </motion.div>
          </motion.div>
        </Portal>
      ) : null}
    </AnimatePresence>
  );
}
