import useCurrentUser from '../CurrentUser/useCurrentUser';
import { useTranslation, Trans } from 'react-i18next';
import { DateTime } from 'luxon';
import useSubscribe from '../Subscription/useSubscribe';
import PricingCard from './PricingCard';

// i18next parser
//
// t('Pricing.soloPrice');
// t('Pricing.teamPrice');

export default function TrialBilling() {
  const { t } = useTranslation();

  const { currentUser } = useCurrentUser();
  const { goToSubscription } = useSubscribe();

  const endDays = DateTime.fromMillis(currentUser.currentPeriodEnd).toRelative({
    unit:
      DateTime.fromMillis(currentUser.currentPeriodEnd)
        .endOf('day')
        .diffNow('days')
        .toObject().days < 1
        ? undefined
        : 'days',
  });

  return (
    <>
      <h1 className="text-center text-4xl">{t('TrialBilling.freeTrial')}</h1>

      <p className="text-center text-xl">
        {t('TrialBilling.trialEndDays', {
          days: endDays,
        })}
      </p>

      <section className="flex justify-center mt-16">
        <PricingCard
          className="mr-16"
          price={
            <Trans
              i18nKey="Pricing.soloPrice"
              components={{ small: <span className="text-xl font-normal" /> }}
            />
          }
          title={t('Pricing.soloTitle')}
          subtitle={t('Pricing.soloSubtitle')}
          features={[
            t('Pricing.allFeatures'),
            t('Pricing.unlimitedWorkspaces'),
            t('Pricing.upToTwoMembers'),
          ]}
          ButtonProps={{
            className: 'no-underline',
            variant: 'contained',
            color: 'primary',
            text: t('Pricing.buySolo'),
            wrap: true,
            onClick: () =>
              goToSubscription({
                priceId: process.env.REACT_APP_STRIPE_SOLO_PRICE,
              }),
          }}
        />

        <PricingCard
          price={
            <Trans
              i18nKey="Pricing.teamPrice"
              components={{ small: <span className="text-xl font-normal" /> }}
            />
          }
          title={t('Pricing.teamTitle')}
          subtitle={t('Pricing.teamSubtitle')}
          features={[t('Pricing.allFeatures'), t('Pricing.upToHeightMembers')]}
          ButtonProps={{
            className: 'no-underline',
            variant: 'contained',
            color: 'primary',
            text: t('Pricing.buyTeam'),
            wrap: true,
            onClick: () =>
              goToSubscription({
                priceId: process.env.REACT_APP_STRIPE_TEAM_PRICE,
              }),
          }}
        />
      </section>
    </>
  );
}
