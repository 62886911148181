import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import apolloClient from './apollo';
import AuthProvider from './components/Auth/AuthProvider';
import MediaUploadProvider from './components/System/MediaUploadProvider';
import SnackbarProvider from './components/System/SnackbarProvider';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <SnackbarProvider>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <MediaUploadProvider>
              <AuthProvider />
            </MediaUploadProvider>
          </BrowserRouter>
        </ApolloProvider>
      </SnackbarProvider>
    </Elements>
  );
}

export default App;
