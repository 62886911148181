export default function TestsList({ fill, ...rest }) {
  return (
    <svg
      width="46"
      height="54"
      viewBox="0 0 46 54"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M3 14H11C12.654 14 14 12.654 14 11V3C14 1.346 12.654 0 11 0H3C1.346 0 0 1.346 0 3V11C0 12.654 1.346 14 3 14ZM2 3C2 2.449 2.449 2 3 2H11C11.551 2 12 2.449 12 3V11C12 11.551 11.551 12 11 12H3C2.449 12 2 11.551 2 11V3Z" />
      <path d="M13 26C12.448 26 12 26.448 12 27V31C12 31.551 11.551 32 11 32H3C2.449 32 2 31.551 2 31V23C2 22.449 2.449 22 3 22H8.5C9.052 22 9.5 21.552 9.5 21C9.5 20.448 9.052 20 8.5 20H3C1.346 20 0 21.346 0 23V31C0 32.654 1.346 34 3 34H11C12.654 34 14 32.654 14 31V27C14 26.448 13.552 26 13 26Z" />
      <path d="M11 40H3C1.346 40 0 41.346 0 43V51C0 52.654 1.346 54 3 54H11C12.654 54 14 52.654 14 51V43C14 41.346 12.654 40 11 40ZM12 51C12 51.551 11.551 52 11 52H3C2.449 52 2 51.551 2 51V43C2 42.449 2.449 42 3 42H11C11.551 42 12 42.449 12 43V51Z" />
      <path d="M19.5 3H33C33.553 3 34 2.552 34 2C34 1.448 33.553 1 33 1H19.5C18.948 1 18.5 1.448 18.5 2C18.5 2.552 18.948 3 19.5 3Z" />
      <path d="M19.5 8H45C45.553 8 46 7.552 46 7C46 6.448 45.553 6 45 6H19.5C18.948 6 18.5 6.448 18.5 7C18.5 7.552 18.948 8 19.5 8Z" />
      <path d="M19.5 13H41C41.553 13 42 12.552 42 12C42 11.448 41.553 11 41 11H19.5C18.948 11 18.5 11.448 18.5 12C18.5 12.552 18.948 13 19.5 13Z" />
      <path d="M19.5 23H33C33.553 23 34 22.552 34 22C34 21.448 33.553 21 33 21H19.5C18.948 21 18.5 21.448 18.5 22C18.5 22.552 18.948 23 19.5 23Z" />
      <path d="M45 26H19.5C18.948 26 18.5 26.448 18.5 27C18.5 27.552 18.948 28 19.5 28H45C45.553 28 46 27.552 46 27C46 26.448 45.553 26 45 26Z" />
      <path d="M19.5 33H41C41.553 33 42 32.552 42 32C42 31.448 41.553 31 41 31H19.5C18.948 31 18.5 31.448 18.5 32C18.5 32.552 18.948 33 19.5 33Z" />
      <path d="M19.5 43H33C33.553 43 34 42.552 34 42C34 41.448 33.553 41 33 41H19.5C18.948 41 18.5 41.448 18.5 42C18.5 42.552 18.948 43 19.5 43Z" />
      <path d="M45 46H19.5C18.948 46 18.5 46.448 18.5 47C18.5 47.552 18.948 48 19.5 48H45C45.553 48 46 47.552 46 47C46 46.448 45.553 46 45 46Z" />
      <path d="M41 51H19.5C18.948 51 18.5 51.448 18.5 52C18.5 52.552 18.948 53 19.5 53H41C41.553 53 42 52.552 42 52C42 51.448 41.553 51 41 51Z" />
      <path d="M16.2928 17.293L6.49976 27.086L4.70676 25.293C4.31576 24.902 3.68376 24.902 3.29276 25.293C2.90176 25.684 2.90176 26.316 3.29276 26.707L5.79276 29.207C5.98776 29.402 6.24376 29.5 6.49976 29.5C6.75576 29.5 7.01176 29.402 7.20676 29.207L17.7068 18.707C18.0978 18.316 18.0978 17.684 17.7068 17.293C17.3158 16.902 16.6838 16.902 16.2928 17.293Z" />
    </svg>
  );
}
