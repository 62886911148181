export default function TestsFolder({ fill, ...rest }) {
  return (
    <svg
      width="29"
      height="23"
      viewBox="0 0 29 23"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M10.8753 12.9465C10.0235 12.9465 9.32178 13.6482 9.32178 14.5001C9.32178 15.352 10.0235 16.0537 10.8753 16.0537C11.7272 16.0537 12.4289 15.352 12.4289 14.5001C12.4289 13.6482 11.7272 12.9465 10.8753 12.9465ZM10.8753 13.9822C11.1675 13.9822 11.3932 14.208 11.3932 14.5001C11.3932 14.7922 11.1675 15.018 10.8753 15.018C10.5832 15.018 10.3575 14.7922 10.3575 14.5001C10.3575 14.208 10.5832 13.9822 10.8753 13.9822Z" />
      <path d="M10.8753 17.0895C10.0235 17.0895 9.32178 17.7912 9.32178 18.643C9.32178 19.4949 10.0235 20.1966 10.8753 20.1966C11.7272 20.1966 12.4289 19.4949 12.4289 18.643C12.4289 17.7912 11.7272 17.0895 10.8753 17.0895ZM10.8753 18.1252C11.1675 18.1252 11.3932 18.3509 11.3932 18.643C11.3932 18.9352 11.1675 19.1609 10.8753 19.1609C10.5832 19.1609 10.3575 18.9352 10.3575 18.643C10.3575 18.3509 10.5832 18.1252 10.8753 18.1252Z" />
      <path d="M13.9817 9.83948C13.8444 9.83948 13.7127 9.89404 13.6155 9.99115C13.5184 10.0883 13.4639 10.22 13.4639 10.3573C13.4639 10.4947 13.5184 10.6264 13.6155 10.7235C13.7127 10.8206 13.8444 10.8752 13.9817 10.8752H19.1603C19.2976 10.8752 19.4294 10.8206 19.5265 10.7235C19.6236 10.6264 19.6782 10.4947 19.6782 10.3573C19.6782 10.22 19.6236 10.0883 19.5265 9.99115C19.4294 9.89404 19.2976 9.83948 19.1603 9.83948H13.9817Z" />
      <path d="M11.8947 8.80344C11.8168 8.80571 11.7404 8.82553 11.6713 8.86143C11.6021 8.89733 11.5419 8.94837 11.4952 9.01076L10.4444 10.4114L10.3018 10.1252C10.2714 10.0644 10.2293 10.0101 10.178 9.96558C10.1266 9.92102 10.0669 9.88702 10.0024 9.86551C9.93792 9.84401 9.86979 9.83542 9.80196 9.84024C9.73413 9.84507 9.66791 9.86321 9.60708 9.89362C9.54625 9.92403 9.49201 9.96612 9.44744 10.0175C9.40288 10.0689 9.36888 10.1285 9.34737 10.193C9.32587 10.2575 9.31728 10.3257 9.32211 10.3935C9.32693 10.4613 9.34507 10.5276 9.37549 10.5884L9.89329 11.6239C9.93276 11.703 9.99179 11.7706 10.0648 11.8203C10.1377 11.8701 10.2222 11.9004 10.3102 11.9083C10.3982 11.9162 10.4867 11.9014 10.5674 11.8655C10.648 11.8295 10.7182 11.7735 10.7711 11.7028L12.3245 9.6317C12.3653 9.57726 12.3949 9.51531 12.4118 9.4494C12.4286 9.38349 12.4323 9.3149 12.4226 9.24757C12.413 9.18023 12.3901 9.11545 12.3554 9.05695C12.3207 8.99844 12.2748 8.94735 12.2203 8.9066C12.1265 8.83637 12.0118 8.80001 11.8947 8.80345L11.8947 8.80344Z" />
      <path d="M13.9817 13.9821C13.8444 13.9821 13.7127 14.0366 13.6155 14.1337C13.5184 14.2308 13.4639 14.3626 13.4639 14.4999C13.4639 14.6373 13.5184 14.769 13.6155 14.8661C13.7127 14.9632 13.8444 15.0178 13.9817 15.0178H19.1603C19.2976 15.0178 19.4294 14.9632 19.5265 14.8661C19.6236 14.769 19.6782 14.6373 19.6782 14.4999C19.6782 14.3626 19.6236 14.2308 19.5265 14.1337C19.4294 14.0366 19.2976 13.9821 19.1603 13.9821H13.9817Z" />
      <path d="M13.9817 18.125C13.8444 18.125 13.7127 18.1796 13.6155 18.2767C13.5184 18.3738 13.4639 18.5055 13.4639 18.6429C13.4639 18.7802 13.5184 18.9119 13.6155 19.009C13.7127 19.1062 13.8444 19.1607 13.9817 19.1607H19.1603C19.2976 19.1607 19.4294 19.1062 19.5265 19.009C19.6236 18.9119 19.6782 18.7802 19.6782 18.6429C19.6782 18.5055 19.6236 18.3738 19.5265 18.2767C19.4294 18.1796 19.2976 18.125 19.1603 18.125H13.9817Z" />
      <path d="M2.58929 0C1.16539 0 0 1.16539 0 2.58929V20.1964C0 21.6203 1.16539 22.7857 2.58929 22.7857H26.4107C27.8346 22.7857 29 21.6203 29 20.1964V5.69643C29 4.27253 27.8346 3.10714 26.4107 3.10714H15.0179C14.4919 3.10714 13.9879 2.89852 13.616 2.52657L11.9734 0.883982C11.4075 0.318068 10.6396 0 9.83929 0H2.58929ZM2.58929 1.03571H9.83929C10.3653 1.03571 10.8692 1.24433 11.2411 1.61628L12.8837 3.25887C13.4496 3.82479 14.2176 4.14286 15.0179 4.14286H26.4107C27.2749 4.14286 27.9643 4.83228 27.9643 5.69643V6.74732C27.5298 6.41768 26.9949 6.21428 26.4107 6.21428H2.58929C2.00514 6.21428 1.47025 6.41768 1.03571 6.74732V2.58929C1.03571 1.72514 1.72514 1.03571 2.58929 1.03571ZM2.58929 7.25H26.4107C27.2749 7.25 27.9643 7.93942 27.9643 8.80357V20.1964C27.9643 21.0606 27.2749 21.75 26.4107 21.75H2.58929C1.72514 21.75 1.03571 21.0606 1.03571 20.1964V8.80357C1.03571 7.93942 1.72514 7.25 2.58929 7.25Z" />
    </svg>
  );
}
