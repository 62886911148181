import { gql } from '@apollo/client';
import MediaFragment from './MediaFragment';

const IssueCommentFragment = gql`
  fragment IssueCommentFragment on IssueComment {
    id
    createdAt
    comment
    user {
      id
      username
      picture {
        id
        src
      }
    }
    mediaList {
      ...MediaFragment
    }
  }

  ${MediaFragment}
`;

export default IssueCommentFragment;
