import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TestsListItem from './TestsListItem';

export default function TestListItemDraggable({
  invisible,
  test,
  checkedTests,
  onCheck,
}) {
  const {
    attributes,
    transition,
    listeners,
    setNodeRef,
    transform,
  } = useSortable({
    id: test.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="outline-none">
      <TestsListItem
        invisible={invisible}
        test={test}
        checkedTests={checkedTests}
        sortableListeners={listeners}
        sortableAttributes={attributes}
        onCheck={onCheck}
      />
    </div>
  );
}
