import clsx from 'clsx';
import { useField } from 'formik';
import { useMemo } from 'react';
import AutoResizeTextarea from './AutoResizeTextarea';
import Error from './Error';

export default function TextField({
  className,
  classes = {},
  label = null,
  endAdornment = null,
  onAdornmentClick = null,
  type = 'text',
  multiline = false,
  required = false,
  disabled = false,
  skipTouchedError = false,
  ...props
}) {
  const [field, meta] = useField(props);

  const handleAdornmentPointerDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onAdornmentClick();
  };

  const InputComponent = useMemo(() => {
    return ({ className, onChange, ...rest }) =>
      multiline ? (
        <AutoResizeTextarea
          className={className}
          onChange={onChange}
          required={required}
          {...rest}
        />
      ) : (
        <input
          className={clsx(className, disabled && 'opacity-40')}
          required={required}
          disabled={disabled}
          onChange={onChange}
          {...rest}
        />
      );
  }, [multiline, required, disabled]);

  return (
    <div className={clsx('flex flex-col mb-4', className)}>
      <label
        className={clsx(
          'mb-1 opacity-75 font-bold text-sm',
          (skipTouchedError || meta.touched) &&
            meta.error &&
            !disabled &&
            'text-error',
          classes.label,
        )}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <div className="relative">
        <InputComponent
          className={clsx(
            'rounded border-gray-300 w-full pr-8',
            (skipTouchedError || meta.touched) &&
              meta.error &&
              !disabled &&
              'border border-error',
          )}
          type={type}
          {...field}
          {...props}
        />
        {endAdornment ? (
          <button
            type="button"
            className="absolute right-0 top-0 bottom-0 w-20 justify-center px-4 py-2 opacity-50"
            onPointerDown={handleAdornmentPointerDown}
          >
            {endAdornment}
          </button>
        ) : null}
      </div>
      {(skipTouchedError || meta.touched) && meta.error && !disabled ? (
        <div className="text-sm mt-1 text-error">
          <Error>{meta.error}</Error>
        </div>
      ) : null}
    </div>
  );
}
