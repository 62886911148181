import Button from '../System/Button';
import { useTranslation } from 'react-i18next';
import NoteAddIcon from '../Icons/NoteAdd';
import useOpen from '../System/useOpen';
import { gql, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import TestDialog from './Test/TestDialog';
import clsx from 'clsx';
import AddIcon from '../Icons/Add';
import useSnackbar from '../System/useSnackbar';
import { GET_TESTS } from './useTests';

const CREATE_TEST = gql`
  mutation CreateTest(
    $title: String!
    $description: String!
    $position: Int!
    $steps: [TestStepInput!]!
    $folderId: String!
  ) {
    createTest(
      title: $title
      description: $description
      position: $position
      steps: $steps
      folderId: $folderId
    ) {
      id
      title
      position
      archived
      status
      createdAt
    }
  }
`;

const POSITION_STEP = 65536;

export default function CreateTestButton({ tests }) {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { replace } = useHistory();
  const { folderId } = useParams();

  const [createTest, { error }] = useMutation(CREATE_TEST, {
    update(cache, { data: { createTest } }) {
      const { getTests } = cache.readQuery({
        query: GET_TESTS,
        variables: {
          folderId,
        },
      });

      cache.writeQuery({
        query: GET_TESTS,
        data: {
          getTests: [...getTests, createTest],
        },
        variables: {
          folderId,
        },
      });
    },
    onCompleted() {
      showSnackbar(t('CreateTestButton.success'), 'success');
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  const { open, onOpen, onClose } = useOpen();

  const handleCreateTest = async ({ title, description, steps }) => {
    const { data } = await createTest({
      variables: {
        title,
        description,
        position: tests?.length
          ? tests[tests.length - 1].position + POSITION_STEP
          : POSITION_STEP,
        steps: steps.filter((step) => step.title.length),
        folderId,
      },
    });
    replace(`/tests/${folderId}/${data.createTest.id}`);
    onClose();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<NoteAddIcon />}
        className={clsx('hidden md:inline-flex')}
        onClick={onOpen}
      >
        {t('CreateTestButton.text')}
      </Button>

      <Button
        variant="contained"
        color="primary"
        className={clsx(
          'md:hidden z-20',
          'h-16 w-16 flex items-center justify-center rounded-full shadow-lg',
          'fixed bottom-6 right-8',
        )}
        onClick={onOpen}
      >
        <AddIcon className="fill-current h-7 w-7" />
      </Button>

      <TestDialog
        open={open}
        error={error}
        onClose={onClose}
        onSubmit={handleCreateTest}
      />
    </>
  );
}
