import clsx from 'clsx';
import ScheduledTests from '../TestScheduler/ScheduledTests';
import { useTranslation } from 'react-i18next';

export default function Agenda({ className, ...rest }) {
  const { t } = useTranslation();

  return (
    <section className={clsx(className)} {...rest}>
      <h1>{t('Agenda.title')}</h1>

      <p className="mb-8">{t('Agenda.subtitle')}</p>

      <ScheduledTests />
    </section>
  );
}
