import { Switch, Route, Redirect } from 'react-router-dom';
import ForgotPasswordPage from '../../pages/forgot-password';
import ForgotPasswordSuccessPage from '../../pages/forgot-password-success';
import ResetPasswordPage from '../../pages/reset-password';
import SignInPage from '../../pages/sign-in';
import SignUpPage from '../../pages/sign-up';

export default function AuthRouter() {
  return (
    <Switch>
      <Route path="/sign-in">
        <SignInPage />
      </Route>
      <Route path="/sign-up">
        <SignUpPage />
      </Route>
      <Route path="/forgot-password">
        <ForgotPasswordPage />
      </Route>
      <Route path="/forgot-password-success">
        <ForgotPasswordSuccessPage />
      </Route>
      <Route path="/reset-password/:token">
        <ResetPasswordPage />
      </Route>
      <Redirect to="/sign-in" />
    </Switch>
  );
}
