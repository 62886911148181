import { gql, useMutation } from '@apollo/client';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '../Icons/DeleteForever';
import Button from '../System/Button';
import ConfirmDialog from '../System/ConfirmDialog';
import useOpen from '../System/useOpen';
import useSnackbar from '../System/useSnackbar';
import Alert from '../System/Alert';
import { useParams } from 'react-router-dom';

const DELETE_FOLDER = gql`
  mutation DeleteFolder($folderId: String!) {
    deleteFolder(folderId: $folderId)
  }
`;

export default function DeleteFolder({ className, folder, ...rest }) {
  const { t } = useTranslation();

  const { folderId } = useParams();

  const { open, onOpen, onClose } = useOpen();

  const { showSnackbar } = useSnackbar();

  const [onDelete, { loading }] = useMutation(DELETE_FOLDER, {
    variables: {
      folderId,
    },
    update(cache) {
      cache.modify({
        fields: {
          getFolders(existingFolderRefs, { readField }) {
            return existingFolderRefs.filter(
              (folderRef) => readField('id', folderRef) !== folderId,
            );
          },
        },
      });
    },
    onCompleted() {
      showSnackbar(t('DeleteFolder.success'), 'success');
    },
    onError() {
      showSnackbar(t('Global.serverError'), 'error');
    },
  });

  return (
    <>
      <Button
        title={t('DeleteFolder.deleteTitle')}
        noTransition
        noPadding
        noBg
        className={clsx(
          'fill-current w-6 h-6 ml-2 opacity-20 group-hover:opacity-80',
          className,
        )}
        onClick={onOpen}
        {...rest}
      >
        <DeleteIcon />
      </Button>

      <ConfirmDialog
        loading={loading}
        open={open}
        onDelete={onDelete}
        onClose={onClose}
      >
        <p className="mb-6">
          {t('DeleteFolder.confirm1')}{' '}
          <strong>
            <em>{folder.name}</em>
          </strong>{' '}
          {t('DeleteFolder.confirm2')}{' '}
          <strong>{t('DeleteFolder.confirm3')}</strong>.
        </p>

        <Alert variant="warning">{t('Global.noUndo')}</Alert>
      </ConfirmDialog>
    </>
  );
}
