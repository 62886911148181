import clsx from 'clsx';
import CreateTestButton from './CreateTestButton';
import { useTranslation } from 'react-i18next';

import draw from '../../assets/undraw_add_post_64nu.svg';

export default function EmptyTestList({ className }) {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'flex-1 flex flex-col justify-center items-center',
        className,
      )}
    >
      <div className="max-w-sm w-full">
        <img src={draw} alt="empty test list" />
      </div>

      <h2 className="mt-12 mb-2">{t('EmptyTestList.title')}</h2>

      <p className="mb-4">{t('EmptyTestList.text')}</p>

      <div>
        <CreateTestButton />
      </div>
    </div>
  );
}
