import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AuthLayout from '../components/Auth/AuthLayout';
import GoogleSignInButton from '../components/Google/GoogleSignInButton';
import SignUpForm from '../components/SignUp/SignUpForm';
import Or from '../components/System/Or';

export default function SignUpPage() {
  const { t } = useTranslation();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const workspaceId = params.get('join');
  const workspaceName =
    params.get('name') && decodeURIComponent(params.get('name'));
  const email = params.get('email') && decodeURIComponent(params.get('email'));

  return (
    <AuthLayout title={t('SignUpPage.title')}>
      {workspaceId && workspaceName && email ? (
        <>
          <div className="bg-background-default px-6 py-4 caption rounded mb-8">
            <p className="mb-2">{t('SignUpPage.invite', { workspaceName })}</p>
            <p>{t('SignUpPage.inviteInfo', { email: email.toLowerCase() })}</p>
          </div>
        </>
      ) : null}

      <GoogleSignInButton join={workspaceId} />

      <Or className="my-8" />

      <SignUpForm join={workspaceId} />
    </AuthLayout>
  );
}
