import { gql, useMutation } from '@apollo/client';

const SET_CURRENT_WORKSPACE = gql`
  mutation SetCurrentWorkspace($workspaceId: String!) {
    setCurrentWorkspace(workspaceId: $workspaceId)
  }
`;

export default function useSetCurrentWorkspace() {
  const [setCurrentWorkspace, { loading, error }] = useMutation(
    SET_CURRENT_WORKSPACE,
  );

  return { setCurrentWorkspace, loading, error };
}
