import { gql, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import ScheduledTestFragment from '../../fragments/ScheduledTestFragment';

const GET_SCHEDULED_TESTS = gql`
  query GetScheduledTests {
    getScheduledTests {
      ...ScheduledTestFragment
    }
  }

  ${ScheduledTestFragment}
`;

export default function useScheduledTests() {
  const { data, loading } = useQuery(GET_SCHEDULED_TESTS);

  const scheduledTests = data?.getScheduledTests;

  const grouppedSchedules = useMemo(() => {
    if (!Array.isArray(scheduledTests)) {
      return [];
    }

    // Scheduled tests are already sorted by date from the server
    // but cache can be updated client side and mess with sorting
    let currentDate;
    return scheduledTests
      .filter(
        (t) => !t.scheduledTestIssueResult && !t.scheduledTestPassedResult,
      )
      .sort((a, b) => (Number(a.limitDate) < Number(b.limitDate) ? -1 : 1))
      .reduce((acc, test) => {
        const transformedTest = {
          ...test,
          limitDate: test.limitDate
            ? DateTime.fromMillis(Number(test.limitDate))
            : null,
        };

        if (test.limitDate !== currentDate) {
          currentDate = test.limitDate;
          return [
            ...acc,
            {
              limitDate: test.limitDate
                ? DateTime.fromMillis(Number(test.limitDate))
                : null,
              scheduledTests: [transformedTest],
            },
          ];
        }
        return acc.map((group, index) => {
          if (index === acc.length - 1) {
            return {
              ...group,
              scheduledTests: [...group.scheduledTests, transformedTest],
            };
          }
          return group;
        });
      }, []);
  }, [scheduledTests]);

  return {
    loading,
    scheduledTests,
    grouppedSchedules,
  };
}
