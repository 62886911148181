import Button from '../../System/Button';
import useOpen from '../../System/useOpen';
import useFolders from '../useFolders';
import FolderItem from '../FolderItem';
import { useParams } from 'react-router-dom';
import FolderIcon from '../../Icons/Folder';
import SettingsIcon from '../../Icons/Settings';
import CreateFolder from '../CreateFolder';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function FolderSection({ className, onFolderSelect }) {
  const { t } = useTranslation();

  const { open: editMode, onToggle: onEdit } = useOpen();

  const { folders } = useFolders();

  const { folderId: folderIdParam } = useParams();

  if (!folderIdParam && folders?.length) {
    return <Redirect to={`/tests/${folders[0].id}`} />;
  }

  return (
    <div className={clsx('flex flex-col overflow-auto', className)}>
      <div className="flex items-center bg-primary-light text-primary-main px-8 py-4 border-b border-primary-main border-opacity-10">
        {editMode ? null : (
          <div className="flex flex-1 items-center">
            <FolderIcon className="fill-current mr-2" />
            <p className="font-bold">Dossiers</p>
          </div>
        )}

        <div className="flex items-center">
          <Button startIcon={<SettingsIcon />} size="small" onClick={onEdit}>
            {editMode
              ? t('FolderSection.exitFolderManager')
              : t('FolderSection.openFolderManager')}
          </Button>
        </div>
      </div>

      {editMode ? (
        <div className="px-8 py-4">
          <CreateFolder onBlur={onEdit} />
        </div>
      ) : null}

      <nav className="flex flex-col overflow-auto">
        <ul data-cy="test-folders-list">
          {folders.map((folder) => (
            <FolderItem
              className="hover:bg-primary-light"
              classes={{
                item: 'px-4',
                selected:
                  'px-4 font-bold bg-primary-light text-primary-contrast',
              }}
              editMode={editMode}
              selected={(folderId) => folderIdParam === folderId}
              key={folder.id}
              folder={folder}
              to={(folder) => `/tests/${folder.id}`}
              onClick={onFolderSelect}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
}
